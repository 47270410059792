import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Data } from "../data/Data";

const Footer = () => {
  const data = Data;
  const WarningIcon = data.footer.WarningIcon;
  const CloseIcon = data.footer.CloseIcon;
  const EmailIcon = data.footer.EmailIcon;
  const WhatsAppIcon = data.footer.WhatsAppIcon;
  const CallIcon = data.footer.CallIcon;
  const FacebookIcon = data.footer.FacebookIcon;
  const LinkedInIcon = data.footer.LinkedInIcon;
  const YouTubeIcon = data.footer.YouTubeIcon;
  const InstagramIcon = data.footer.InstagramIcon;
  const XIcon = data.footer.XIcon;
  const PinterestIcon = data.footer.PinterestIcon;
  const TopArrowIcon = data.footer.TopArrowIcon;

  const [showSmarterPopup, setShowSmarterPopup] = useState(false);

  const showSmarterDecisionsPopUp = () => {
    setShowSmarterPopup(true);
  };

  const hideSmarterDecisionsPopUp = () => {
    setShowSmarterPopup(false);
  };

  useEffect(() => {
    const footerTitle = document.getElementById(".footer__title");

    // Verifica si el elemento existe antes de agregar el evento
    if (footerTitle) {
      footerTitle.addEventListener("mouseover", showSmarterDecisionsPopUp);
    }

    // Cleanup para remover el event listener
    return () => {
      if (footerTitle) {
        footerTitle.removeEventListener("mouseover", showSmarterDecisionsPopUp);
      }
    };
  }, []); // El array vacío significa que se ejecuta solo una vez después del primer renderizado

  return (
    <footer className="font-montserrat relative w-full overflow-hidden bg-white text-white">
      {/* RIGHT WAVES */}
      <div
        className="absolute right-0 top-20 h-[400px] w-[100px] bg-no-repeat bg-cover z-5"
        style={{
          backgroundImage: `url(https://console.catalyst.zoho.com/baas/v1/project/6905000000008864/folder/6905000000051112/file/16144000000028116/download?Environment=Production&catalyst_org=861843634)`,
        }}
      ></div>

      {/* BOTTOM WAVES */}
      <div
        className="absolute bottom-12 left-0 h-[700px] w-[200px] bg-no-repeat bg-cover z-5"
        style={{
          backgroundImage: `url(https://console.catalyst.zoho.com/baas/v1/project/6905000000008864/folder/6905000000051112/file/16144000000028116/download?Environment=Production&catalyst_org=861843634)`,
        }}
      ></div>

      {/* TOP WAVES */}
      <div
        className="h-[450px] w-full bg-cover bg-no-repeat"
        style={{
          backgroundImage: `url(https://console.catalyst.zoho.com/baas/v1/project/6905000000008864/folder/6905000000051112/file/16144000000028056/download?Environment=Production&catalyst_org=861843634)`,
        }}
      ></div>

      {/* Footer Container */}
      <div className="bg-[#003b5c] p-8">
        <div className="flex justify-center items-center mb-8">
          <div className="w-[15%] h-[4px] bg-blue-500"></div>
          <p
            className="mx-4 text-[2.5rem] uppercase font-semibold tracking-widest"
            id=".footer__title"
          >
            Investor Report
          </p>
          <div className="w-[15%] h-[4px] bg-blue-500"></div>
        </div>

        {/* Popup para Smarter Decisions */}
        {showSmarterPopup && (
          <div className="absolute top-[25%] text-[1.5rem] text-[#003b5c] left-1/2 transform -translate-x-1/2 bg-white bg-opacity-80 backdrop-blur-sm rounded-3xl shadow-lg p-8 w-[750px] transition-opacity duration-500">
            <div className="relative">
              <img
                src={WarningIcon}
                alt="Warning"
                className="absolute left-1/2 transform -translate-x-1/2 top-[-9rem] w-[125px] h-[125px] bg-blue-500 p-4 rounded-full border-4 border-white"
              />
              <button
                className="absolute top-4 right-4"
                onClick={hideSmarterDecisionsPopUp}
              >
                <img src={CloseIcon} alt="Close PopUp" />
              </button>
              <div className="text-center">
                <h3 className="text-[1.5rem] font-medium mt-12">
                  Make Smarter Decisions!
                </h3>
                <h2 className="text-[3rem] font-bold mb-2">Sign up!</h2>
                <h4 className="text-[1.5rem] font-medium">
                  Receive in-depth reports with detailed:
                </h4>
                <div className="flex justify-center gap-4 mt-4 text-[1.5rem]">
                  <label className="flex items-center gap-2">
                    <input
                      type="checkbox"
                      className="w-6 h-6 rounded-full border-4 border-blue-900 bg-blue-500 focus:ring-0"
                    />
                    ROI Analysis
                  </label>
                  <label className="flex items-center gap-2">
                    <input
                      type="checkbox"
                      className="w-6 h-6 rounded-full border-4 border-blue-900 bg-blue-500 focus:ring-0"
                    />
                    Cap Rate
                  </label>
                  <label className="flex items-center gap-2">
                    <input
                      type="checkbox"
                      className="w-6 h-6 rounded-full border-4 border-blue-900 bg-blue-500 focus:ring-0"
                    />
                    Cash-on-Cash Return
                  </label>
                </div>
                <p className="mt-4">
                  Our insights empower you to identify high-potential properties
                  and maximize your returns.
                </p>
                <input
                  type="email"
                  placeholder="yourmailaddress@here.com"
                  className="w-full p-2 mt-4 border border-blue-300 rounded-lg shadow-inner focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <button className="mt-4 bg-blue-500 text-white font-bold text-[1.5rem] uppercase py-2 px-8 rounded-lg transition hover:bg-blue-400">
                  Subscribe
                </button>
              </div>
            </div>
          </div>
        )}

        <div className="flex flex-wrap justify-between flex-col items-center py-8">
          {/* Footer Links */}
          <ul className="flex flex-wrap justify-center text-[1.25rem] text-center font-light space-x-4 mb-8">
            <li>
              <Link to="/market-report" className="hover:underline">
                Market Report
              </Link>
            </li>
            <div className="w-1 h-[1.5rem] bg-blue-500" />
            <li>
              <Link to="/concierge" className="hover:underline">
                Concierge
              </Link>
            </li>
            <div className="w-1 h-[1.5rem] bg-blue-500" />
            <li>
              <Link to="/storm" className="hover:underline">
                Storm
              </Link>
            </li>
            <div className="w-1 h-[1.5rem] bg-blue-500" />
            <li>
              <Link to="/management" className="font-semibold hover:underline">
                Management
              </Link>
            </li>
            <div className="w-1 h-[1.5rem] bg-blue-500" />
            <li>
              <Link to="/buying" className="font-semibold hover:underline">
                Buying
              </Link>
            </li>
            <div className="w-1 h-[1.5rem] bg-blue-500" />
            <li>
              <Link to="/selling" className="font-semibold hover:underline">
                Selling
              </Link>
            </li>
            <div className="w-1 h-[1.5rem] bg-blue-500" />
            <li>
              <Link to="/about-us" className="hover:underline">
                About Us
              </Link>
            </li>
            <div className="w-1 h-[1.5rem] bg-blue-500" />
            <li>
              <Link to="/bluworld" className="hover:underline">
                BluWorld
              </Link>
            </li>
            <div className="w-1 h-[1.5rem] bg-blue-500" />
            <li>
              <Link to="/marine-lifestyle" className="hover:underline">
                Marine Lifestyle
              </Link>
            </li>
          </ul>

          {/* Contact Links */}
          <div className="flex justify-center items-center space-x-6 mb-8 gap-4 text-[2rem]">
            <a
              href="mailto:followus@blurealty.ai"
              className="flex items-center space-x-2 hover:underline p-10"
            >
              <div className="bg-gradient-to-b from-[#2699fb] to-[#022234] rounded-full w-24 h-24 flex items-center justify-center">
                <img src={EmailIcon} alt="Email Icon" className="w-12 h-12" />
              </div>
              <span>followus@blurealty.ai</span>
            </a>
            <a
              href="https://wa.me/3058002587"
              className="flex items-center space-x-2 hover:underline p-10"
            >
              <div className="bg-gradient-to-b from-[#2699fb] to-[#022234] rounded-full w-24 h-24 flex items-center justify-center">
                <img
                  src={WhatsAppIcon}
                  alt="WhatsApp Icon"
                  className="w-10 h-10"
                />
              </div>
              <span className="mt-10 text-end">
                305.800.BLUR <br /> (2587)
              </span>
            </a>
            <a
              href="tel:+13058002587"
              className="flex items-center space-x-2 hover:underline p-10"
            >
              <div className="bg-gradient-to-b from-[#2699fb] to-[#022234] rounded-full w-24 h-24 flex items-center justify-center">
                <img src={CallIcon} alt="Call Icon" className="w-10 h-10" />
              </div>
              <span>Book a Call</span>
            </a>
          </div>

          {/* Social Icons */}
          <div className="flex justify-center space-x-4">
            <a
              href="https://www.facebook.com"
              className="w-32 h-32 flex items-center justify-center border border-[#2699fb] rounded-full"
            >
              <div className="bg-[#2699fb] rounded-full w-20 h-20 flex items-center justify-center">
                <img
                  src={FacebookIcon}
                  alt="Facebook"
                  className="bg-[#2699fb] p-4 rounded-full w-20 h-20"
                />
              </div>
            </a>
            <a
              href="https://www.linkedin.com"
              className="w-32 h-32 flex items-center justify-center border border-[#2699fb] rounded-full"
            >
              <div className="bg-[#2699fb] rounded-full w-20 h-20 flex items-center justify-center">
                <img src={LinkedInIcon} alt="LinkedIn" className="w-12 h-12" />
              </div>
            </a>
            <a
              href="https://www.youtube.com"
              className="w-32 h-32 flex items-center justify-center border border-[#2699fb] rounded-full"
            >
              <div className="bg-[#2699fb] rounded-full w-20 h-20 flex items-center justify-center">
                <img
                  src={YouTubeIcon}
                  alt="YouTube"
                  className="bg-[#2699fb] p-4 rounded-full w-20 h-20"
                />
              </div>
            </a>
            <a
              href="https://www.instagram.com"
              className="w-32 h-32 flex items-center justify-center border border-[#2699fb] rounded-full"
            >
              <div className="bg-[#2699fb] rounded-full w-20 h-20 flex items-center justify-center">
                <img
                  src={InstagramIcon}
                  alt="Instagram"
                  className="bg-[#2699fb] w-12 h-12"
                />
              </div>
            </a>
            <a
              href="https://www.twitter.com"
              className="w-32 h-32 flex items-center justify-center border border-[#2699fb] rounded-full"
            >
              <div className="bg-[#2699fb] rounded-full w-20 h-20 flex items-center justify-center">
                <img
                  src={XIcon}
                  alt="Twitter"
                  className="bg-[#2699fb] p-4 rounded-full w-20 h-20"
                />
              </div>
            </a>
            <a
              href="https://www.pinterest.com"
              className="w-32 h-32 flex items-center justify-center border border-[#2699fb] rounded-full"
            >
              <div className="bg-[#2699fb] rounded-full w-20 h-20 flex items-center justify-center">
                <img
                  src={PinterestIcon}
                  alt="Pinterest"
                  className="bg-[#2699fb] p-4 rounded-full w-20 h-20"
                />
              </div>
            </a>
          </div>
        </div>

        {/* Footer Bottom */}
        <div className="text-center py-4 flex flex-col gap-10 text-[1.5rem]">
          <p className="font-bold">#blutranquility #blurealtyai</p>
          <div className="flex flex-row justify-center items-center text-center gap-24">
            <div className="flex flex-row justify-center space-x-4 text-center gap-2">
              <Link
                to="/privacy-policy"
                className="underline flex justify-center items-center text-center"
              >
                Privacy Policy
              </Link>
              <div className="w-[1px] h-8 bg-white" />
              <Link
                to="/terms-and-conditions"
                className="underline flex justify-center items-center text-center"
              >
                Terms & Conditions
              </Link>
            </div>
            <div className="flex flex-row justify-center space-x-4 text-center gap-2">
              <p className="underline">323 Sunny Isles Boulevard, 7th Floor</p>
              <div className="w-[1px] h-8 bg-white" />
              <p className="underline">Sunny Isles Beach, Florida 33160</p>
            </div>
          </div>
        </div>

        {/* Back to Top Arrow */}
        <a
          href="#Top"
          className="absolute bottom-0 left-0 ml-16 mb-64 flex justify-center mt-4"
        >
          <img src={TopArrowIcon} alt="Back to Top" className="w-8 h-12" />
        </a>
      </div>
    </footer>
  );
};

export default Footer;

import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import "./index.css";

// import App from './App.jsx'
import BluWorld from "./pages/BluWorld/BluWorld.jsx";
import Home from "./pages/Home/Home.jsx";
import Jp from "./pages/Jp/Jp.jsx";
import MAForm from "./pages/MAForm/MAForm.jsx";

createRoot(document.getElementById("root")).render(
  <StrictMode>
    {/* <App /> */}
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/ma-form" element={<MAForm />} />
        <Route path="/blu-world" element={<BluWorld />} />
        <Route path="/jp" element={<Jp />} />
      </Routes>
    </Router>
  </StrictMode>
);

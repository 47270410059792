import React, { useEffect, useState } from "react";
import BluStory from "./BluContentSection/BluStory";
import BluWorld from "./BluContentSection/BluWorld";
import { Data } from "../../data/Data";

const bluStoryImage =
  "https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/BioPage%2FImages%2FbluStory.png?alt=media&token=db764857-2891-4327-bfc3-07d4ae621d05";
const bioPageWavesImage =
  "https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/BioPage%2FImages%2FbioPageWaves.svg?alt=media&token=7740b2a0-b011-4ce8-9040-c1c9a74c1f05";

const BluContentSection = () => {
  useEffect(() => {
    const handleMouseEnter = (event) => {
      event.target.style.transform = "scale(1.2)";
    };

    const handleMouseLeave = (event) => {
      event.target.style.transform = "scale(1)";
    };

    const cards = document.querySelectorAll(".carousel li");
    cards.forEach((card) => {
      card.addEventListener("mouseenter", handleMouseEnter);
      card.addEventListener("mouseleave", handleMouseLeave);
    });

    return () => {
      cards.forEach((card) => {
        card.removeEventListener("mouseenter", handleMouseEnter);
        card.removeEventListener("mouseleave", handleMouseLeave);
      });
    };
  }, []);

  const [isVisibleBluStory, setIsVisibleBluStory] = useState(false)
  const [isVisibleBluWorld, setIsVisibleBluWorld] = useState(false)
  function visibleBluWorld() {
    setIsVisibleBluWorld(!isVisibleBluWorld)
    setIsVisibleBluStory(false)
  }
  function visibleBluStory() {
    setIsVisibleBluWorld(false)
    setIsVisibleBluStory(!isVisibleBluStory)
  }

  const data = Data.jp

  return (
    <section className="relative overflow-hidden">
      <div className="flex justify-center items-center absolute z-20 w-full h-[150px] gap-8 my-12">
        <button onClick={() => visibleBluWorld()} className="bg-white w-full max-w-[20%] h-[50%] cursor-pointer font-montserrat text-[#2699FB] font-bold rounded-3xl border-2 border-[#2699FB] text-[1.5rem]">Giving Back</button>
        <button onClick={() => visibleBluStory()} className="bg-white w-full max-w-[20%] h-[50%] cursor-pointer font-montserrat text-[#2699FB] font-bold rounded-3xl border-2 border-[#2699FB] text-[1.5rem]">BLU STORY</button>
      </div>
      <div className="pb-[250px]" />
      <BluWorld isVisible={isVisibleBluWorld} />
      <BluStory isVisible={isVisibleBluStory} />
      <img src={bioPageWavesImage} className="w-full my-12" alt="Ondas de la página de biografía" />
    </section>
  );
};

export default BluContentSection;
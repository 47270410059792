import React from "react";

const GoToBottomArrow =
  "https://console.catalyst.zoho.com/baas/v1/project/6905000000008864/folder/6905000000051131/file/16144000000031208/download?Environment=Production&catalyst_org=861843634";
const BluWorldLogo =
  "https://console.catalyst.zoho.com/baas/v1/project/6905000000008864/folder/6905000000051150/file/16144000000031038/download?Environment=Production&catalyst_org=861843634";
const BeachCleanImg =
  "https://console.catalyst.zoho.com/baas/v1/project/6905000000008864/folder/6905000000051074/file/16144000000033200/download?Environment=Production&catalyst_org=861843634";
const ObserverAcademyImg =
  "https://console.catalyst.zoho.com/baas/v1/project/6905000000008864/folder/6905000000051074/file/16144000000033168/download?Environment=Production&catalyst_org=861843634";
const BluCaresImg =
  "https://console.catalyst.zoho.com/baas/v1/project/6905000000008864/folder/6905000000051074/file/16144000000033183/download?Environment=Production&catalyst_org=861843634";
const ElDoradoImg =
  "https://console.catalyst.zoho.com/baas/v1/project/6905000000008864/folder/6905000000051074/file/16144000000033210/download?Environment=Production&catalyst_org=861843634";
const BluHeroImg =
  "https://console.catalyst.zoho.com/baas/v1/project/6905000000008864/folder/6905000000051074/file/16144000000033153/download?Environment=Production&catalyst_org=861843634";

export default function BluWorldHero() {
  return (
    <div className="relative w-full h-screen mb-10 flex justify-center items-center">
      {/* Hero Background */}
      <img src={BluHeroImg} className="absolute inset-0 w-full h-[160vh] object-cover z-[-10] top-[-30vh] md:top-0 " alt="Hero Background" />

      <div className="relative flex flex-col items-center justify-start pt-[13rem] h-full w-full bg-cover bg-no-repeat font-montserrat">
        {/* BluWorld Logo */}
        <img src={BluWorldLogo} alt="BluWorld" className="w-96" />

        {/* Title */}
        <h1 className="text-center text-white font-light uppercase text-lg tracking-wider mt-5 mb-8">
          Protecting our <span className="font-bold">world</span> starts with{" "}
          <span className="font-bold">each of us.</span>
        </h1>

        {/* Cards */}
        <div className="flex items-center gap-8">
          <div className="relative w-[290px] h-[380px] rounded-[25px] shadow-[0px_0px_24px_rgba(255,255,255,0.65)] p-9 flex items-end transition-transform duration-300 transform hover:scale-110 overflow-hidden cursor-pointer">
            <img src={BeachCleanImg} alt="Beach Clean" className="absolute inset-0 w-full h-full object-cover grayscale transition-all duration-300 hover:grayscale-0 z-0" />
            <div className="absolute inset-0 bg-gradient-to-b from-transparent to-[#003b5c] mix-blend-multiply pointer-events-none"></div>
            <div className="relative z-10">
              <h2 className="text-white text-2xl font-light">Beach Clean</h2>
            </div>
          </div>

          <div className="relative w-[290px] h-[380px] rounded-[25px] shadow-[0px_0px_24px_rgba(255,255,255,0.65)] p-9 flex items-end transition-transform duration-300 transform hover:scale-110 overflow-hidden cursor-pointer">
            <img src={ElDoradoImg} alt="El Dorado Discovered" className="absolute inset-0 w-full h-full object-cover grayscale transition-all duration-300 hover:grayscale-0 z-0" />
            <div className="absolute inset-0 bg-gradient-to-b from-transparent to-[#003b5c] mix-blend-multiply pointer-events-none"></div>
            <div className="relative z-10">
              <h2 className="text-white text-2xl font-light">El Dorado Discovered</h2>
            </div>
          </div>

          <div className="relative w-[290px] h-[380px] rounded-[25px] shadow-[0px_0px_24px_rgba(255,255,255,0.65)] p-9 flex items-end transition-transform duration-300 transform hover:scale-110 overflow-hidden cursor-pointer">
            <img src={ObserverAcademyImg} alt="Observer Academy" className="absolute inset-0 w-full h-full object-cover grayscale transition-all duration-300 hover:grayscale-0 z-0" />
            <div className="absolute inset-0 bg-gradient-to-b from-transparent to-[#003b5c] mix-blend-multiply pointer-events-none"></div>
            <div className="relative z-10">
              <h2 className="text-white text-2xl font-light">Observer Academy</h2>
            </div>
          </div>

          <div className="relative w-[290px] h-[380px] rounded-[25px] shadow-[0px_0px_24px_rgba(255,255,255,0.65)] p-9 flex items-end transition-transform duration-300 transform hover:scale-110 overflow-hidden cursor-pointer">
            <img src={BluCaresImg} alt="Blu Cares" className="absolute inset-0 w-full h-full object-cover grayscale transition-all duration-300 hover:grayscale-0 z-0" />
            <div className="absolute inset-0 bg-gradient-to-b from-transparent to-[#003b5c] mix-blend-multiply pointer-events-none"></div>
            <div className="relative z-10">
              <h2 className="text-white text-2xl font-light">Blu Cares</h2>
            </div>
          </div>
        </div>

        {/* Go To Bottom Arrow */}
        <img src={GoToBottomArrow} className="mt-9" alt="Go to Bottom" aria-hidden />
      </div>
    </div>
  );
}
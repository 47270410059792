const PrimarySection = ({ isActive }) => (
    <div className={`w-full transition-opacity duration-500 ${isActive ? "opacity-100" : "opacity-0 hidden"}`}>
        <div className="flex flex-col w-[50%]">
            <h3 className="text-[12rem] h-56 font-bold text-[#003b5c] font-dmSerifDisplay">Jean</h3>
            <h3 className="text-[6rem] h-30 text-[#003b5c] font-thin">Parra</h3>
            <h3 className="uppercase font-bold text-xl text-[#003b5c] tracking-wide">Licensed Real Estate Broker.</h3>
            <div className="flex justify-between mt-8">
                <div className="w-[200px]">
                    <div className="flex items-center">
                        <div
                            className="w-24 h-24 bg-center p-8 bg-no-repeat"
                            style={{
                                backgroundImage:
                                    "url(https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/Icon%2FTelephone.svg?alt=media&token=6bec51ee-11a5-47a2-a687-7d2e00a0a227)",
                            }}>
                        </div>
                        <p className="ml-4 text-[1.5rem] font-bold text-[#003b5c]">Phone:</p>
                    </div>
                    <div className="text-right">
                        <p className="text-[1.5rem] font-light text-[#003b5c]">305.800.BLUR</p>
                        <p className="text-[1.5rem] text-[#003b5c] tracking-wider">(2587)</p>
                    </div>
                </div>
                <div className="w-[300px]">
                    <div className="flex items-center">
                        <div
                            className="w-24 h-24 bg-center bg-no-repeat"
                            style={{
                                backgroundImage:
                                    "url(https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/Icon%2FE-mail.svg?alt=media&token=69b3dc0e-2f78-44a5-8fe7-fe4ffdd9daa7)",
                            }}
                        ></div>
                        <p className="text-[1.5rem] font-bold text-[#003b5c]">E-Mail:</p>
                    </div>
                    <p className="text-center text-[1.5rem] text-[#003b5c] font-light">jp@blurealty.ai</p>
                </div>
            </div>
        </div>
    </div>
);

export default PrimarySection;

import React from "react";

const BluWhiteLogo =
  "https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/Logos%2FBluWhite.svg?alt=media&token=3cc5840f-1ea3-4b12-92c1-61303a0b940e";
const DownArrow =
  "https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/Icon%2FbioPageDownArrow.svg?alt=media&token=cbb3be6f-8c6a-4258-8ca0-ea6666e85a6c";

const HeroBioPage = () => {
  return (
    <div
      className="font-montserrat relative h-screen bg-cover bg-center"
      style={{
        backgroundImage: `url(https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/BioPage%2FImages%2FbioPageBg.jpg?alt=media&token=2d4dcc5c-d4db-4926-b046-e678f30bbeb3)`,
      }}
    >
      <div className="absolute inset-0 bg-[#043048] mix-blend-multiply opacity-70 z-0"></div>
      <section className="relative z-0 flex flex-col items-center justify-center h-full pt-32">
        <div className="mb-10">
          <img
            src={BluWhiteLogo}
            alt="Blu Realty & Investments"
            draggable="false"
            className="w-auto h-auto"
          />
        </div>
        <h1 className="text-3xl lg:text-4xl text-white font-thin uppercase text-center mb-16">
          Life is Sweet when Stress is Minimal!
        </h1>
        <div className="absolute bottom-16">
          <img
            draggable="false"
            src={DownArrow}
            alt="Down Arrow"
            className="w-auto h-auto"
          />
        </div>
      </section>
    </div>
  );
};

export default HeroBioPage;
import React from "react";
import { Data } from "../../../data/Data";

const BluStory = ({ isVisible }) => { 
  const data = Data.jp
  return (
    <div
      className={`flex flex-col justify-center items-center bg-cover w-full h-full z-30 bg-no-repeat bg-center transition-all overflow-hidden duration-700 ${!isVisible ? "hidden" : "block"}`}
      style={{
        backgroundImage: `url(${data.images.BluStoryBg})`,
        height: isVisible ? "40rem" : "0",
        opacity: isVisible ? 1 : 0,
      }}
    > 
      <img src={data.images.BluStoryBg} className="w-full z-0 absolute bg-cover"/>
      <h2 className="text-[1.5rem] text-white uppercase font-light border-b-2 absolute z-10 top-60 border-white pb-4">Blu Story</h2>
      <img src={data.images.BluStoryTimeLine} alt="Blu Story" className="absolute bottom-82 z-10 w-[75%] mt-20" />
    </div>
  );
};

export default BluStory;
import React, { useState, useEffect } from "react";

const StarContractSection = () => {
  const [inputsBuy, setInputBuy] = useState(false);
  const [inputsSell, setInputSell] = useState(false);
  const [inputsManagement, setInputManagement] = useState(false);

  const handleCheckboxBuy = () => {
    setInputBuy(!inputsBuy);
    setInputSell(false);
    setInputManagement(false);
  };
  const handleCheckboxSell = () => {
    setInputBuy(false);
    setInputSell(!inputsSell);
    setInputManagement(false);
  };
  const handleCheckboxManagement = () => {
    setInputBuy(false);
    setInputSell(false);
    setInputManagement(!inputsManagement);
  };

  return (
    <section
      className="relative w-full h-auto duration-300 backdrop-opacity-80 backdrop-invert mx-auto py-16 bg-gradient-to-t from-[#003b5c] to-transparent bg-cover bg-position-y-[75%] bg-no-repeat"
      style={{
        backgroundPositionY: "75%",
        backgroundImage: `url('https://console.catalyst.zoho.com/baas/v1/project/6905000000008864/folder/6905000000051112/file/16144000000028066/download?Environment=Production&catalyst_org=861843634')`,
      }}
    >
      {/* <div className="bg-[#061c294f] absolute z-4 w-full h-full top-0" /> */}
      <form className="z-10 flex flex-col items-center" method="post">
        <div className="text-center flex justify-center items-center flex-col h-auto w-full top-0">
          <p className="text-[2.5rem] font-bold text-white uppercase">
            Are you looking to:
          </p>
          <div className="flex items-center justify-center mt-4 space-x-6">
            <div className="flex flex-row items-center gap-2">
              <p className="text-white text-3xl font-bold">Buy</p>
              {inputsBuy === true ? (
                <label className="inline-block w-16 h-16 bg-[#2699fb] rounded-full border-8 border-white cursor-pointer" />
              ) : (
                <label
                  onClick={handleCheckboxBuy}
                  className="inline-block w-16 h-16 bg-white rounded-full border-2 border-gray-300 cursor-pointer"
                />
              )}
            </div>
            <div className="flex flex-row items-center gap-2">
              <p className="text-white text-3xl font-bold">Sell</p>
              {inputsSell === true ? (
                <label className="inline-block w-16 h-16 bg-[#2699fb] rounded-full border-8 border-white cursor-pointer" />
              ) : (
                <label
                  onClick={handleCheckboxSell}
                  className="inline-block w-16 h-16 bg-white rounded-full border-2 border-gray-300 cursor-pointer"
                />
              )}
            </div>
            <div className="flex flex-row items-center gap-2">
              <p className="text-white text-3xl font-bold">Management</p>
              {inputsManagement === true ? (
                <label className="inline-block w-16 h-16 bg-[#2699fb] rounded-full border-8 border-white cursor-pointer" />
              ) : (
                <label
                  onClick={handleCheckboxManagement}
                  className="inline-block w-16 h-16 bg-white rounded-full border-2 border-gray-300 cursor-pointer"
                />
              )}
            </div>
          </div>

          {inputsBuy && (
            <div className="flex flex-row justify-center items-center space-y-4 gap-4 w-full">
              <input type="text" placeholder="Name" className="hidden" />
              <input
                type="text"
                placeholder="Name"
                className="w-[30%] h-full p-3 border-2 max-w-md border-blue-200 rounded-lg text-blue-500 placeholder-blue-500"
              />
              <input
                type="email"
                placeholder="E-mail"
                className="w-[30%] h-full p-3 border-2 max-w-md border-blue-200 rounded-lg text-blue-500 placeholder-blue-500"
              />
              <input
                type="tel"
                placeholder="Phone"
                className="w-[30%] h-full p-3 border-2 max-w-md border-blue-200 rounded-lg text-blue-500 placeholder-blue-500"
              />
            </div>
          )}

          {inputsBuy && (
            <input
              type="submit"
              value="Submit"
              className="mt-8 px-8 py-4 text-2xl font-bold text-white bg-blue-500 rounded-lg hover:bg-blue-700 transition-all cursor-pointer"
            />
          )}

          {inputsManagement && (
            <div className="flex flex-row justify-center items-center space-y-4 gap-4 w-full">
              <input type="text" placeholder="Name" className="hidden" />
              <input
                type="text"
                placeholder="Name"
                className="w-[30%] h-full p-3 border-2 max-w-md border-blue-200 rounded-lg text-blue-500 placeholder-blue-500"
              />
              <input
                type="email"
                placeholder="E-mail"
                className="w-[30%] h-full p-3 border-2 max-w-md border-blue-200 rounded-lg text-blue-500 placeholder-blue-500"
              />
              <input
                type="tel"
                placeholder="Phone"
                className="w-[30%] h-full p-3 border-2 max-w-md border-blue-200 rounded-lg text-blue-500 placeholder-blue-500"
              />
            </div>
          )}

          {inputsManagement && (
            <input
              type="submit"
              value="Submit"
              className="mt-8 px-8 py-4 text-2xl font-bold text-white bg-blue-500 rounded-lg hover:bg-blue-700 transition-all cursor-pointer"
            />
          )}

          {inputsSell && (
            <div className="flex flex-row justify-center items-center space-y-4 gap-4 w-full">
              <input type="text" placeholder="Name" className="hidden" />
              <input
                type="text"
                placeholder="Name"
                className="w-[30%] h-full p-3 border-2 max-w-md border-blue-200 rounded-lg text-blue-500 placeholder-blue-500"
              />
              <input
                type="email"
                placeholder="E-mail"
                className="w-[30%] h-full p-3 border-2 max-w-md border-blue-200 rounded-lg text-blue-500 placeholder-blue-500"
              />
              <input
                type="tel"
                placeholder="Phone"
                className="w-[30%] h-full p-3 border-2 max-w-md border-blue-200 rounded-lg text-blue-500 placeholder-blue-500"
              />
            </div>
          )}

          {inputsSell && (
            <input
              type="submit"
              value="Submit"
              className="mt-8 px-8 py-4 text-2xl font-bold text-white bg-blue-500 rounded-lg hover:bg-blue-700 transition-all cursor-pointer"
            />
          )}
        </div>
      </form>
    </section>
  );
};

export default StarContractSection;

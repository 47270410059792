import React, { useState } from "react";
import "./ElDoradoSection.css";
const BluWorldElDoradoDiscovered_Title =
  "https://console.catalyst.zoho.com/baas/v1/project/6905000000008864/folder/6905000000051074/file/16144000000033128/download?Environment=Production&catalyst_org=861843634";
const BluWorldElDoradoDiscovered_ItWas =
  "https://console.catalyst.zoho.com/baas/v1/project/6905000000008864/folder/6905000000051074/file/16144000000033138/download?Environment=Production&catalyst_org=861843634";
const BluWorldElDoradoDiscovered_Happened =
  "https://console.catalyst.zoho.com/baas/v1/project/6905000000008864/folder/6905000000051074/file/16144000000033143/download?Environment=Production&catalyst_org=861843634";
const BluWorldElDoradoDiscovered_ToFix =
  "https://console.catalyst.zoho.com/baas/v1/project/6905000000008864/folder/6905000000051074/file/16144000000033133/download?Environment=Production&catalyst_org=861843634";

export default function BluWorldElDoradoDiscovered() {
  const [selected, setSelected] = useState(null);

  const handleSelect = (index) => {
    setSelected(index === selected ? null : index); // Si se hace clic de nuevo en el ya seleccionado, colapsarlo.
  };

  return (
    <div className="BluWorldElDoradoDiscovered">
      <div className="BluWorldElDoradoDiscovered_Title">
        <img src={BluWorldElDoradoDiscovered_Title} alt="" />
        <h2 className="BluWorldElDoradoDiscovered_SubTitle">Discovered</h2>
      </div>
      <div className="BluWorldElDoradoDiscovered_GalleryContainer">
        <div
          className={`BluWorldElDoradoDiscovered_GalleryItem BluWorldElDoradoDiscovered_GalleryItem--One ${
            selected === 1 ? "expanded" : selected ? "collapsed" : ""
          }`}
          onClick={() => handleSelect(1)}
        >
          <div className="BluWorldElDoradoDiscovered_GalleryItem--Information">
            <h5>
              {" "}
              <span className="Number"></span> Identifying the <br /> Damaged
              Area:
            </h5>
            <h6>
              The first step in restoring Lake Guatavita is to identify the area
              damaged by conquistadors in the 16th century, who cut a channel
              into the lake's hillside to drain the water. This channel must be
              assessed to determine the damage and plan for its restoration.
              Then, it will be filled using geotextile technology, local soil,
              and stones. Native vegetation will help reinforce the soil and
              prevent erosion, aiding in the recovery of the original ecosystem.
            </h6>
          </div>
          <div className="StageOneSvg" style={{ left:"695px", top:"264px" }}></div>
          <div className="ElDoradoDiscovered_ItemTitle">
            <h3>How</h3>
            <img src={BluWorldElDoradoDiscovered_ItWas} alt="" style={{ marginBottom: '-10px' }} />
          </div>
          <div className="ElDoradoDiscovered_ItemContent">
            <h2 className="ElDoradoDiscovered_ItemContent--Title">
              Muisca Ritual at Lake Guatavita
            </h2>
            <h4 className="ElDoradoDiscovered_ItemContent--Text">
              The legend of El Dorado originates from the Muisca rituals in
              Colombia. When a new zipa took power, he covered himself in gold
              dust and sailed to the center of Lake Guatavita, where he threw
              gold and emeralds into the water as offerings to the gods. This
              dazzling ritual attracted Spanish conquistadors in the 16th
              century, inspiring their search for the mythical golden city.
            </h4>
          </div>
        </div>
        <div
          className={`BluWorldElDoradoDiscovered_GalleryItem BluWorldElDoradoDiscovered_GalleryItem--Two ${
            selected === 2 ? "expanded" : selected ? "collapsed" : ""
          }`}
          onClick={() => handleSelect(2)}
        >
          <div className="BluWorldElDoradoDiscovered_GalleryItem--Information">
            <h5>
              {" "}
              <span className="Number"></span> Stabilization of <br /> the
              restored area
            </h5>
            <h6>
              As of today, this is how the lake is. The goal is to let the water
              naturally rise to its former level, restoring the lake. Once the
              channel fills, temporary barriers, reinforcement meshes, and
              native plants will help stabilize the area. Regular monitoring
              will ensure long-term stability and quick issue correction.
            </h6>
          </div>
          <div className="StageTwoSvg" style={{ top:"271px" }}></div>
          <div className="ElDoradoDiscovered_ItemTitle">
            <h3>What</h3>
            <img
              src={BluWorldElDoradoDiscovered_Happened}
              alt=""
              className="BluWorldElDoradoDiscovered_Happened"
            />
          </div>
          <div className="ElDoradoDiscovered_ItemContent">
            <h2 className="ElDoradoDiscovered_ItemContent--Title">
              Draining Lake Guatavita
            </h2>
            <h4 className="ElDoradoDiscovered_ItemContent--Text">
              Drawn by the promise of treasure, the Spanish conducted several
              expeditions to drain Lake Guatavita. In the 16th century, they cut
              a channel into the lake’s side to lower the water level. They
              recovered some gold, but the operation was not fully successful
              and caused significant environmental damage, altering the lake’s
              ecosystem and surrounding area.
            </h4>
          </div>
        </div>
        <div
          className={`BluWorldElDoradoDiscovered_GalleryItem BluWorldElDoradoDiscovered_GalleryItem--Three ${
            selected === 3 ? "expanded" : selected ? "collapsed" : ""
          }`}
          onClick={() => handleSelect(3)}
        >
          <div className="BluWorldElDoradoDiscovered_GalleryItem--Information">
            <h5>
              {" "}
              <span className="Number"></span> Natural Water <br /> Restoration
            </h5>
            <h6>
              Once the channel is closed, the water will gradually rise to its
              original level, allowing the lake to naturally restore itself over
              time. This process will bring the lagoon back to what it once was,
              ensuring that its natural balance and historical significance are
              preserved.
            </h6>
          </div>
          <div className="StageThreeSvg" style={{ left:"44%" }}></div>
          <div className="ElDoradoDiscovered_ItemTitle">
            <h3>How</h3>
            <img src={BluWorldElDoradoDiscovered_ToFix} alt="" />
          </div>
          <div className="ElDoradoDiscovered_ItemContent">
            <h2 className="ElDoradoDiscovered_ItemContent--Title">
              Guatavita's Greatest Treasure
            </h2>
            <h4 className="ElDoradoDiscovered_ItemContent--Text">
              It was determined that the true treasure of Lake Guatavita is the
              water itself, known for its healing properties—a secret kept by
              the Muisca. They valued the lake not only for its gold but for the
              health benefits the water provided. This perspective highlights
              the importance of natural resources and the wisdom of ancient
              cultures, offering a new understanding of the legend of El Dorado.
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
}

import React from "react";

const bgVideo =
  "https://console.catalyst.zoho.com/baas/v1/project/6905000000008864/folder/6905000000051112/file/16144000000028408/download?Environment=Production&catalyst_org=861843634";
const bgPoster =
  "https://console.catalyst.zoho.com/baas/v1/project/6905000000008864/folder/6905000000051112/file/16144000000028046/download?Environment=Production&catalyst_org=861843634";

const HeroSection = () => {
  return (
    <section className="font-montserrat relative h-screen overflow-hidden">
      <div className="absolute inset-0 z-0 bg-gradient-to-b from-transparent to-[#0524369d] transition"></div>

      <video
        muted
        autoPlay
        loop
        playsInline
        poster={bgPoster}
        alt="Photo of Sunny Isles"
        className="absolute inset-0 object-cover w-full h-full z-[-1]"
      >
        <source src={bgVideo} type="video/mp4" alt="Video of Sunny Isles" />
      </video>
      <form
        action="submit_form.php"
        className="absolute top-1/2 transform -translate-y-1/2 w-full z-20 flex justify-center px-8"
        method="post"
      >
        <input
          type="text"
          className="shadow-inner bg-white bg-opacity-85 text-[1.25rem] max-w-[50%] h-[60px] w-full px-[25px] font-sans rounded-[20px] text-gray-500 mr-8 outline-none"
          placeholder="Sales, Lease, Commercial"
        />
        <button className="w-full font-montserrat max-w-[15%] h-[60px] shadow-md cursor-pointer uppercase font-extrabold bg-[#2699fb] text-white rounded-[20px] text-[1.25rem] tracking-wider hover:bg-[#003b5c] transition-all">
          Search
        </button>
      </form>
    </section>
  );
};

export default HeroSection;

const AboutMeSection = ({ isActive }) => (
    <div
      className={`w-full transition-opacity duration-500 ${
        isActive ? "opacity-100" : "opacity-0 hidden"
      }`}
    >
      <h2 className="text-[3rem] text-[#003b5c] w-fit font-light uppercase border-b-4 border-[#003b5c] pb-2">About Me</h2>
      <div className="">
        <h3 className="text-[12rem] h-64 font-bold text-[#003b5c] font-dmSerifDisplay">Jean</h3>
        <h3 className="text-[2.5rem] text-[#003b5c] font-light">Parra</h3>
        <h3 className="uppercase font-bold text-xl text-[#003b5c] tracking-wide mt-4">
          Licensed Real Estate Broker.
        </h3>
        <p className="text-[1.5rem] text-justify text-[#003b5c] mt-8 leading-relaxed">
          Over two decades of passion for technology and real estate have led to
          the creation of the world's <strong>first AI-powered</strong> real
          estate company. With unwavering commitment to humility, gratitude, and
          meticulous attention to detail, I'm dedicated to helping clients and
          our team achieve their goals.
        </p>
      </div>
    </div>
  );
  
  export default AboutMeSection;
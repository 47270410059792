import React from "react";

const ObserverLogo =
  "https://console.catalyst.zoho.com/baas/v1/project/6905000000008864/folder/6905000000051074/file/16144000000033083/download?Environment=Production&catalyst_org=861843634";
const University =
  "https://console.catalyst.zoho.com/baas/v1/project/6905000000008864/folder/6905000000051074/file/16144000000033188/download?Environment=Production&catalyst_org=861843634";
const Student =
  "https://console.catalyst.zoho.com/baas/v1/project/6905000000008864/folder/6905000000051074/file/16144000000033193/download?Environment=Production&catalyst_org=861843634";
const Boy =
  "https://console.catalyst.zoho.com/baas/v1/project/6905000000008864/folder/6905000000051074/file/16144000000033168/download?Environment=Production&catalyst_org=861843634";

export default function ObserverSection() {
  return (
    <div className="min-h-[100vh] pt-24">
      <div className="relative flex flex-col items-center bg-cover bg-no-repeat bg-[#003b5c] w-full min-h-full">
        {/* Logo */}
        <div
          style={{ marginTop: '-21vh' }}
          className="absolute bg-white w-96 h-96 top-0 rounded-full flex justify-center items-center shadow-lg z-10"
        ><img src={ObserverLogo} alt="Observer Logo" className="w-3/4 h-auto" />
        </div>

        {/* Sección de título */}
        <div className="text-center mt-64 font-montserrat text-white">
          <h4 className="text-[1.5rem] font-bold">MEET THE</h4>
          <h4 className="text-[5rem] leading-none font-dmSerifDisplay font-bold mt-2">Observer Academy</h4>
          <p className="text-[1.5rem] mt-8 mx-16">
            Our mission is to empower the next generation of leaders through an AI infused learning from elementary to high school. We provide boarding facilities, meals, and a rigorous curriculum that emphasizes academic excellence. Our approach includes advanced technology training, communication skills development, and personal growth inspired by visionary American leaders. <br />
            <br />
            Situated in a peaceful countryside environment, we support orphaned and underprivileged children, equipping them with resilience, compassion, and a commitment to making a positive impact in their communities.
          </p>
        </div>

        {/* Tarjetas de imágenes */}
        <div className="flex justify-center gap-16 my-24">
          <div className="w-[362px] h-[510px] overflow-hidden rounded-3xl flex-shrink-0">
            <img src={Boy} alt="Boy" className="w-full h-full object-cover" />
          </div>
          <div className="w-[362px] h-[510px] overflow-hidden rounded-3xl flex-shrink-0">
            <img src={Student} alt="Student" className="w-full h-full object-cover object-center" />
          </div>
          <div className="w-[362px] h-[510px] overflow-hidden rounded-3xl flex-shrink-0">
            <img src={University} alt="University" className="w-full h-full object-cover" />
          </div>
        </div>
      </div>
      <div className="rotate-180 w-full relative">
      <div className="bg-[#003b5c] w-full h-[50px] z-40 absolute bottom-[-10px]"/>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path fill="#003b5c" fill-opacity="1" d="M0,256L40,240C80,224,160,192,240,154.7C320,117,400,75,480,53.3C560,32,640,32,720,69.3C800,107,880,181,960,224C1040,267,1120,277,1200,250.7C1280,224,1360,160,1400,128L1440,96L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"></path>
        </svg>
      </div>
    </div>
  );
}
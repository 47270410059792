import React from "react";
import { Data } from "../../../data/Data";

const BluWorld = ({ isVisible }) => {

  const data = Data.jp

  return (
    <div className={`h-screen flex flex-col justify-center items-center gap-10 transition-all font-montserrat duration-700 ${!isVisible ? "hidden" : "block"} overflow-hidden`
    } style={{ height: isVisible ? "auto" : "0", opacity: isVisible ? 1 : 0, }}>
      <img src={data.images.BluWorldLogo} className="w-[500px] bg-cover bg-center" />
      <h2 className="text-[3rem] text-center text-[#003b5c] font-light uppercase border-b border-[#003b5c] mx-auto w-fit pb-2">Building a Better World</h2>
      <p className="text-center text-blue-900 text-[1.5rem] font-light w-1/2 mx-auto my-8">
        <a href="#" className="text-[#003b5c] underline text-[1.5rem] font-bold">Blu World</a>, Blu Realty's non-profit foundation, focuses on ocean clean-up, sustainability, and restoration of nature's habitats.
      </p>
      <div className="w-full h-[400px] flex flex-row justify-center items-center gap-4">
        <img src={data.images.cleanOcean} className="rounded-2xl w-[250px] h-[300px] bg-cover bg-center hover:w-[275px] hover:h-[325px] hover:duration-200 duration-200" />
        <img src={data.images.Lake} className="rounded-2xl w-[250px] h-[300px] bg-cover bg-center hover:w-[275px] hover:h-[325px] hover:duration-200 duration-200" />
        <img src={data.images.beachClean} className="rounded-2xl w-[250px] h-[300px] bg-cover bg-center hover:w-[275px] hover:h-[325px] hover:duration-200 duration-200" />
        <img src={data.images.battery} className="rounded-2xl w-[250px] h-[300px] bg-cover bg-center hover:w-[275px] hover:h-[325px] hover:duration-200 duration-200" />
        <img src={data.images.turtle} className="rounded-2xl w-[250px] h-[300px] bg-cover bg-center hover:w-[275px] hover:h-[325px] hover:duration-200 duration-200" />
      </div>
      {/* <ul className="flex justify-center items-center gap-4 mx-auto carousel">
        <li className="bg-cover bg-center card" style={{ backgroundImage: `url('https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/BioPage%2FImages%2Fcarousel1.jpg?alt=media&token=91d97773-5908-4ff7-be3f-e858e496e685')`, width: '200px', height: '300px' }}></li>
        <li className="bg-cover bg-center card" style={{ backgroundImage: `url('https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/BioPage%2FImages%2Fcarousel2.jpg?alt=media&token=c400da4a-a94c-4856-9054-67cc6e9e817b')`, width: '200px', height: '300px' }}></li>
        <li className="bg-cover bg-center card" style={{ backgroundImage: `url('https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/BioPage%2FImages%2Fcarousel3.jpg?alt=media&token=6a743bc7-8495-4d10-ba00-12b7168cb891')`, width: '200px', height: '300px' }}></li>
        <li className="bg-cover bg-center card" style={{ backgroundImage: `url('https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/BioPage%2FImages%2Fcarousel4.jpg?alt=media&token=a126dbed-283c-4e8e-a217-4ce431f710ff&quot')`, width: '200px', height: '300px' }}></li>
        <li className="bg-cover bg-center card" style={{ backgroundImage: `url('https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/BioPage%2FImages%2Fcarousel5.jpg?alt=media&token=8cf40640-f91a-437a-8f02-448035d27f64&quot')`, width: '200px', height: '300px' }}></li>
      </ul> */}
      <h3 className="text-center text-blue-900 text-[1.5rem] font-light my-8">Shaping Tomorrow</h3>
    </div >
  );
};

export default BluWorld;
import HeroSection from "../../components/BluWorld/HeroSection";
import BeachCleanUpSection from "../../components/BluWorld/BeachCleanUpSection";
import ElDoradoSection from "../../components/BluWorld/ElDoradoSection";
import ObserverSection from "../../components/BluWorld/ObserverSection";
import BluCaresSection from "../../components/BluWorld/BluCaresSection";
import StayInTouchSection from "../../components/BluWorld/StayInTouchSection";
import Header from "../../blocks/Header";
import Quotes from "../../blocks/Quotes";

export default function BluWorld() {
    return (
        
       <>
            <Header />
            <HeroSection />
            <BeachCleanUpSection />
            {/* YA NO ESTA HARDCODEADO HASTA ACA */}
            <ElDoradoSection />
            <ObserverSection />
            <BluCaresSection />
            <StayInTouchSection />
            <Quotes />
        </>
        
    );
}
import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Data } from "../data/Data";


export default function Header() {

    const data = Data.navbar
    const BluLogo = data.icons.BluLogo
    const BluWhiteLogo = data.icons.BluWhiteLogo
    const UserIcon = data.icons.UserIcon
    const SearchIcon = data.icons.SearchIcon
    const BurgerIcon = data.icons.BurgerIcon
    const SearchBarIcon = data.icons.SearchBarIcon

    const [burgerMenuOpen, setBurgerMenuOpen] = useState(false);
    const location = useLocation();

    useEffect(() => {
        const Header = document.getElementById("Top");
        const HeaderLogo = document.getElementById("HeaderLogo");

        // Altura Dinamica segun la Ruta
        if (Header && HeaderLogo) {
            if (location.pathname === "/blu-world") {
                HeaderLogo.style.width = "100px";
                Header.style.height = "120px";
            } else {
                HeaderLogo.style.width = "120px";
                Header.style.height = "150px";
            }
        }
    }, [location.pathname]);

    const toggleBurgerMenu = () => {
        setBurgerMenuOpen(!burgerMenuOpen);
    };

    return (
        <div className="font-montserrat absolute z-10 w-full flex items-center justify-between px-32 py-4 bg-opacity-40 bg-white backdrop-blur-sm shadow-lg" id="Top">
            <Link to="/" className="header__logo--link">
                <div
                    className={`transition-all ${location.pathname === "/blu-world" ? "w-24" : "w-30"}`}
                    id="HeaderLogo"
                >
                    <img
                        draggable="false"
                        src={location.pathname === "/blu-world" ? BluWhiteLogo : BluLogo}
                        alt="Blu Realty & Investments Logo"
                        className="w-full h-auto"
                    />
                </div>
            </Link>
            <div className="block lg:hidden">
                <button className="flex items-center" onClick={toggleBurgerMenu}>
                    <img src={BurgerIcon} alt="" aria-hidden />
                    <p className="ml-4 text-[1.25rem] font-bold uppercase text-[#003b5c]">{data.bluNav.title}</p>
                </button>
            </div>
            <nav className="hidden lg:block">
                <ul className={`flex h-full gap-6 items-center space-x-4 transition-transform duration-300 ease-in-out transform ${burgerMenuOpen ? "translate-x-0" : "-translate-x-full"} lg:translate-x-0`}>
                    {location.pathname === "/blu-world" ? (
                        <>
                            <li className="uppercase text-white">
                                <Link to="#beach-clean" className="text-white">{data.bluNav.beachClean}</Link>
                            </li>
                            <li className="uppercase text-white">
                                <Link to="#el-dorado-discovered" className="text-white">{data.bluNav.doradoDiscovered}</Link>
                            </li>
                            <li className="uppercase text-white">
                                <Link to="#observer-academy" className="text-white">{data.bluNav.observerAcademy}</Link>
                            </li>
                            <li className="uppercase text-white">
                                <Link to="#blu-cares" className="text-white">{data.bluNav.bluCares}</Link>
                            </li>
                            <li>
                                <button className="bg-[#003b5c] text-white uppercase font-semibold px-4 py-2 rounded-lg">{data.bluNav.stayInTouch}</button>
                            </li>
                        </>
                    ) : (
                        <>
                            <li>
                                <Link to={data.hrefs.bookCall} className="text-[#003b5c] text-[1.25rem] h-full font-semibold hover:underline cursor-pointer">
                                    {data.links.bookCall}
                                </Link>
                            </li>
                            <li>
                                <Link to={data.hrefs.buying} className="text-[#003b5c] text-[1.25rem] h-full font-semibold hover:underline cursor-pointer">
                                    {data.links.buying}
                                </Link>
                            </li>
                            <li>
                                <Link to={data.hrefs.selling} className="text-[#003b5c] text-[1.25rem] h-full font-semibold hover:underline cursor-pointer">
                                    {data.links.selling}
                                </Link>
                            </li>
                            <li>
                                <Link to={data.hrefs.management} className="text-[#003b5c] text-[1.25rem] h-full font-semibold hover:underline cursor-pointer">
                                    {data.links.management}
                                </Link>
                            </li>
                            <li>
                                <Link to={data.hrefs.marketReport} className="text-[#003b5c] text-[1.25rem] font-semibold hover:underline cursor-pointer">
                                    {data.links.marketReport}
                                </Link>
                            </li>
                        </>
                    )}

                    {location.pathname !== "/blu-world" && (
                        <>
                            <li>
                                <Link to={data.links.login} className="bg-[#2699fb] text-white text-[1.25rem] uppercase font-bold px-3 py-2 rounded-md flex items-center">
                                    <img src={UserIcon} alt="Log In" className="w-6 h-6 mr-2" />
                                    {data.links.login}
                                </Link>
                            </li>
                            <li>
                                <button className="bg-[#2699fb] text-[1.25rem] rounded-full w-14 h-14 flex justify-center items-center">
                                    <img src={SearchIcon} alt="Search" className="w-6 h-6" />
                                </button>
                            </li>
                        </>
                    )}
                </ul>
            </nav>

            {burgerMenuOpen && (
                <div className="fixed top-0 left-0 h-full w-full bg-white bg-opacity-90 z-20 flex flex-col justify-center items-center">
                    <button onClick={() => setBurgerMenuOpen(false)} className="absolute top-4 right-4">
                        <img src={BurgerIcon} alt="Close Menu" className="w-8 h-8" />
                    </button>
                    <ul className="text-center">
                        <li className="text-[1.25rem] mb-4">
                            <Link to={data.bluNavHash.beachClean} className="text-[#003b5c]">{data.bluNavHashText.beachClean}</Link>
                        </li>
                        <li className="text-[1.25rem] mb-4">
                            <Link to={data.bluNavHash.doradoDiscovered} className="text-[#003b5c]">{data.bluNavHashText.doradoDiscovered}</Link>
                        </li>
                        <li className="text-[1.25rem] mb-4">
                            <Link to={data.bluNavHash.observerAcademy} className="text-[#003b5c]">{data.bluNavHashText.observerAcademy}</Link>
                        </li>
                        <li className="text-[1.25rem] mb-4">
                            <Link to={data.bluNavHash.bluCares} className="text-[#003b5c]">{data.bluNavHashText.bluCares}</Link>
                        </li>
                    </ul>
                </div>
            )}
        </div>
    );
};
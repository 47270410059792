import React, { useState } from "react";
import Popup from "../../blocks/Popup";
import { Data } from "../../data/Data";

export default function ServicesSectionNew() {
    
    const data = Data.home

    const SunnyIsles2 = data.icons.SunnyIsles2
    const ApartBedroom = data.icons.ApartBedroom
    const SellingIcon = data.icons.SellingIcon
    const AcquisitionsIcon = data.icons.AcquisitionsIcon
    const DownArrow = data.icons.DownArrow
    const CloseIcon = data.icons.CloseIcon

    const [acquisitionView, setAcquisitionView] = useState(false)
    const [sellingView, setSellingView] = useState(false)

    const [popupVisibleSelling, setPopupVisibleSelling] = useState(false)
    const [popupAcquisitionSelling, setPopupVisibleAcquisition] = useState(false)

    const InputComponent = (props) => {
        return (
            <input type="text" name={props.name} placeholder={props.ph} className="font-bold text-[1.25rem] w-full p-6 border rounded-lg border-[#2698fb69] placeholder:text-[#2699fb]" />
        )
    }

    return (
        <section className="relative flex justify-center items-center w-full h-[80vh] mt-2 font-montserrat">

            {/* SERVICES SELLING*/}
            <div onClick={() => setPopupVisibleSelling(true)} className="relative h-full w-full bg-cover bg-center cursor-pointer flex flex-col justify-center items-center text-center p-40 text-2xl text-white" style={{ backgroundImage: `url(${SunnyIsles2})` }} onMouseEnter={() => setSellingView(true)} onMouseLeave={() => setSellingView(false)}>
                <div className="absolute bg-[#0e2d41b6] duration-300 hover:bg-[#00192Bd3] w-full h-full flex justify-center items-center flex-col gap-4">
                    {
                        sellingView ?
                            <>
                                <img className="w-[50%] h-[50%] translate-y-0 duration-300" src={SellingIcon} />
                                <h1 className="translate-y-0 duration-300 text-[2.5rem] font-bold">SELLING</h1>
                                <p className="px-[10%] text-[1.25rem] translate-y-0 duration-300">As the world's first AI-powered real estate company, wehave created a platform that frees our sales team to build relationships, while our expert in-house maintenance technicians resolve property needs.</p>
                                <p className="px-[10%] text-[2rem] translate-y-0 duration-300 font-dmSerifDisplay">Smooth transaction!</p>
                            </> :
                            <>
                                <img className="w-[50%] h-[50%] translate-y-6 duration-300" src={SellingIcon} />
                                <h1 className="translate-y-6 duration-300 text-[2.5rem] font-bold">SELLING</h1>
                                <p className="px-[10%] text-[1.25rem] translate-y-6 duration-300"></p>
                                <p className="px-[10%] text-[2rem] translate-y-6 duration-300"></p>
                            </>
                    }
                </div>
            </div>
            {/* SERVICES ACQUISITION*/}
            <div onClick={() => setPopupVisibleAcquisition(true)} className="relative h-full w-full bg-cover bg-center cursor-pointer flex flex-col justify-center items-center text-center p-40 text-2xl text-white" style={{ backgroundImage: `url(${ApartBedroom})` }} onMouseEnter={() => setAcquisitionView(true)} onMouseLeave={() => setAcquisitionView(false)}>
                <div className="absolute bg-[#0e2d41b6] duration-300 hover:bg-[#00192Bd3] w-full h-full flex justify-center items-center flex-col gap-4">
                    {
                        acquisitionView ?
                            <>
                                <img className="w-[50%] h-[50%] translate-y-0 font-d duration-300" src={AcquisitionsIcon} />
                                <h1 className="translate-y-0 duration-300 text-[2.5rem] font-bold">ACQUISITIONS</h1>
                                <p className="px-[10%] text-[1.25rem] translate-y-0 duration-300">Specializing in Miami, the Blu buying experience is simple.<br />Grow your portfolio without management worries.</p>
                                <p className="px-[10%] text-[2rem] translate-y-0 duration-300 font-dmSerifDisplay">Life is sweeter when stress is minimal!</p>
                            </> :
                            <>
                                <img className="w-[50%] h-[50%] translate-y-6 font-d duration-300" src={AcquisitionsIcon} />
                                <h1 className="translate-y-6 duration-300 text-[2.5rem] font-bold">ACQUISITIONS</h1>
                                <p className="px-[10%] text-[1.25rem] translate-y-6 duration-300"></p>
                                <p className="px-[10%] text-[2rem] translate-y-6 duration-300"></p>
                            </>
                    }
                </div>
            </div>

            {/* POP UP SELLING*/}
            <Popup isOpen={popupVisibleSelling} onClose={() => setPopupVisibleSelling(false)}>
                <div className="w-full h-[30%] p-16 flex flex-col justify-center rounded-t-[70px] bg-[#003b5c] text-white">
                    <h3 className="w-full text-[2.5rem] font-bold text-center mb-4">SELLING SERVICES</h3>
                    <p className="w-full text-[1.5rem] text-center mb-4">
                        Skilled negotiators. Proven marketing strategies: exposure in <br />
                        100+ real estate websites, social media & direct mail generate a <br />
                        frenzy of active buyers and deliver{" "}
                        <strong>Top Dollar</strong> results.
                    </p>
                </div>
                <form className="flex flex-col relative justify-center items-center gap-10 p-16 pt-24 w-full h-[60%]">
                    <img src={DownArrow} className="rounded-full h-28 w-28 p-6 bg-white absolute top-[-50px] shadow-lg" />
                    <div className="flex space-x-4 w-full">
                        <InputComponent name="sellingServicesName" ph="Name" />
                        <InputComponent name="sellingServicesEmail" ph="E-mail" />
                        <InputComponent name="sellingServicesPhone" ph="Phone" />
                    </div>
                    <InputComponent name="sellingServicesAddress" ph="Complete Address, Zip Code" />
                    <InputComponent name="sellingServicesAddress" ph="Are there any additions or remodels that add value?" />
                    <button className="cursor-pointer font-extrabold mb-12 py-6 px-14 bg-[#2699fb] text-white rounded-[25px] text-[1.5rem] hover:bg-[#003b5c] duration-200">SUBMIT</button>
                </form>

                <img onClick={() => setPopupVisibleSelling(false)} src={CloseIcon} className="cursor-pointer rounded-full h-28 w-28 p-6 bg-white absolute bottom-[-50px] left-[45.5%] shadow-lg" />
            </Popup>
            {/* POP UP ACQUISITION */}
            <Popup isOpen={popupAcquisitionSelling} onClose={() => setPopupVisibleAcquisition(false)}>
                <div className="w-full h-[30%] p-16 flex flex-col justify-center text-[#003b5c] gap-10">
                    <h3 className="w-full text-[2.5rem] font-bold text-center mb-4">OUR CLIENTS ENJOY</h3>
                    <div className="flex flex-row w-full justify-around items-center">
                        <div className="flex flex-col justify-center items-center w-full h-full"><img className="w-32 h-32" src={data.icons.marketROIAnalysis} /><p className="text-center text-[1.5rem] text-[#003b5c]">Market and ROI Analysis</p></div>
                        <div className="flex flex-col justify-center items-center w-full h-full"><img className="w-32 h-32" src={data.icons.GDCReports} /><p className="text-center text-[1.5rem] text-[#003b5c]">Geographical, Demographical & Censual Reports</p></div>
                        <div className="flex flex-col justify-center items-center w-full h-full"><img className="w-32 h-32" src={data.icons.foreclosureAcquisition} /><p className="text-center text-[1.5rem] text-[#003b5c]">Foreclosure Acquisitions</p></div>
                    </div>
                    <div className="flex flex-row w-full justify-around items-center">
                        <div className="flex flex-col justify-center items-center w-full h-full"><img className="w-32 h-32" src={data.icons.assetsPreservation} /><p className="text-center text-[1.5rem] text-[#003b5c]">Asset Preservation</p></div>
                        <div className="flex flex-col justify-center items-center w-full h-full"><img className="w-32 h-32" src={data.icons.clearBudgets} /><p className="text-center text-[1.5rem] text-[#003b5c]">Clear Budgets & Insightful Reports</p></div>
                        <div className="flex flex-col justify-center items-center w-full h-full"><img className="w-32 h-32" src={data.icons.residentRetention} /><p className="text-center text-[1.5rem] text-[#003b5c]">Resident Retention</p></div>
                    </div>
                </div>
                <div className="w-full h-80 relative">
                    <img className="absolute z-0 w-full h-full" src={data.images.popupAcquisition.Visa} />
                    <div className="absolute z-1 w-full h-full flex flex-col justify-center items-start pl-[25%] text-white text-[1.5rem]">
                        <p className="text-4xl">Local Insurers & Tax Attorneys</p>
                        <p className="flex flex-row justify-center items-center gap-4"><img src={data.images.popupAcquisition.iconImageVisa}/>Liability</p>
                        <p className="flex flex-row justify-center items-center gap-4"><img src={data.images.popupAcquisition.iconImageVisa}/>Ordinance Compliance & Lien Reduction</p>
                        <p className="flex flex-row justify-center items-center gap-4"><img src={data.images.popupAcquisition.iconImageVisa}/>Domestic and Foreign Businesses Formations</p>
                        <p className="flex flex-row justify-center items-center gap-4"><img src={data.images.popupAcquisition.iconImageVisa}/>Trusts & Estate Planning</p>
                    </div>
                </div>
                <p className="text-[#003b5c] text-[1rem] w-full text-center mt-16">By focusing on the detailed needs of real estate investment, we cultivate long-term & mutually successful relationships.</p>
                <form className="flex flex-col relative justify-center items-center gap-10 p-16 pt-24 w-full h-[60%]">
                    <div className="flex space-x-4 w-full">
                        <InputComponent name="sellingServicesName" ph="Name" />
                        <InputComponent name="sellingServicesEmail" ph="E-mail" />
                        <InputComponent name="sellingServicesPhone" ph="Phone" />
                    </div>
                    <InputComponent name="sellingServicesAddress" ph="Complete Address, Zip Code" />
                    <InputComponent name="sellingServicesAddress" ph="Are there any additions or remodels that add value?" />
                    <button className="cursor-pointer font-extrabold mb-12 py-6 px-14 bg-[#2699fb] text-white rounded-[25px] text-[1.5rem] hover:bg-[#003b5c] duration-200">SUBMIT</button>
                </form>
                <img onClick={() => setPopupVisibleAcquisition(false)} src={CloseIcon} className="cursor-pointer rounded-full h-28 w-28 p-6 bg-white absolute bottom-[-50px] left-[45.5%] shadow-lg" />
            </Popup>

        </section>
    );
}
import React, { useState } from "react";
import Popup from "../../blocks/Popup";
import { Data } from "../../data/Data";

const MaintenanceIcon =
  "https://console.catalyst.zoho.com/baas/v1/project/6905000000008864/folder/6905000000051131/file/6905000000049202/download?Environment=Development&catalyst_org=861843634";
const ReportingIcon =
  "https://console.catalyst.zoho.com/baas/v1/project/6905000000008864/folder/6905000000051131/file/6905000000049207/download?Environment=Development&catalyst_org=861843634";
const ScreeningIcon =
  "https://console.catalyst.zoho.com/baas/v1/project/6905000000008864/folder/6905000000051131/file/6905000000049212/download?Environment=Development&catalyst_org=861843634";
const EvictionsIcon =
  "https://console.catalyst.zoho.com/baas/v1/project/6905000000008864/folder/6905000000051131/file/6905000000049217/download?Environment=Development&catalyst_org=861843634";
const FreeValuationIcon =
  "https://console.catalyst.zoho.com/baas/v1/project/6905000000008864/folder/6905000000051131/file/6905000000049222/download?Environment=Development&catalyst_org=861843634";
const CloseIcon =
  "https://console.catalyst.zoho.com/baas/v1/project/6905000000008864/folder/6905000000051131/file/6905000000049197/download?Environment=Development&catalyst_org=861843634";

const CoreServices = () => {
  const data = Data.home;

  const [popUp, setPopUp] = useState(null);
  const [isVisible, setIsVisible] = useState(false);

  function OpenPopup(name) {
    setPopUp(name);
    setIsVisible(true);
  }

  const CoreService = ({ id, imgSrc, title, onClick }) => (
    <div
      className="flex flex-col justify-center items-center text-center cursor-pointer max-w-80 w-full max-h-80 h-full"
      onClick={onClick}
    >
      <img
        src={imgSrc}
        alt={`${title} Services`}
        className="p-10 w-[80%] h-[80%] rounded-[25%] shadow-lg"
      />
      <p className="text-[1.5rem] font-semibold text-[#003b5c] mt-4">{title}</p>
    </div>
  );

  const InputComponent = (props) => {
    return (
      <input
        type="text"
        name={props.name}
        placeholder={props.ph}
        className="font-bold text-xl w-full p-6 border rounded-lg border-[#2698fb69] placeholder:text-[#2699fb]"
      />
    );
  };

  return (
    <section className="flex flex-col justify-evenly items-center w-full h-full font-montserrat">
      <div className="my-8">
        <button className="bg-[#2699fb] rounded-[25px] text-white font-bold text-[2.5rem] py-6 px-10 shadow-lg hover:bg-blue-500 transition">
          GET IN TOUCH WITH US
        </button>
      </div>
      <h2 className="text-[2.5rem] font-bold text-[#003b5c] uppercase mb-8">
        Intelligent Real Estate Management
      </h2>
      <div className="relative flex justify-center items-center gap-2 w-3/4">
        <CoreService
          id="coreService1"
          imgSrc={MaintenanceIcon}
          title="Maintenance"
          onClick={() => OpenPopup("maintenance")}
        />
        <CoreService
          id="coreService2"
          imgSrc={ReportingIcon}
          title="Reporting"
          onClick={() => OpenPopup("reporting")}
        />
        <CoreService
          id="coreService3"
          imgSrc={ScreeningIcon}
          title="Screening"
          onClick={() => OpenPopup("screening")}
        />
        <CoreService
          id="coreService4"
          imgSrc={EvictionsIcon}
          title="Evictions"
          onClick={() => OpenPopup("evictions")}
        />
        <CoreService
          id="coreService5"
          imgSrc={FreeValuationIcon}
          title="Free Valuation"
          onClick={() => OpenPopup("freeValuation")}
        />
        {/* Popups */}
        <Popup isOpen={isVisible} onClose={() => setIsVisible(false)}>
          {(popUp === "maintenance" && (
            <div className="rounded-[50%] flex flex-col justify-between mt-10 items-center gap-10 bg-cover text-[#003b5c]">
              <div className="w-full h-[50%] flex flex-row justify-center items-center">
                <div className="flex flex-col justify-center items-center w-[30%] ml-[10%] pr-20 h-[50%] border-r-4 border-r-[#3cd7f8] gap-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="160px"
                    height="auto"
                    viewBox="0 0 177.473 38.31"
                  >
                    <defs>
                      <clipPath id="clip-path">
                        <rect
                          id="Rectángulo_4821"
                          data-name="Rectángulo 4821"
                          width="177.473"
                          height="38.31"
                          fill="#003b5c"
                        ></rect>
                      </clipPath>
                    </defs>
                    <g
                      id="Grupo_9961"
                      data-name="Grupo 9961"
                      transform="translate(0 0)"
                    >
                      <g
                        id="Grupo_9960"
                        data-name="Grupo 9960"
                        transform="translate(0 0)"
                        clip-path="url(#clip-path)"
                      >
                        <path
                          id="Trazado_6325"
                          data-name="Trazado 6325"
                          d="M87.176,37.679c-3.262-2.464-6.573-4.866-9.758-7.425a2.087,2.087,0,0,0-3.151,0c-3.185,2.559-6.5,4.961-10.508,7.991,1.363-4.58,2.251-8.415,3.673-12.04.927-2.364.2-3.541-1.609-4.823a58.666,58.666,0,0,1-8.716-7.414c3.488,0,7-.241,10.457.073,3,.273,4.105-.944,4.757-3.671A49.25,49.25,0,0,1,76.17,0c1.146,3.757,2.357,7.495,3.413,11.278.539,1.932,1.4,2.876,3.619,2.751a51.993,51.993,0,0,1,11.532.6c-2.951,2.261-5.849,4.6-8.874,6.755-1.805,1.289-2.533,2.468-1.6,4.825,1.415,3.581,2.3,7.371,3.414,11.071l-.5.4"
                          transform="translate(13.209 0)"
                          fill="#003b5c"
                        ></path>
                        <path
                          id="Trazado_6326"
                          data-name="Trazado 6326"
                          d="M5.774,37.464c1.209-4.01,2.051-7.336,3.236-10.535.7-1.884.238-2.907-1.306-3.989a52.68,52.68,0,0,1-7.7-6.5c3.242,0,6.489-.115,9.722.043,1.952.095,2.841-.577,3.325-2.469.83-3.247,1.927-6.426,3.174-10.486,1.175,3.694,2.337,6.613,3,9.644.587,2.688,1.851,3.573,4.552,3.327a31.174,31.174,0,0,1,8.8.525c-2.788,2.15-5.525,4.369-8.386,6.418a2.16,2.16,0,0,0-.874,2.887,72.816,72.816,0,0,1,2.806,10.648c-2.568-1.943-5.261-3.747-7.662-5.878-1.71-1.517-2.866-1.3-4.473.078-2.415,2.062-5.036,3.884-8.207,6.291"
                          transform="translate(0 0.815)"
                          fill="#003b5c"
                        ></path>
                        <path
                          id="Trazado_6327"
                          data-name="Trazado 6327"
                          d="M143.72,36.809c-2.747-2.085-5.538-4.113-8.22-6.277-1.155-.933-1.938-1.052-3.166-.032-2.652,2.2-5.476,4.2-8.916,6.8,1.224-4.052,2.092-7.408,3.277-10.647.635-1.737.329-2.688-1.162-3.726a49.14,49.14,0,0,1-7.784-6.643c3.244,0,6.5-.131,9.732.049,1.888.106,2.683-.622,3.156-2.322a59.762,59.762,0,0,1,3.53-9.812c1,3.226,2.114,6.421,2.944,9.69.488,1.925,1.433,2.523,3.35,2.436a46.73,46.73,0,0,1,9.777.512c-2.6,2-5.13,4.09-7.813,5.967-1.57,1.1-1.957,2.142-1.268,4,1.162,3.132,2,6.387,2.969,9.589l-.405.417"
                          transform="translate(27.235 0.971)"
                          fill="#003b5c"
                        ></path>
                        <path
                          id="Trazado_6328"
                          data-name="Trazado 6328"
                          d="M44.3,4.328c1.043,3.342,2.171,6.66,3.091,10.035a2.2,2.2,0,0,0,2.589,1.917,54.8,54.8,0,0,1,10.338.544c-2.671,2.054-5.272,4.2-8.038,6.121A2.673,2.673,0,0,0,51.135,26.5c1.111,3.329,2.112,6.695,3.4,10.806-3.413-2.576-6.234-4.564-8.879-6.762-1.3-1.081-2.166-.951-3.389.042-2.61,2.119-5.335,4.1-8.5,6.5a49.117,49.117,0,0,1,2.762-9.445c.938-2.454.552-4.008-1.779-5.447a32.235,32.235,0,0,1-6.887-5.95c3.341,0,6.684-.078,10.019.034a2.319,2.319,0,0,0,2.746-2.061c.882-3.326,2.008-6.586,3.035-9.874l.637-.022"
                          transform="translate(6.444 1.001)"
                          fill="#003b5c"
                        ></path>
                        <path
                          id="Trazado_6329"
                          data-name="Trazado 6329"
                          d="M115.952,36.755c-2.831-2.126-5.706-4.194-8.465-6.408a1.788,1.788,0,0,0-2.711.03c-2.762,2.209-5.637,4.278-9.118,6.892,1.153-3.792,1.976-6.908,3.063-9.928.751-2.088.649-3.515-1.43-4.843a38.439,38.439,0,0,1-7.327-6.235c3.107,0,6.218-.107,9.318.038,2.048.095,3.2-.321,3.694-2.618a41.022,41.022,0,0,1,3.442-9.4c1.008,3.208,2.171,6.378,2.961,9.639.485,1.992,1.485,2.45,3.336,2.378a47.059,47.059,0,0,1,9.742.531c-2.4,1.852-4.672,3.9-7.227,5.5-2.251,1.412-2.506,2.953-1.635,5.259,1.084,2.878,1.888,5.861,2.812,8.8l-.456.371"
                          transform="translate(20.809 0.99)"
                          fill="#003b5c"
                        ></path>
                      </g>
                    </g>
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="280px"
                    height="auto"
                    viewBox="0 0 321 183"
                  >
                    <path
                      class="20Years"
                      id="20Years"
                      data-name="20Years"
                      d="M232,242V205.75l63.25-58.5a65.844,65.844,0,0,0,8.875-10A28.588,28.588,0,0,0,308,129.625a22.472,22.472,0,0,0,1-6.375q0-6.5-4.25-10.125T292,109.5a28.6,28.6,0,0,0-25.75,16.25l-44.5-22.25q9.5-18,28.5-29.25T297.5,63q20.75,0,36.75,6.75t25,19q9,12.25,9,29a69.86,69.86,0,0,1-2.125,17,55.518,55.518,0,0,1-8.625,17.875Q351,162,338.25,173.5l-47.5,43.25-9.25-20.5h90.75V242Zm231.5,4q-23.25,0-41.125-10.75t-28-31.25q-10.125-20.5-10.125-49.5T394.375,105q10.125-20.5,28-31.25T463.5,63q23.25,0,41.125,10.75t28,31.25q10.125,20.5,10.125,49.5T532.625,204q-10.125,20.5-28,31.25T463.5,246Zm0-47.25a15,15,0,0,0,10.25-4q4.5-4,7.125-13.75t2.625-26.5q0-17-2.625-26.625T473.75,114.25a15.133,15.133,0,0,0-20.5,0q-4.5,4-7.125,13.625T443.5,154.5q0,16.75,2.625,26.5t7.125,13.75A15,15,0,0,0,463.5,198.75Z"
                      transform="translate(-221.75 -63)"
                      fill="#003b5c"
                    ></path>
                  </svg>
                  <p className="text-[#3cd7f8] text-[3rem] font-bold w-full text-center">
                    YEARS
                  </p>
                </div>
                <div className="flex flex-col justify-center items-center w-full h-full gap-2 mr-[10%] ml-[10%]">
                  <p className="text-[#3cd7f8] text-[2rem] font-bold w-full text-center">
                    Quality Assurance
                  </p>
                  <p className="text-[#003b5c] text-[1.5rem] w-full text-center">
                    With over 20 years experience in Real Estate development,our
                    expertise expands from raw-land to the finished product.
                  </p>
                </div>
              </div>
              <img
                src={data.images.popupCoreServices.beach}
                className="w-full"
              />
              <img
                onClick={() => setIsVisible(false)}
                src={CloseIcon}
                className="cursor-pointer rounded-full h-28 w-28 p-6 bg-white absolute bottom-[-50px] left-[45.5%] shadow-lg"
              />
            </div>
          )) ||
            (popUp === "reporting" && (
              <div className="rounded-[50%] flex flex-row justify-between mt-10 items-center gap-10 bg-cover text-[#003b5c]">
                <div className="flex flex-col justify-center items-center w-[50%] h-full gap-10">
                  <img
                    src={data.images.popupCoreServices.reporting}
                    className="w-[75%]"
                  />
                  <img
                    src={data.images.popupCoreServices.textReporting}
                    className="w-full h-full"
                  />
                  <p className="text-[#003b5c] text-[1.5rem] w-[50%] text-center">
                    Maximize returns through robust reporting and risk
                    management.
                  </p>
                </div>
                <img
                  src={data.images.popupCoreServices.beachReporting}
                  className="w-[50%] h-full m-0 p-0"
                />
                <img
                  onClick={() => setIsVisible(false)}
                  src={CloseIcon}
                  className="cursor-pointer rounded-full h-28 w-28 p-6 bg-white absolute bottom-[-50px] left-[45.5%] shadow-lg"
                />
              </div>
            )) ||
            (popUp === "screening" && (
              <div className="rounded-[50%] flex flex-col justify-between mt-10 items-center gap-10 bg-cover text-[#003b5c]">
                <h3 className="text-[#3cd7f8] w-auto text-[2.5rem] font-bold text-center">
                  Screening
                </h3>
                <p className="text-[#003b5c] text-[1.5rem] w-[50%] text-center">
                  We use major U.S. reporting agencies and leading national
                  database screening solutions to verify: income, credit, rental
                  and in-depth criminal/sex offender background history.
                </p>
                <img
                  src={data.images.popupCoreServices.beachScreening}
                  className="w-full h-full m-0 p-0"
                />
                <img
                  onClick={() => setIsVisible(false)}
                  src={CloseIcon}
                  className="cursor-pointer rounded-full h-28 w-28 p-6 bg-white absolute bottom-[-50px] left-[45.5%] shadow-lg"
                />
              </div>
            )) ||
            (popUp === "evictions" && <div></div>) ||
            (popUp === "freeValuation" && (
              <div className="relative w-full h-wull p-16 flex flex-col justify-center text-[#003b5c] gap-4">
                <form className="flex flex-col relative justify-center items-center gap-20 w-full h-[60%]">
                  <h3 className="w-full text-[2.5rem] font-bold text-center">
                    How much is my Property Worth?
                  </h3>
                  <p className="text-[#003b5c] text-[1.5rem] w-full text-center">
                    Complete to receive your free valuation report
                  </p>
                  <div className="flex space-x-4 w-full">
                    <InputComponent name="sellingServicesName" ph="Name" />
                    <InputComponent name="sellingServicesEmail" ph="E-mail" />
                    <InputComponent name="sellingServicesPhone" ph="Phone" />
                  </div>
                  <InputComponent
                    name="sellingServicesAddress"
                    ph="Complete Address, Zip Code"
                  />
                  <InputComponent
                    name="sellingServicesAddress"
                    ph="Are there any additions or remodels that add value?"
                  />
                  <button className="cursor-pointer font-extrabold py-6 mb-8 px-14 bg-[#2699fb] text-white rounded-[25px] text-[1.5rem] hover:bg-[#003b5c] duration-200">
                    SUBMIT
                  </button>
                </form>
                <img
                  onClick={() => setIsVisible(false)}
                  src={CloseIcon}
                  className="cursor-pointer rounded-full h-28 w-28 p-6 bg-white absolute bottom-[-50px] left-[45.5%] shadow-lg"
                />
              </div>
            ))}
        </Popup>
      </div>
    </section>
  );
};

export default CoreServices;

import { Data } from "../../data/Data";

export default function HandSection({ handlePrint }) {
  const data = Data
  const HandImage = data.navbar.HandImage
  const PrintImage = data.navbar.PrintImage
  const SendImage = data.navbar.SendImage
  return (
    <div
      className="content-col HandSection"
      style={{
        position: "relative",
        height: "100%",
        alignContent: "center",
        textAlign: "center",
      }}
    >
      <label
        className="xlLabel"
        style={{
          position: "absolute",
          width: "100%",
          marginTop: "7%",
        }}
      >
        Sincerely!
      </label>
      <div className="HandContainer">
        <img src={HandImage} className="content-img" alt="HeroImage" />
        <div className="triangle-down"></div>
      </div>
      <div className="print-send-images-container" onClick={handlePrint}>
        <p
          className="xlLabel"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          <img src={PrintImage} className="print-send-icon" />
          Print
        </p>
        <p
          className="xlLabel"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          Send
          <img src={SendImage} className="print-send-icon" />
        </p>
      </div>
    </div>
  );
}

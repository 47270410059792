import React, { useState } from "react";

const AgentContact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form data:", formData);
    // Aquí puedes agregar la lógica para enviar los datos del formulario
  };

  return (
    <section className="font-montserrat h-screen w-full bg-[#CCF5FE] px-[5rem] flex flex-col justify-center items-center">
      <div className="max-h-max w-full flex items-center justify-center mb-[3.5rem]">
        <div className="w-1/2">
          <h2 className="font-dmSerifDisplay text-[5rem] text-[#003B5C]">Let's Start</h2>
          <h2 className="uppercase text-[#003B5C] font-light text-[3rem] pb-4 border-b border-[#2699FB] w-fit">
            The Conversation!
          </h2>
          <p className="mt-4 text-[2rem] text-[#003B5C]">Please verify we got it right!</p>
        </div>
        <div className="w-1/2">
          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="flex flex-col gap-4">
              <input
                type="text"
                name="name"
                placeholder="Name"
                value={formData.name}
                onChange={handleChange}
                className="text-[#2699FB] py-[2%] px-[5%] rounded-lg border-2 border-[#2699FB] placeholder:text-[#2699fb] placeholder:font-bold text-[1.5rem] placeholder-bold"
              />
              <input
                type="email"
                name="email"
                placeholder="E-mail"
                value={formData.email}
                onChange={handleChange}
                className="text-[#2699FB] py-[2%] px-[5%] rounded-lg border-2 border-[#2699FB] placeholder:text-[#2699fb] placeholder:font-bold text-[1.5rem] placeholder-bold"
              />
              <input
                type="tel"
                name="phone"
                placeholder="WhatsApp Phone"
                value={formData.phone}
                onChange={handleChange}
                className="text-[#2699FB] py-[2%] px-[5%] rounded-lg border-2 border-[#2699FB] placeholder:text-[#2699fb] placeholder:font-bold text-[1.5rem] placeholder-bold"
              />
            </div>
          </form>
        </div>
      </div>
      <div className="w-full flex justify-center items-center mt-20">
        <button className="cursor-pointer font-Montserrat text-[#2699FB] uppercase font-bold py-2 px-16 rounded-2xl bg-white border-2 border-[#2699FB] text-[2rem]" onClick={handleSubmit}>SUBMIT</button>
      </div>
    </section>
  );
};

export default AgentContact;
import React from "react";

const BuyerBrokerAgreement = () => {
    return (
        <section className="relative flex items-center justify-center h-[40vh] bg-cover bg-center bg-[rgba(0,59,92,0.8)]" style={{ backgroundImage: `url(https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/BioPage%2FImages%2FBLU_IMG_AUG_08-min.jpg?alt=media&token=7aec3105-5f52-4393-ad5c-5618b6283b96)` }}>
            <div className="absolute w-full h-full top-0 bg-[rgba(0,59,92,0.8)]"/>
            <div className="absolute flex flex-col items-center justify-center gap-4">
                <button className="cursor-pointer font-bold text-white text-[2rem] bg-[#2699fb] shadow-white py-10 px-14 rounded-2xl shadow-lg font-montserrat tracking-wider">Buyer Broker Agreement</button>
                <button className="mt-4 text-[1.5rem] font-montserrat font-medium text-white">Click Here</button>
            </div>
        </section>
    );
};

export default BuyerBrokerAgreement;
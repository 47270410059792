import { Data } from "../../data/Data";

export default function HeroSection() {
  const data = Data
  const HeroImage = data.maForm.HeroImage
  const WhiteLogo = data.maForm.WhiteLogo
  return (
    <div className="content HeroSection">
      <img src={HeroImage} className="content-img" alt="HeroImage" />
      <div className="HeroSection_Logo">
        <img src={WhiteLogo} alt="Blu" />
      </div>
    </div>
  );
}

import Footer from "../../blocks/Footer";
import Header from "../../blocks/Header";
import Quotes from "../../blocks/Quotes";
import AboutUsSection from "../../components/Home/AboutUsSection";
import CoreServices from "../../components/Home/CoreServices";
import HeroSection from "../../components/Home/HeroSection";
import ServicesSectionNew from "../../components/Home/ServicesSectionNew";
import StarContractSection from "../../components/Home/StarContractSection";

export default function Home() {
    return (
        <>
            <Header />
            <HeroSection />
            <ServicesSectionNew />
            <CoreServices />
            <StarContractSection />
            <AboutUsSection />
            <Quotes />
            <Footer />
        </>
    );
}
import React from 'react';

const Popup = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null;

    return (
        <div className="absolute w-full h-full bottom-0 inset-0 z-50 flex justify-center items-center" onClick={onClose}>
            <div className="absolute top-5 bg-white rounded-[125px] flex flex-col w-content shadow-2xl z-10" onClick={(e) => e.stopPropagation()}>
                {children}
            </div>
        </div>
    );
};

export default Popup;
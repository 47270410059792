import React, { useState } from "react";
import PrimarySection from "./BioSection/PrimarySection";
import AboutMeSection from "./BioSection/AboutMeSection";
import HighlightsSection from "./BioSection/HighlightsSection";

const BioSection = () => {
    const [activeSection, setActiveSection] = useState("primary");
    const [btnHighlightsText, setBtnHighlightsText] = useState("Highlights");
    const [btnAboutMeText, setBtnAboutMeText] = useState("About Me");

    const handleHighlightsClick = () => {
        if (btnHighlightsText === "Contact") {
            setActiveSection("primary");
            setBtnHighlightsText("Highlights");
            setBtnAboutMeText("About Me");
        } else {
            setActiveSection("highlights");
            setBtnHighlightsText("Contact");
        }
    };

    const handleAboutMeClick = () => {
        if (btnAboutMeText === "Contact") {
            setActiveSection("primary");
            setBtnHighlightsText("Highlights");
            setBtnAboutMeText("About Me");
        } else {
            setActiveSection("aboutMe");
            setBtnAboutMeText("Contact");
        }
    };

    return (
        <section className="relative font-montserrat h-screen mx-32 overflow-hidden">
            <h2 className="text-center text-[5rem] pt-10 font-dmSerifDisplay text-[#003b5c]"> I keep my clients updated</h2>
            <div className="flex items-center h-full">
                <div className="absolute mt-20 top-0 w-1/2 h-[50vh]">
                    <PrimarySection isActive={activeSection === "primary"} />
                    <AboutMeSection isActive={activeSection === "aboutMe"} />
                    <HighlightsSection isActive={activeSection === "highlights"} />
                    <div className="flex items-center gap-9 mt-10">
                        <button
                            className="border border-[#003b5c] bg-transparent text-[#003b5c] font-bold py-4 px-8 rounded-lg uppercase transition-all duration-300 hover:bg-[#003b5c] hover:text-white text-[1.5rem]"
                            onClick={handleHighlightsClick} >
                            {btnHighlightsText}
                        </button>
                        <button
                            className="border border-[#003b5c] bg-transparent text-[#003b5c] font-bold py-4 px-8 rounded-lg uppercase transition-all duration-300 hover:bg-[#003b5c] hover:text-white text-[1.5rem]"
                            onClick={handleAboutMeClick} >
                            {btnAboutMeText}
                        </button>
                    </div>
                </div>

                <div className="w-full">
                    <div
                        className="ml-[50%] bg-contain bg-no-repeat bottom-0 h-[100vh] w-auto"
                        style={{
                            backgroundImage:
                                "url(https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/BioPage%2FImages%2FJP_New_Pic.png?alt=media&token=c778056c-6449-45ff-b255-374d6c7bc5e4)",
                        }}
                    ></div>
                </div>
            </div>
        </section>
    );
};

export default BioSection;
import React from "react";

export default function StayInTouchSection() {

  const url = "https://console.catalyst.zoho.com/baas/v1/project/6905000000008864/folder/6905000000051074/file/16144000000033058/download?Environment=Production&catalyst_org=861843634"

  return (
    <div className="bg-cover bg-center h-[50vh] py-10 flex items-center justify-center relative font-montserrat" style={{ backgroundImage: `url('https://console.catalyst.zoho.com/baas/v1/project/6905000000008864/folder/6905000000051074/file/16144000000033058/download?Environment=Production&catalyst_org=861843634')` }}>
      <div className="absolute inset-0 h-[50vh] bg-[#003B5C] opacity-80"></div>
      <div className="relative z-10 text-center text-white flex flex-col justify-center items-center gap-4">
        <h2 className="text-[4rem] font-medium font-dmSerifDisplay">Stay in Touch</h2>
        <p className="mt-2 text-[1.5rem]">We are currently not accepting donations because we are in the process of organizing.<br />If you would like to stay informed, subscribe and follow us.</p>
        <button className="mt-4 px-12 py-4 max-w-[350px] text-[1.5rem] bg-[#2699fb] text-white font-bold rounded hover:bg-[#2699fb]">SUBSCRIBE</button>
      </div>
    </div>
  );
}
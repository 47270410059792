import Footer from "../../blocks/Footer";
import Header from "../../blocks/Header";
import Quotes from "../../blocks/Quotes";
import AgentContact from "../../components/Jp/AgentContact";
import BioSection from "../../components/Jp/BioSection";
import BioServicesSection from "../../components/Jp/BioServicesSection";
import BluContentSection from "../../components/Jp/BluContentSection";
import BuyerBrokerAgreement from "../../components/Jp/BuyerBrokerAgreement";
import HeroBioPage from "../../components/Jp/HeroBioPage";

export default function Jp() {
    return (
        <>
            <Header />
            <HeroBioPage />
            <BioSection />
            <BuyerBrokerAgreement />
            <BioServicesSection />
            <AgentContact />
            <BluContentSection />
            <Quotes />
            <Footer />
        </>
    );
}
const HighlightsSection = ({ isActive }) => (
  <div
    className={`w-full transition-opacity duration-500 ${isActive ? "opacity-100" : "opacity-0 hidden"
      }`}
  >
    <h2 className="text-[3rem] text-[#003b5c] w-fit font-light uppercase border-b-4 border-[#003b5c] pb-2">Highlights</h2>
    <div className="mt-8">
      <div className="flex flex-col space-y-6">

        <div className="flex items-center">
          <span
            className="w-6 h-6 bg-contain bg-no-repeat mr-4"
            style={{ backgroundImage: "url(https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/Icon%2FbioPageDiamond.svg?alt=media&token=b7c4887c-eea2-44e4-a3f2-1077c45858d8)", }}></span>
          <span className="text-[6rem] text-[#003b5c] font-dmSerifDisplay mr-4 font-bold">20</span>
          <span className="text-[2rem] text-[#003b5c] font-light">Years in <br /><h1 className="font-bold">Real Estate</h1></span>
        </div>

        <div className="flex items-center">
          <span
            className="w-6 h-6 bg-contain bg-no-repeat mr-4"
            style={{
              backgroundImage:
                "url(https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/Icon%2FbioPageDiamond.svg?alt=media&token=b7c4887c-eea2-44e4-a3f2-1077c45858d8)",
            }}
          ></span>
          <div className="flex flex-col">
            <span className="text-[4rem] text-[#003b5c] font-dmSerifDisplay mr-4 font-bold">
              Driven
            </span>
            <span className="text-[1.5rem] text-[#003b5c] font-light">
              In Supporting Affiliate Growth
            </span>
          </div>
        </div>

        <div className="flex items-center">
          <span
            className="w-6 h-6 bg-contain bg-no-repeat mr-4"
            style={{
              backgroundImage:
                "url(https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/Icon%2FbioPageDiamond.svg?alt=media&token=b7c4887c-eea2-44e4-a3f2-1077c45858d8)",
            }}
          ></span>
          <div className="flex flex-col">
            <span className="text-[4rem] text-[#003b5c] font-dmSerifDisplay mr-4 font-bold">Focused</span>
            <span className="text-[1.5rem] text-[#003b5c] font-light">On Delivering Value</span>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default HighlightsSection;
import React from "react";

const AboutUsSection = () => {
    return (
        <section className="font-montserrat flex justify-center items-center gap-40 flex-row w-full h-screen px-[10%]">

            <div className="text-left w-[50%] h-[50%] max-w-[700px] gap-4 flex flex-col justify-center">

                <h2 className="text-[3rem] text-[#49a9fc] font-bold mb-2">About Us</h2>

                <p className="text-[1.5rem] text-[#003b5c] w-full mb-6">
                    Through 20+ years of experience, we understood that the marriage
                    between brokerage and maintenance is a “happily ever after” for real
                    estate investment.
                </p>

                <p className="text-[1.5rem] text-[#003b5c] w-full mb-6">
                    We help our clients achieve their goals through reliable asset
                    management and reporting. We achieve our goals with honesty, fairness
                    and long-term partnerships.
                </p>

                <p className="text-[1.5rem] text-[#003b5c] w-full mb-6">
                    Maintenance services are exclusive to properties we list or manage.
                </p>

                <div className="flex space-x-6">

                    <button className="w-full w-max-[100px] bg-transparent border-4 border-[#49a9fc] text-[#49a9fc] text-[1.5rem] font-bold py-6 px-16 rounded-[25px] uppercase hover:bg-[#49a9fc] hover:text-white transition">
                        Our Story
                    </button>

                    <button className="w-full w-max-[100px] bg-transparent border-4 border-[#49a9fc] text-[#49a9fc] text-[1.5rem] font-bold py-6 px-16 rounded-[25px] uppercase hover:bg-[#49a9fc] hover:text-white transition">
                        Join Us
                    </button>
                </div>

            </div>

            <form className="bg-white rounded-3xl px-10 w-[50%] h-[50%] max-w-[800px] gap-10 flex flex-col justify-center shadow-2xl">
                <p className="text-[3rem] font-bold text-[#003b5c] text-center mb-6">
                    Let's Grow Together
                </p>
                <div className="flex flex-col space-y-4 gap-8">
                    <input
                        type="text"
                        placeholder="John Doe"
                        className="w-full px-6 py-4 text-[#49a9fc] placeholder-[#49a9fc] border-2 border-blue-200 rounded-[20px] focus:outline-none focus:border-[#49a9fc] text-[1.5rem]"
                    />
                    <input
                        type="email"
                        placeholder="johndoe@mail.com"
                        className="w-full px-6 py-4 text-[#49a9fc] placeholder-[#49a9fc] border-2 border-blue-200 rounded-[20px] focus:outline-none focus:border-[#49a9fc] text-[1.5rem]"
                    />
                    <input
                        type="tel"
                        placeholder="+1"
                        className="w-full px-6 py-4 text-[#49a9fc] placeholder-[#49a9fc] border-2 border-blue-200 rounded-[20px] focus:outline-none focus:border-[#49a9fc] text-[1.5rem]"
                    />
                    <input
                        type="submit"
                        value="Submit"
                        className="w-full bg-[#49a9fc] text-white font-bold text-[1.5rem] py-4 rounded-[20px] cursor-pointer hover:bg-blue-500 transition"
                    />
                </div>
            </form>

        </section>
    );
};

export default AboutUsSection;
import Header from "../../blocks/Header";
import MASection from "../../components/MAForm/MAForm";

const MAForm = () => {

  return (
    <>
      <Header />
      <MASection />
    </>
  );
};

export default MAForm;
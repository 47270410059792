import React, { useState, useEffect, useRef, useCallback } from "react";
import "./MAForm.css";
import HeroSection from "./HeroSection";
import WelcomeSection from "./WelcomeSection";
import HandSection from "./HandSection";
import LetterOwnerGranting from "./LetterOwnerGranting";
import FooterMAForm from "./FooterMAForm";
import Form from "./Form";
import axios from "axios";
import jsPDF from "jspdf";
import { CSSTransition } from "react-transition-group";
import { Data } from "../../data/Data";

const MASection = () => {
  const data = Data
  const PrintIcon = data.maForm.PrintIcon
  const SendIcon = data.maForm.SendIcon
  const DownloadIcon = data.maForm.DownloadIcon

  const [formData1, setFormData1] = useState({});
  const [formData2, setFormData2] = useState({});
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isFormModified, setIsFormModified] = useState(false);
  const formRef = useRef(null);
  const popupRef = useRef(null);

  const handlePrint = useCallback(() => {
    if (formRef.current) {
      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "a4",
      });

      let y = 10; // Inicialización de la coordenada y
      const addSection = (text, size = 12, bold = false) => {
        if (y > 270) {
          // Asegura que no se escriba fuera del espacio de la página.
          pdf.addPage();
          y = 10;
        }
        pdf.setFontSize(size);
        pdf.setFont(undefined, "normal");
        const splitText = pdf.splitTextToSize(text, 180); // Ajusta el texto a la anchura de la página.
        splitText.forEach((line) => {
          pdf.text(line, 15, y);
          y += 6; // Incremento para la siguiente línea.
        });
        y += 10; // Espacio extra después de un párrafo.
      };
      addSection(
        `Management Agreement\nDear ${formData1.MA_Owner_Record || '("Owner")'
        },\n\nWe are delighted that you have chosen us to be part of your team. As our relationship grows, we are confident that you will see why Blu Realty & Investments is the right choice. Our cutting-edge technology will allow you to experience the most efficient and reliable service in the industry.\n\nOur goal is to maintain your property performing in its best condition, appreciating in its value and giving you its highest returns. We thank you for the opportunity and look forward to earning your business and trust.\n\nSincerely!`,
        12,
        true
      );
      addSection(
        `MANAGEMENT AGREEMENT ("Agreement") is between ${formData1.MA_Owner_Record || '("Owner")'
        } and Blu Realty & Investments, LLC ("Manager")`,
        12,
        true
      );
      addSection(
        `1. Owner gives Manager the EXCLUSIVE RIGHT TO BE BROKER in the sale, lease and management of the real and personal property (collectively "Property") described below beginning the ${formData1.MA_Start_Date || "Start Date"
        } and terminating at 11:59 pm on ${formData1.MA_End_Date || "End Date"
        }.`,
        12
      );
      addSection(
        "Upon full execution of a contract for sale and purchase of the Property, all rights and obligations of this Agreement will automatically extend through the date of the actual closing of the sales contract. This Agreement shall be cancelable after one year, with or without cause, by either party with one hundred and eighty (180) days written notice. Owner certifies and represents that he/she has the legal authority and capacity to lease the Property and improvements.",
        12
      );

      addSection(
        `Description of Property\n(A) Street Address: ${formData1.Street_Address2 || "-"
        }\nCity: ${formData1.City1 || "-"}\nState: ${formData1.State1 || "-"
        }\nZipcode: ${formData1.Zipcode || "-"}\n(B) Legal Description: ${formData1.MA_Legal_Description || "-"
        }\n(C) Personal Property:\n  Refrigerator: ${formData1.Zipcode || "-"
        }\n  Stove: ${formData1.Stove || "-"}\n  Microwave: ${formData1.Microwave || "-"
        }\n  Dishwasher: ${formData1.Dishwasher || "-"}\n  Dryer: ${formData1.Dryer || "-"
        }\n  Washer: ${formData1.Washer || "-"}\n  Garbage Disposal: ${formData1.Garbage_Disposal || "-"
        }\n  Garage Opener: ${formData1.Garage_Opener || "-"
        }\n(D) Owner provides the following Keys to the Property:\n  Unit    Building Access    Mailbox\n  Pool    Garage     Door Opener\n(E) Type of Property: ${formData1.TypeOfProperty || "-"
        }\n(F) Occupancy: ${formData1.MA_Property_Occupancy || "-"
        }\nPlease provide a copy of the latest lease agreement(s) and tenant(s) contact information.\nTenant Name: ${formData1.Tenant_Name || "-"
        }\nTenant Phone: ${formData1.Tenant_Phone || "-"}\nTenant Email: ${formData1.Tenant_Email || "-"
        }`,
        12
      );
      addSection(
        `3. PRICE AND TERMS: The property is offered for sale and/or lease on the following terms or on other terms acceptable to Seller:\n(a) Price: ${formData1.Price || "-"
        }\nLease: ${formData1.Lease || "-"}\n(b) Financing Terms: ${formData1.Financing_Terms || "-"
        }\n(c) Owner Expenses: Owner will pay mortgage discount or other closing costs not to exceed 3% of the purchase price and any other expenses Owner agrees to pay in connection with a transaction.`,
        12
      );
      addSection(
        "4. MANAGER OBLIGATIONS AND AUTHORITY: Manager will use due diligence to manage and operate Property in accordance with this Agreement.",
        12,
        true
      );
      addSection(
        "(A) Owner authorizes Manager to:\n(i) Advertise the Property as Manager deems advisable including advertising the Property on the Internet.\n(ii) Complete and sign the lead-based paint/hazards certification on Owner’s behalf (for Properties built before 1978).\n(iii) Manage tenant relations, including negotiating renewals of existing leases; collecting, holding and disbursing rents and other amounts due or to become due to Owner; enter into a lease/contract to lease on Owner’s behalf (Owner must execute special power of Attorney); handling tenant requests and negotiations; terminating tenancies and signing and serving appropriate notices on behalf of Owner; initiating and prosecuting eviction and damages action on behalf of Owner; and procuring legal counsel when necessary to protect Owner’s interests and rights in connection with the Property.",
        12
      );
      addSection(
        "(4) All collected late fees will be split 50% / 50% between Owner and Manager. All rents are due on the 1st of the month; tenants have until the 5th of the month to pay rent. Owner can expect to receive rental proceeds by the 25th to the end of the month to allow time for collection and repair invoices to be processed and paid. In the event that a tenant does not pay rent by the 8th of the month, the collection process will commence immediately. Tenants that have not paid rent by the 12th will receive a 3-day notice.",
        12
      );
      addSection(
        "(5) During vacancy, Manager must 1. Bi-weekly evaluation for the property 2. Maintain/pay utility services 3. Maintain key in lockbox 4. Track expenses and provide an accurate income/expense report. Abated monthly management fee, for vacant period, to 5% (of last rental amount).",
        12
      );
      addSection(
        "(B) Maintain accurate records of receipts, expenses and accruals to Owner in connection with managing the Property. Manager will render to Owner itemized financial statement at the end of the fiscal year and will promptly remit to Owner the balance of receipts less disbursements and accruals for future expenses. All fInancial statements will be posted online via the Owner portal. The primary email address provided in Section 12 (below) will be used as your portal login username.",
        12
      );
      addSection(
        "Upon execution of this management agreement, Owner will receive an email granting access to the owner online portal.",
        12
      );
      addSection(
        "(C) Property Maintenance: Owner understands that Florida law requires licensed professionals in the construction trades to perform relevant repairs on the rental properties unless the repairs can be made for under $1,000.00 and are not of a life/safety concern. Additionally, Owner understands that when Manager acts as the Owner’s agent, Florida law provides the Manager may contract for repairs, maintenance, remodeling or improvements of the Property with a certified or registered contractor when labor and materials together do no exceed $5,000.00. Subject to these limitations set by law, Owner authorizes Manager to:",
        12
      );
      addSection(
        "(i) Enter into contracts on Owner’s behalf for utilities, public services, maintenance, repairs and other services as Manager deems advisable.\n(ii) Provide repair services from Manager’s in-house technicians and Manager’s partnered contractors. Manager will not solicit bids from non-partnered contractors on behalf of Owner nor will Manager be responsible for any damages or liability arising from negligence of any contractor. Manager supervises all work and repairs performed by the Manager’s in-house technicians at no additional cost. Work supervision performed by non-partnered and Manager’s partnered contractors, incur the hourly rate set fort in Section #8 below.\n(iii) Maintain and repair interior, exterior and landscaping of Property (if applicable), including making periodic evaluations; purchasing supplies; and supervising alterations, modernization and redecoration of Property. Manager will obtain prior approval of Owner for any item or each service in excess of $5,000.00, except for monthly or recurring expenses and emergency repairs which in Manager’s opinion are necessary to prevent the Property from becoming uninhabitable or damaged, to avoid suspension of services required to be provided by law or lease, or to avoid penalties or fines to be imposed by governmental entity. Service request type and Manager’s response time are detailed in the chart below:",
        12
      );
      addSection(
        "(D) Manager to perform yearly evaluation prior to summer months. HVAC system is inspected to assure system is prepared for the summer months when it is used the most. A detailed report is presented to Owner detailing the condition of the HVAC system as well as other inspected areas of the Property. Additional services are estimated in the “Preventative Care Services” section below and billed separately at the time of service. Owner approves that Manager will: 1. Perform HVAC drain-line cleaning services and 2. Change HVAC filter if necessary during this evaluation period. HVAC preparation services are not performed and billed if Owner has a yearly HVAC maintenance contract with a licensed professional, Owner to provided said maintenance contract information in Section 12, or if a move-in occurred in March or April. Furthermore, Owner authorizes Manager to perform general maintenance repairs up to $1,000.00 during this evaluation. Repairs above that dollar amount will require Owner’s approval. Repairs deemed tenants’ responsibility may be collected during the lease term or deducted from security deposit after tenant has moved-out. This service is performed per unit and billed accordingly.",
        12
      );
      addSection(
        "(E) If the homeowners association does not service Rodent Bait Stations, Manager will purchase, install and service Rodent Bait Stations bimonthly.",
        12
      );
      addSection(
        "(F) Forward Owner rents proceeds: Manager will forward all payments to Owner via: Direct Bank Wire/Transfer (wired information must provided in Section below.",
        12
      );
      addSection(
        "(G) Florida Tenancy laws, F.S. Chap 83.49, requires tenant’s security deposit to be held in a separate Florida banking institution for the benefit of the tenant or a surety bond must be posted.Should Owner decide to hold tenant’s security deposit, Owner hereby indemnifies, holdsharmless, releases Manager from any and all liability arising from tenant’s security deposit and/or damaged. Manager is not an attorney at law and Owner should consult legal consul for interpretation of the law.",
        12
      );
      addSection(
        "(H) In emergency circumstances and in order to maintain reliable and trust wording relationships with local vendors or service providers, Manager must provide venders or service providers prompt payment. To accomplish this, Manager must maintain $3,000.00 {LSDToken] of Owner’s proceeds in escrow during the life of this agreement. At termination of this agreement, Manager will return any balance in escrow.",
        12
      );
      addSection(
        "(I) During vacancy of the property, Manager will advertise the Property as Broker deems advisable including advertising the Property on the Internet",
        12
      );
      addSection(
        "(J) During the 1st year Manager will present a proposed monthly and year budget. Sequential years, Manager will present proposed vs. actual expense budget and advised Owner to withholding budgeted amount. Budgeting prevents the inability to resolve unexpected major repairs.",
        12
      );
      addSection(
        "OWNER OBLIGATIONS: In consideration of the obligations of Manager, Owner agrees:",
        12
      );
      addSection(
        "(A) To cooperate with Manager in carrying out the purpose of this Agreement.",
        12
      );
      addSection(
        "(B) To deliver all expenses incurred that are directly paid by Owner. Manager will not be able to provide adequate financial reports if Owner fails to provide expenses.",
        12
      );
      addSection(
        "(C) To provide complete and accurate information to Manager including disclosing all known facts that materially affect the value of the Property. If the Property was built in 1977 or earlier, Owner will provide Manager with all information Owner knows about lead-based paint and leadbased paint hazards in the Property and with all available documents pertaining to such paint and hazards, as required by federal law. Owner understands that the law requires the provision of this information to Manager and to prospective tenants before the tenants become obligated to lease the Property. Owner acknowledges that Manager will rely on Owner’s representations regarding the Property with dealing with prospective tenants.",
        12
      );
      addSection(
        "(D) To carry, at Owner’s sole expense, liability and property damage insurance adequate to protect the interes of Owner and Manager. Said insurance will name both Manager and Owner as insured parties, and shall indemnify, protect, defend and hold harmless provision of subparagraph 5(h). Manager will not be liable for any error of judgment or mistake of law or fact or for any loss caused by Manager’s negligence, except when the loss is caused by Manager’s willful misconduct. This obligation will survive the termination of the agreement. Owner will carry insurance as follows:",
        12
      );
      addSection(
        "(i) Perils of fire, lightning wind, hail, explosion, smoke, riot, aircraft, vehicles, vandalism, and burglary on the contents of the Property in the amount of $500,000.00 or higher.",
        12
      );
      addSection(
        "(ii) “At risk” protection on the buildings in the amount of current total replacement cost, and on rental income in the amount of current lease agreement.",
        12
      );
      addSection(
        "(iii) Liability for personal injury and property damage in the amount of $500,000.00 minimum in the event of any litigation, arbitration or dispute involving an alleged violation by Manager or Owner, a prior Owner or a prior Manager, or any of them together or separately, of any law pertaining to fair employment, fair credit reporting, environmental protection, rent control, taxes, wrongful eviction, habitability of premises, fair housing, or discrimination of any kind, including, but not limited to, any law prohibiting or making illegal arbitrary discrimination on the basis of race, sex, creed, color, family status religion, national origin, or mental or physical handicap. Owner shall pay all expenses incurred by Manager, including, but not limited to, reasonable attorneys’ fees and Manager’s costs and time, and any liability, fines, penalties or the like. Provided, however, that Owner shall not be responsible to Manager for any such expenses in the event Manager is finally adjudged to have violated any such law and acted personally or beyond the scope of the agency rela-tionship intended herein, and not in a representative or agency capacity.",
        12
      );
      addSection(
        "(E) To inform Manager before conveying or leasing the Property.",
        12
      );
      addSection(
        "(F) Upon termination of this Agreement, to assume obligations of all contracts that Manager entered into on Owner’s behalf.",
        12
      );
      addSection(
        "(G) To pay all amounts billed by Manager. Owner authorizes Manager to reimburse itself out of rents collected for any outstanding invoices",
        12
      );
      addSection(
        "(H) To indemnify and hold harmless Manager and Manager’s officers, directors, agents and employees from all claims, demands, causes of action costs and expenses, including reasonable attorneys’ fees at all levels arising from liability to any person, personal injuries, property damages and interference with tenancies, accountants’ fees to the extent based on (i) Owner’s misstatement, negligence, action, inaction or failure to perform the obligations of this contract or any lease or agreement with a vendor; (ii) the existence of undisclosed material facts about the Property; (ii) Manager’s performance, at Owner’s request, of any task beyond the scope of services regulated by Chapter 475, F.S., as amended, including Manager’s referral, recommendation of any vendor; or (4) services or products provided and expenses incurred by any vendor. This subparagraph will survive Manager’s performance and the transfer or title. (iii) To authorize or make repairs necessary to transfer a reasonable safe dwelling unit to the tenant.",
        12
      );
      addSection(
        "(J) To exercise reasonable care to repair dangerous defective conditions upon notice of their existence.",
        12
      );
      addSection(
        "(K) To update Manager of changes to any governing bodies’ mailing address and/or owner of record. Owner hereby verifies that the mailing address, owner of record, and any financial obligation are current. All notices and fines imposed by governing bodies are deliver via U.S. Mail to the mailing address listed in their records. Manager is not be responsible for any monetary fines imposed by any governing bodies.",
        12
      );
      addSection(
        "(L) Homeowner’s association online access must be provided in Section 12 (below). In order for Manager to access current ledger information, notices or others, Owner must include all logins and passwords of homeowner’s association management website.",
        12
      );
      addSection(
        "(M) If Owner has retained a service provider or warranty company, Owner must fill in the contact information for said provider in Section 12 (below). Additionally, Owner must name Manager as an authorized representative.",
        12
      );
      addSection(
        "(N) Owner is not to communicate directly with tenants. If the Owner receives direct communication from tenants, Owner must relay information to Manager.",
        12
      );
      addSection(
        "(O) Upon vacancy of the property Owner must maintain and have active electric and water utilities services. When requested, and at Manager’s discretion, Manager can connect services for property utilizing Manager’s accounts and with Manager’s credit wordiness a processing fee will be added.",
        12
      );
      addSection(
        "(P) Provide Tax ID number. U.S. Tax Law requires Property managers to issue a 1099-MISC tax form for any property Owner who receives more than $600 related to their rental business.",
        12
      );
      addSection(
        "COMPENSATION:\nOwner agrees to compensate Manager and Other Brokers:\n(A) Owner will compensate Manager 6.0% of the total purchase price no later than the date of closing specified in the sales contract. However, closing is not a prerequisite for Manager’s fee being earned. If an option is exercised, Owner will pay Manager the same fee (as stated above) for procuring a buyer who is ready, willing, and able to purchase the Property or any interest in the Property on the terms of this Agreement or on any other terms acceptable to Owner.",
        12
      );
      addSection(
        "(B) Notice to Owner: The buyer’s broker, even if compensated by Owner or Manager, may represent the interests of the buyer. Manager’s office policy is to cooperate with all other brokers except when not in Owner’s best interest and to offer compensation in the amount of 3.0% of the purchase price to a single agent, transaction broker or a broker who has no brokerage relationship with the buyer.",
        12
      );
      addSection(
        "(C) New Leases and Renewals: one month of lease value as a leasing fee, if Owner enters into any new lease, on the date Owner enters into a lease or agreement to lease, whichever is earlier. Renewal is 1/2 of a month of lease value of the original lease with a tenant placed in the Property by or through Manager.",
        12
      );
      addSection(
        "(D) For managing tenant relations and the Property: 10% of gross lease value to be paid on a monthly basis.",
        12
      );
      addSection(
        "(E) Manager’s fee is due in the following circumstances: (1) If any interest in the Property is transferred, whether by sale, lease, exchange, governmental action, bankruptcy, or any other means of transfer, with the assistance of any real estate licensee. (2) If Owner refuses or fails to sign an offer at the price and terms stated in this Agreement, defaults on an executed sales contract, or agrees with a buyer to cancel an executed sales contract. (3) If, within 180 days after Termination Date (“Protection Period”), Owner transfers or contracts to transfer the Property or any interest in the Property to any prospects with whom Manager or any other real estate licensee communicated regarding the Property before Termination Date. However, no fee will be due to Manager if the Property is relisted after Termination Date and sold through another broker.",
        12
      );
      addSection(
        "(F) Retained Deposits: As consideration for Broker’s services, Broker is entitled to receive 50% of all deposits that Owner retains as liquidated damages for a buyer’s default in a transaction, not to exceed the Paragraph 6(a) fee.",
        12
      );
      addSection(
        "SERVICES EXCLUDED: This Agreement does not include providing tax, accounting, or legal advice. Owner shall be solely responsible for assuring compliance of the premises with applicable laws and B compliance with tax reporting requirements. This Agreement does not include on-site management services, refinancing, preparation of the premises for refinancing, modernization, fire or major damage restoration, rehabilitation, representation or appearance before public agencies or in legal proceedings, advising on proposed new construction, debt collection, consultation, attendance at association meetings or public hearings, except to the extent expressly set forth above or otherwise agreed in writing. Manager shall in any event be entitled to payment by Owner of Special Services Compensation for any such service if provided at the rate of $88.00 per hour.",
        12
      );
      addSection(
        "DISPUTE RESOLUTIONS: This Agreement will be construed under Florida law. All disputes between Manager and Owner based on this Agreement or its breach will be mediated under the rules of the American Arbitration Association or another mediator agreed upon by the parties. Mediation is a process in which parties attempt to resolve a dispute by submitting it to an impartial mediator who facilitates the resolution of the dispute but who is not empowered to impose a settlement on the parties. The parties will equally divide the mediation fee, if any. In any litigation based on this Agreement, the prevailing party will be entitled to recover reasonable attorneys’ fees and costs at all levels, unless the parties agree that disputes will be settled by arbitration as follows: Arbitration - Owner and Manager agree that disputes not resolved by mediation will be settled by neutral binding arbitration in the county in which the Property is located in accordance with the rules of the American Arbitration Association or other arbitrator agreed upon by the parties. The arbitrator may not alter the Contract terms. Each party to any arbitration or litigation (including appeals and interpleaders) will pay its own fees, costs and expenses, including attorneys’ fees at all levels, and will equally split the arbitra-tors’ fees and administrative fees of arbitration.",
        12
      );
      addSection(
        "ATTORNEYS’ FEES: In any action between Owner and a tenant in which Manager is made a party because of acting as an escrow agent under this Agreement, or if Manager interpleads escrowed funds, Manager will recover reasonable attorneys’ fees and costs, to be paid out of the escrowed funds and charged and awarded as court costs in favor of the prevailing party.",
        12
      );
      addSection(
        "MISCELLANEOUS: This Agreement is binding on Manager’s and Owner’s heirs, personal representatives, administrators, successors and assigns. Signatures, initials, documents referenced in the Agreement, counterparts and modifications communicated electronically or on paper will be acceptable for all purposes and will be binding. Major work, were defined or implied, are services of a value of $1,000.00 or higher.",
        12
      );
      addSection(
        "ADDITIONAL CLAUSES: Either party must notify the other, in writing, upon the termination date of this agreement the desire to terminate. If no notice is received, the terms of this agreement should continue and this agreement will auto-renew for an equal term as the term stated in this agreement. In addition to conventional written communication, Manager shall be entitled to rely on approvals or instructions from Owner which are received by or confirmed in email transmissions, text messages or other electronic forms of communication established by the custom and practice of the parties.",
        12
      );

      // Convert the PDF into a blob and display it in a pop-up for printing
      const blob = pdf.output("blob");
      const url = URL.createObjectURL(blob);
      const printWindow = window.open(url);
      printWindow.onload = () => {
        printWindow.focus(); // Necessary to focus on the new window so the print dialog works correctly
        printWindow.print();
      };
    } else {
      console.error("The form reference is not attached to a DOM element.");
    }
  }, [formData1, formData2]);

  const handleDownload = useCallback(() => {
    if (formRef.current) {
      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "a4",
      });

      let y = 10; // Inicialización de la coordenada y
      const addSection = (text, size = 12, bold = false) => {
        if (y > 270) {
          // Asegura que no se escriba fuera del espacio de la página.
          pdf.addPage();
          y = 10;
        }
        pdf.setFontSize(size);
        pdf.setFont(undefined, "normal");
        const splitText = pdf.splitTextToSize(text, 180); // Ajusta el texto a la anchura de la página.
        splitText.forEach((line) => {
          pdf.text(line, 15, y);
          y += 6; // Incremento para la siguiente línea.
        });
        y += 10; // Espacio extra después de un párrafo.
      };
      addSection(
        `Management Agreement\nDear ${formData1.MA_Owner_Record || '("Owner")'
        },\n\nWe are delighted that you have chosen us to be part of your team. As our relationship grows, we are confident that you will see why Blu Realty & Investments is the right choice. Our cutting-edge technology will allow you to experience the most efficient and reliable service in the industry.\n\nOur goal is to maintain your property performing in its best condition, appreciating in its value and giving you its highest returns. We thank you for the opportunity and look forward to earning your business and trust.\n\nSincerely!`,
        12,
        true
      );
      addSection(
        `MANAGEMENT AGREEMENT ("Agreement") is between ${formData1.MA_Owner_Record || '("Owner")'
        } and Blu Realty & Investments, LLC ("Manager")`,
        12,
        true
      );
      addSection(
        `1. Owner gives Manager the EXCLUSIVE RIGHT TO BE BROKER in the sale, lease and management of the real and personal property (collectively "Property") described below beginning the ${formData1.MA_Start_Date || "Start Date"
        } and terminating at 11:59 pm on ${formData1.MA_End_Date || "End Date"
        }.`,
        12
      );
      addSection(
        "Upon full execution of a contract for sale and purchase of the Property, all rights and obligations of this Agreement will automatically extend through the date of the actual closing of the sales contract. This Agreement shall be cancelable after one year, with or without cause, by either party with one hundred and eighty (180) days written notice. Owner certifies and represents that he/she has the legal authority and capacity to lease the Property and improvements.",
        12
      );

      addSection(
        `Description of Property\n(A) Street Address: ${formData1.Street_Address2 || "-"
        }\nCity: ${formData1.City1 || "-"}\nState: ${formData1.State1 || "-"
        }\nZipcode: ${formData1.Zipcode || "-"}\n(B) Legal Description: ${formData1.MA_Legal_Description || "-"
        }\n(C) Personal Property:\n  Refrigerator: ${formData1.Zipcode || "-"
        }\n  Stove: ${formData1.Stove || "-"}\n  Microwave: ${formData1.Microwave || "-"
        }\n  Dishwasher: ${formData1.Dishwasher || "-"}\n  Dryer: ${formData1.Dryer || "-"
        }\n  Washer: ${formData1.Washer || "-"}\n  Garbage Disposal: ${formData1.Garbage_Disposal || "-"
        }\n  Garage Opener: ${formData1.Garage_Opener || "-"
        }\n(D) Owner provides the following Keys to the Property:\n  Unit    Building Access    Mailbox\n  Pool    Garage     Door Opener\n(E) Type of Property: ${formData1.TypeOfProperty || "-"
        }\n(F) Occupancy: ${formData1.MA_Property_Occupancy || "-"
        }\nPlease provide a copy of the latest lease agreement(s) and tenant(s) contact information.\nTenant Name: ${formData1.Tenant_Name || "-"
        }\nTenant Phone: ${formData1.Tenant_Phone || "-"}\nTenant Email: ${formData1.Tenant_Email || "-"
        }`,
        12
      );
      addSection(
        `3. PRICE AND TERMS: The property is offered for sale and/or lease on the following terms or on other terms acceptable to Seller:\n(a) Price: ${formData1.Price || "-"
        }\nLease: ${formData1.Lease || "-"}\n(b) Financing Terms: ${formData1.Financing_Terms || "-"
        }\n(c) Owner Expenses: Owner will pay mortgage discount or other closing costs not to exceed 3% of the purchase price and any other expenses Owner agrees to pay in connection with a transaction.`,
        12
      );
      addSection(
        "4. MANAGER OBLIGATIONS AND AUTHORITY: Manager will use due diligence to manage and operate Property in accordance with this Agreement.",
        12,
        true
      );
      addSection(
        "(A) Owner authorizes Manager to:\n(i) Advertise the Property as Manager deems advisable including advertising the Property on the Internet.\n(ii) Complete and sign the lead-based paint/hazards certification on Owner’s behalf (for Properties built before 1978).\n(iii) Manage tenant relations, including negotiating renewals of existing leases; collecting, holding and disbursing rents and other amounts due or to become due to Owner; enter into a lease/contract to lease on Owner’s behalf (Owner must execute special power of Attorney); handling tenant requests and negotiations; terminating tenancies and signing and serving appropriate notices on behalf of Owner; initiating and prosecuting eviction and damages action on behalf of Owner; and procuring legal counsel when necessary to protect Owner’s interests and rights in connection with the Property.",
        12
      );
      addSection(
        "(4) All collected late fees will be split 50% / 50% between Owner and Manager. All rents are due on the 1st of the month; tenants have until the 5th of the month to pay rent. Owner can expect to receive rental proceeds by the 25th to the end of the month to allow time for collection and repair invoices to be processed and paid. In the event that a tenant does not pay rent by the 8th of the month, the collection process will commence immediately. Tenants that have not paid rent by the 12th will receive a 3-day notice.",
        12
      );
      addSection(
        "(5) During vacancy, Manager must 1. Bi-weekly evaluation for the property 2. Maintain/pay utility services 3. Maintain key in lockbox 4. Track expenses and provide an accurate income/expense report. Abated monthly management fee, for vacant period, to 5% (of last rental amount).",
        12
      );
      addSection(
        "(B) Maintain accurate records of receipts, expenses and accruals to Owner in connection with managing the Property. Manager will render to Owner itemized financial statement at the end of the fiscal year and will promptly remit to Owner the balance of receipts less disbursements and accruals for future expenses. All fInancial statements will be posted online via the Owner portal. The primary email address provided in Section 12 (below) will be used as your portal login username.",
        12
      );
      addSection(
        "Upon execution of this management agreement, Owner will receive an email granting access to the owner online portal.",
        12
      );
      addSection(
        "(C) Property Maintenance: Owner understands that Florida law requires licensed professionals in the construction trades to perform relevant repairs on the rental properties unless the repairs can be made for under $1,000.00 and are not of a life/safety concern. Additionally, Owner understands that when Manager acts as the Owner’s agent, Florida law provides the Manager may contract for repairs, maintenance, remodeling or improvements of the Property with a certified or registered contractor when labor and materials together do no exceed $5,000.00. Subject to these limitations set by law, Owner authorizes Manager to:",
        12
      );
      addSection(
        "(i) Enter into contracts on Owner’s behalf for utilities, public services, maintenance, repairs and other services as Manager deems advisable.\n(ii) Provide repair services from Manager’s in-house technicians and Manager’s partnered contractors. Manager will not solicit bids from non-partnered contractors on behalf of Owner nor will Manager be responsible for any damages or liability arising from negligence of any contractor. Manager supervises all work and repairs performed by the Manager’s in-house technicians at no additional cost. Work supervision performed by non-partnered and Manager’s partnered contractors, incur the hourly rate set fort in Section #8 below.\n(iii) Maintain and repair interior, exterior and landscaping of Property (if applicable), including making periodic evaluations; purchasing supplies; and supervising alterations, modernization and redecoration of Property. Manager will obtain prior approval of Owner for any item or each service in excess of $5,000.00, except for monthly or recurring expenses and emergency repairs which in Manager’s opinion are necessary to prevent the Property from becoming uninhabitable or damaged, to avoid suspension of services required to be provided by law or lease, or to avoid penalties or fines to be imposed by governmental entity. Service request type and Manager’s response time are detailed in the chart below:",
        12
      );
      addSection(
        "(D) Manager to perform yearly evaluation prior to summer months. HVAC system is inspected to assure system is prepared for the summer months when it is used the most. A detailed report is presented to Owner detailing the condition of the HVAC system as well as other inspected areas of the Property. Additional services are estimated in the “Preventative Care Services” section below and billed separately at the time of service. Owner approves that Manager will: 1. Perform HVAC drain-line cleaning services and 2. Change HVAC filter if necessary during this evaluation period. HVAC preparation services are not performed and billed if Owner has a yearly HVAC maintenance contract with a licensed professional, Owner to provided said maintenance contract information in Section 12, or if a move-in occurred in March or April. Furthermore, Owner authorizes Manager to perform general maintenance repairs up to $1,000.00 during this evaluation. Repairs above that dollar amount will require Owner’s approval. Repairs deemed tenants’ responsibility may be collected during the lease term or deducted from security deposit after tenant has moved-out. This service is performed per unit and billed accordingly.",
        12
      );
      addSection(
        "(E) If the homeowners association does not service Rodent Bait Stations, Manager will purchase, install and service Rodent Bait Stations bimonthly.",
        12
      );
      addSection(
        "(F) Forward Owner rents proceeds: Manager will forward all payments to Owner via: Direct Bank Wire/Transfer (wired information must provided in Section below.",
        12
      );
      addSection(
        "(G) Florida Tenancy laws, F.S. Chap 83.49, requires tenant’s security deposit to be held in a separate Florida banking institution for the benefit of the tenant or a surety bond must be posted.Should Owner decide to hold tenant’s security deposit, Owner hereby indemnifies, holdsharmless, releases Manager from any and all liability arising from tenant’s security deposit and/or damaged. Manager is not an attorney at law and Owner should consult legal consul for interpretation of the law.",
        12
      );
      addSection(
        "(H) In emergency circumstances and in order to maintain reliable and trust wording relationships with local vendors or service providers, Manager must provide venders or service providers prompt payment. To accomplish this, Manager must maintain $3,000.00 {LSDToken] of Owner’s proceeds in escrow during the life of this agreement. At termination of this agreement, Manager will return any balance in escrow.",
        12
      );
      addSection(
        "(I) During vacancy of the property, Manager will advertise the Property as Broker deems advisable including advertising the Property on the Internet",
        12
      );
      addSection(
        "(J) During the 1st year Manager will present a proposed monthly and year budget. Sequential years, Manager will present proposed vs. actual expense budget and advised Owner to withholding budgeted amount. Budgeting prevents the inability to resolve unexpected major repairs.",
        12
      );
      addSection(
        "OWNER OBLIGATIONS: In consideration of the obligations of Manager, Owner agrees:",
        12
      );
      addSection(
        "(A) To cooperate with Manager in carrying out the purpose of this Agreement.",
        12
      );
      addSection(
        "(B) To deliver all expenses incurred that are directly paid by Owner. Manager will not be able to provide adequate financial reports if Owner fails to provide expenses.",
        12
      );
      addSection(
        "(C) To provide complete and accurate information to Manager including disclosing all known facts that materially affect the value of the Property. If the Property was built in 1977 or earlier, Owner will provide Manager with all information Owner knows about lead-based paint and leadbased paint hazards in the Property and with all available documents pertaining to such paint and hazards, as required by federal law. Owner understands that the law requires the provision of this information to Manager and to prospective tenants before the tenants become obligated to lease the Property. Owner acknowledges that Manager will rely on Owner’s representations regarding the Property with dealing with prospective tenants.",
        12
      );
      addSection(
        "(D) To carry, at Owner’s sole expense, liability and property damage insurance adequate to protect the interes of Owner and Manager. Said insurance will name both Manager and Owner as insured parties, and shall indemnify, protect, defend and hold harmless provision of subparagraph 5(h). Manager will not be liable for any error of judgment or mistake of law or fact or for any loss caused by Manager’s negligence, except when the loss is caused by Manager’s willful misconduct. This obligation will survive the termination of the agreement. Owner will carry insurance as follows:",
        12
      );
      addSection(
        "(i) Perils of fire, lightning wind, hail, explosion, smoke, riot, aircraft, vehicles, vandalism, and burglary on the contents of the Property in the amount of $500,000.00 or higher.",
        12
      );
      addSection(
        "(ii) “At risk” protection on the buildings in the amount of current total replacement cost, and on rental income in the amount of current lease agreement.",
        12
      );
      addSection(
        "(iii) Liability for personal injury and property damage in the amount of $500,000.00 minimum in the event of any litigation, arbitration or dispute involving an alleged violation by Manager or Owner, a prior Owner or a prior Manager, or any of them together or separately, of any law pertaining to fair employment, fair credit reporting, environmental protection, rent control, taxes, wrongful eviction, habitability of premises, fair housing, or discrimination of any kind, including, but not limited to, any law prohibiting or making illegal arbitrary discrimination on the basis of race, sex, creed, color, family status religion, national origin, or mental or physical handicap. Owner shall pay all expenses incurred by Manager, including, but not limited to, reasonable attorneys’ fees and Manager’s costs and time, and any liability, fines, penalties or the like. Provided, however, that Owner shall not be responsible to Manager for any such expenses in the event Manager is finally adjudged to have violated any such law and acted personally or beyond the scope of the agency rela-tionship intended herein, and not in a representative or agency capacity.",
        12
      );
      addSection(
        "(E) To inform Manager before conveying or leasing the Property.",
        12
      );
      addSection(
        "(F) Upon termination of this Agreement, to assume obligations of all contracts that Manager entered into on Owner’s behalf.",
        12
      );
      addSection(
        "(G) To pay all amounts billed by Manager. Owner authorizes Manager to reimburse itself out of rents collected for any outstanding invoices",
        12
      );
      addSection(
        "(H) To indemnify and hold harmless Manager and Manager’s officers, directors, agents and employees from all claims, demands, causes of action costs and expenses, including reasonable attorneys’ fees at all levels arising from liability to any person, personal injuries, property damages and interference with tenancies, accountants’ fees to the extent based on (i) Owner’s misstatement, negligence, action, inaction or failure to perform the obligations of this contract or any lease or agreement with a vendor; (ii) the existence of undisclosed material facts about the Property; (ii) Manager’s performance, at Owner’s request, of any task beyond the scope of services regulated by Chapter 475, F.S., as amended, including Manager’s referral, recommendation of any vendor; or (4) services or products provided and expenses incurred by any vendor. This subparagraph will survive Manager’s performance and the transfer or title. (iii) To authorize or make repairs necessary to transfer a reasonable safe dwelling unit to the tenant.",
        12
      );
      addSection(
        "(J) To exercise reasonable care to repair dangerous defective conditions upon notice of their existence.",
        12
      );
      addSection(
        "(K) To update Manager of changes to any governing bodies’ mailing address and/or owner of record. Owner hereby verifies that the mailing address, owner of record, and any financial obligation are current. All notices and fines imposed by governing bodies are deliver via U.S. Mail to the mailing address listed in their records. Manager is not be responsible for any monetary fines imposed by any governing bodies.",
        12
      );
      addSection(
        "(L) Homeowner’s association online access must be provided in Section 12 (below). In order for Manager to access current ledger information, notices or others, Owner must include all logins and passwords of homeowner’s association management website.",
        12
      );
      addSection(
        "(M) If Owner has retained a service provider or warranty company, Owner must fill in the contact information for said provider in Section 12 (below). Additionally, Owner must name Manager as an authorized representative.",
        12
      );
      addSection(
        "(N) Owner is not to communicate directly with tenants. If the Owner receives direct communication from tenants, Owner must relay information to Manager.",
        12
      );
      addSection(
        "(O) Upon vacancy of the property Owner must maintain and have active electric and water utilities services. When requested, and at Manager’s discretion, Manager can connect services for property utilizing Manager’s accounts and with Manager’s credit wordiness a processing fee will be added.",
        12
      );
      addSection(
        "(P) Provide Tax ID number. U.S. Tax Law requires Property managers to issue a 1099-MISC tax form for any property Owner who receives more than $600 related to their rental business.",
        12
      );
      addSection(
        "COMPENSATION:\nOwner agrees to compensate Manager and Other Brokers:\n(A) Owner will compensate Manager 6.0% of the total purchase price no later than the date of closing specified in the sales contract. However, closing is not a prerequisite for Manager’s fee being earned. If an option is exercised, Owner will pay Manager the same fee (as stated above) for procuring a buyer who is ready, willing, and able to purchase the Property or any interest in the Property on the terms of this Agreement or on any other terms acceptable to Owner.",
        12
      );
      addSection(
        "(B) Notice to Owner: The buyer’s broker, even if compensated by Owner or Manager, may represent the interests of the buyer. Manager’s office policy is to cooperate with all other brokers except when not in Owner’s best interest and to offer compensation in the amount of 3.0% of the purchase price to a single agent, transaction broker or a broker who has no brokerage relationship with the buyer.",
        12
      );
      addSection(
        "(C) New Leases and Renewals: one month of lease value as a leasing fee, if Owner enters into any new lease, on the date Owner enters into a lease or agreement to lease, whichever is earlier. Renewal is 1/2 of a month of lease value of the original lease with a tenant placed in the Property by or through Manager.",
        12
      );
      addSection(
        "(D) For managing tenant relations and the Property: 10% of gross lease value to be paid on a monthly basis.",
        12
      );
      addSection(
        "(E) Manager’s fee is due in the following circumstances: (1) If any interest in the Property is transferred, whether by sale, lease, exchange, governmental action, bankruptcy, or any other means of transfer, with the assistance of any real estate licensee. (2) If Owner refuses or fails to sign an offer at the price and terms stated in this Agreement, defaults on an executed sales contract, or agrees with a buyer to cancel an executed sales contract. (3) If, within 180 days after Termination Date (“Protection Period”), Owner transfers or contracts to transfer the Property or any interest in the Property to any prospects with whom Manager or any other real estate licensee communicated regarding the Property before Termination Date. However, no fee will be due to Manager if the Property is relisted after Termination Date and sold through another broker.",
        12
      );
      addSection(
        "(F) Retained Deposits: As consideration for Broker’s services, Broker is entitled to receive 50% of all deposits that Owner retains as liquidated damages for a buyer’s default in a transaction, not to exceed the Paragraph 6(a) fee.",
        12
      );
      addSection(
        "SERVICES EXCLUDED: This Agreement does not include providing tax, accounting, or legal advice. Owner shall be solely responsible for assuring compliance of the premises with applicable laws and B compliance with tax reporting requirements. This Agreement does not include on-site management services, refinancing, preparation of the premises for refinancing, modernization, fire or major damage restoration, rehabilitation, representation or appearance before public agencies or in legal proceedings, advising on proposed new construction, debt collection, consultation, attendance at association meetings or public hearings, except to the extent expressly set forth above or otherwise agreed in writing. Manager shall in any event be entitled to payment by Owner of Special Services Compensation for any such service if provided at the rate of $88.00 per hour.",
        12
      );
      addSection(
        "DISPUTE RESOLUTIONS: This Agreement will be construed under Florida law. All disputes between Manager and Owner based on this Agreement or its breach will be mediated under the rules of the American Arbitration Association or another mediator agreed upon by the parties. Mediation is a process in which parties attempt to resolve a dispute by submitting it to an impartial mediator who facilitates the resolution of the dispute but who is not empowered to impose a settlement on the parties. The parties will equally divide the mediation fee, if any. In any litigation based on this Agreement, the prevailing party will be entitled to recover reasonable attorneys’ fees and costs at all levels, unless the parties agree that disputes will be settled by arbitration as follows: Arbitration - Owner and Manager agree that disputes not resolved by mediation will be settled by neutral binding arbitration in the county in which the Property is located in accordance with the rules of the American Arbitration Association or other arbitrator agreed upon by the parties. The arbitrator may not alter the Contract terms. Each party to any arbitration or litigation (including appeals and interpleaders) will pay its own fees, costs and expenses, including attorneys’ fees at all levels, and will equally split the arbitra-tors’ fees and administrative fees of arbitration.",
        12
      );
      addSection(
        "ATTORNEYS’ FEES: In any action between Owner and a tenant in which Manager is made a party because of acting as an escrow agent under this Agreement, or if Manager interpleads escrowed funds, Manager will recover reasonable attorneys’ fees and costs, to be paid out of the escrowed funds and charged and awarded as court costs in favor of the prevailing party.",
        12
      );
      addSection(
        "MISCELLANEOUS: This Agreement is binding on Manager’s and Owner’s heirs, personal representatives, administrators, successors and assigns. Signatures, initials, documents referenced in the Agreement, counterparts and modifications communicated electronically or on paper will be acceptable for all purposes and will be binding. Major work, were defined or implied, are services of a value of $1,000.00 or higher.",
        12
      );
      addSection(
        "ADDITIONAL CLAUSES: Either party must notify the other, in writing, upon the termination date of this agreement the desire to terminate. If no notice is received, the terms of this agreement should continue and this agreement will auto-renew for an equal term as the term stated in this agreement. In addition to conventional written communication, Manager shall be entitled to rely on approvals or instructions from Owner which are received by or confirmed in email transmissions, text messages or other electronic forms of communication established by the custom and practice of the parties.",
        12
      );

      // Guardar el PDF en lugar de imprimir
      pdf.save("ManagementAgreement.pdf"); // Cambia el nombre del archivo como prefieras
    } else {
      console.error("The form reference is not attached to a DOM element.");
    }
  }, [formData1]); // Asegúrate de incluir todas las dependencias necesarias

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name !== "typeOfProperty" && name !== "propertyOccupancy") {
      setFormData1((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  useEffect(() => {
    setIsFormModified(Object.keys(formData1).length > 0);
  }, [formData1]);

  const handleChangePart1 = (data) => {
    setFormData1((prev) => ({ ...prev, ...data }));
  };
  const handleChangePart2 = (data) => {
    setFormData2((prev) => ({ ...prev, ...data }));
  };

  const handleSubmit = useCallback(
    async (e) => {
      if (e) e.preventDefault();

      if (!isConfirmed && isFormModified) {
        setIsPopupVisible(true);
        return;
      }
      try {
        const postResponse = await axios.post(
          "https://blurealty-861843634.development.catalystserverless.com/server/maformpost_funct/post",
          formData1
        );
        console.log(
          `Data sent correctly: ${JSON.stringify(postResponse.data)}`
        );
        const DealId = postResponse.data.data.details.userMessage[0];
        console.log(DealId);
        const putResponse = await axios.put(
          `https://blurealty-861843634.development.catalystserverless.com/server/maformpost_funct/update/${DealId}`,
          formData2
        );
        console.log(
          `Data updated correctly: ${JSON.stringify(putResponse.data)}`
        );
      } catch (error) {
        console.error(`Error during request: ${error}`);
      }
      setIsPopupVisible(false);
      setIsConfirmed(false);
      setIsFormModified(false);
    },
    [formData1, formData2, isConfirmed, isFormModified]
  );

  useEffect(() => {
    if (isConfirmed && isFormModified) {
      handleSubmit();
    }
  }, [isConfirmed, isFormModified, handleSubmit]);

  const handleConfirmSubmit = () => {
    setIsConfirmed(true);
  };
  return (
    <>
      <div ref={formRef}>
        <HeroSection />
        <WelcomeSection />
        <HandSection handlePrint={handlePrint} />
        <Form
          handleChangePart1={handleChangePart1}
          handleChangePart2={handleChangePart2}
          handleSubmit={handleSubmit}
        />
        <LetterOwnerGranting handlePrint={handlePrint} />
        <FooterMAForm />
      </div>
      <CSSTransition
        in={isPopupVisible}
        timeout={300}
        classNames="fade"
        unmountOnExit
        nodeRef={popupRef}
      >
        <div className="PopUp" ref={popupRef}>
          <div className="PopUp__Content">
            <button
              onClick={() => setIsPopupVisible(false)}
              className="PopUp__Close"
            >
              <div className="ClosePopUp"></div>
            </button>
            <h3 className="PopUp__Title">
              You provided us with the following information:
            </h3>
            <div className="dFlex">
              <h4>("Agreement") is between:</h4>
              <input
                type="text"
                id="popUpOwnerName"
                placeholder={formData1.MA_Owner_Record || ""}
                name="MA_Owner_Record"
                onChange={handleInputChange}
              />
              <h4>("Owner")</h4>
            </div>
            <h4 className="mFive">
              and Blu Realty & Investments, LLC (“Manager”).
            </h4>
            <h4 className="PopUp__SubTitle">Description of Property:</h4>
            <div className="PopUp_DescProperty">
              <div className="PopUp_DescProperty--Input">
                <label htmlFor="City1">City:</label>
                <input
                  type="text"
                  name="City1"
                  id="PopUp_propertyCity"
                  placeholder={formData1.City1 || ""}
                  onChange={handleInputChange}
                />
              </div>
              <div className="PopUp_DescProperty--Input">
                <label htmlFor="PopUp_State1">State:</label>
                <input
                  type="text"
                  name="State1"
                  id="PopUp_State1"
                  placeholder={formData1.State1 || ""}
                  onChange={handleInputChange}
                />
              </div>
              <div className="PopUp_DescProperty--Input">
                <label htmlFor="PopUp_Zipcode">Zipcode:</label>
                <input
                  type="text"
                  name="Zipcode"
                  id="PopUp_Zipcode"
                  placeholder={formData1.Zipcode || ""}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="PopUp_DescProperty--Input">
              <label htmlFor="PopUp_MA_Legal_Description">
                (B) Legal Description:
              </label>
              <input
                className="wForty"
                type="text"
                name="MA_Legal_Description"
                id="PopUp_MA_Legal_Description"
                placeholder={formData1.MA_Legal_Description || ""}
                onChange={handleInputChange}
              />
            </div>
            <div className="PopUp_DescProperty--Input">
              <label htmlFor="PopUp_PersonalProperty">
                (C) Personal Property:
              </label>
            </div>
            <div className="gridDropdowns">
              <div
                className="sectionTwelve__DropdownContainer"
                style={{ gridArea: "Uno" }}
              >
                <label className="customSelect">
                  <select
                    name="Refrigerator"
                    id="Refrigerator"
                    onChange={handleChangePart1}
                  >
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                  </select>
                </label>
                <label
                  htmlFor="Refrigerator"
                  className="sectionTwelve__DropdownLabel"
                >
                  Refrigerator
                </label>
              </div>

              <div
                className="sectionTwelve__DropdownContainer"
                style={{ gridArea: "Dos" }}
              >
                <label className="customSelect">
                  <select name="Stove" id="Stove" onChange={handleChangePart1}>
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                  </select>
                </label>
                <label htmlFor="Stove" className="sectionTwelve__DropdownLabel">
                  Stove
                </label>
              </div>

              <div
                className="sectionTwelve__DropdownContainer"
                style={{ gridArea: "Tres" }}
              >
                <label className="customSelect">
                  <select
                    name="Microwave"
                    id="Microwave"
                    onChange={handleChangePart1}
                  >
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                  </select>
                </label>
                <label
                  htmlFor="Microwave"
                  className="sectionTwelve__DropdownLabel"
                >
                  Microwave
                </label>
              </div>

              <div
                className="sectionTwelve__DropdownContainer"
                style={{ gridArea: "Cuatro" }}
              >
                <label className="customSelect">
                  <select
                    name="Dishwasher"
                    id="Dishwasher"
                    onChange={handleChangePart1}
                  >
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                  </select>
                </label>
                <label
                  htmlFor="Dishwasher"
                  className="sectionTwelve__DropdownLabel"
                >
                  Dishwasher
                </label>
              </div>

              <div
                className="sectionTwelve__DropdownContainer"
                style={{ gridArea: "Cinco" }}
              >
                <label className="customSelect">
                  <select name="Dryer" id="Dryer" onChange={handleChangePart1}>
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                  </select>
                </label>
                <label htmlFor="Dryer" className="sectionTwelve__DropdownLabel">
                  Dryer
                </label>
              </div>

              <div
                className="sectionTwelve__DropdownContainer"
                style={{ gridArea: "Seis" }}
              >
                <label className="customSelect">
                  <select
                    name="Washer"
                    id="Washer"
                    onChange={handleChangePart1}
                  >
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                  </select>
                </label>
                <label
                  htmlFor="Washer"
                  className="sectionTwelve__DropdownLabel"
                >
                  Washer
                </label>
              </div>

              <div
                className="sectionTwelve__DropdownContainer"
                style={{ gridArea: "Siete" }}
              >
                <label className="customSelect">
                  <select
                    name="GarbageDisposal"
                    id="GarbageDisposal"
                    onChange={handleChangePart1}
                  >
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                  </select>
                </label>
                <label
                  htmlFor="GarbageDisposal"
                  className="sectionTwelve__DropdownLabel"
                >
                  Garbage Disposal
                </label>
              </div>

              <div
                className="sectionTwelve__DropdownContainer"
                style={{ gridArea: "Ocho" }}
              >
                <label className="customSelect">
                  <select
                    name="GarageOpener"
                    id="GarageOpener"
                    onChange={handleChangePart1}
                  >
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                  </select>
                </label>
                <label
                  htmlFor="GarageOpener"
                  className="sectionTwelve__DropdownLabel"
                >
                  Garage Opener
                </label>
              </div>
            </div>
            <div className="PopUp_DescProperty--Input">
              <label htmlFor="PopUp_OwnerKeys">
                (D) Owner provides the following keys to the property:
              </label>
            </div>
            <div className="dropdownRow">
              <div
                className="sectionTwelve__DropdownContainer sectionTwelve__DropdownContainer--W"
                style={{ gridArea: "KeysOne" }}
              >
                <label className="customSelect">
                  <select
                    name="Keys_Unit"
                    id="Keys_Unit"
                    onChange={handleChangePart1}
                  >
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                  </select>
                </label>
                <label
                  htmlFor="Keys_Unit"
                  className="sectionTwelve__DropdownLabel"
                >
                  Unit
                </label>
              </div>

              <div
                className="sectionTwelve__DropdownContainer sectionTwelve__DropdownContainer--W"
                style={{ gridArea: "KeysTwo" }}
              >
                <label className="customSelect">
                  <select
                    name="Keys_BuildingAccess"
                    id="Keys_BuildingAccess"
                    onChange={handleChangePart1}
                  >
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                  </select>
                </label>
                <label
                  htmlFor="Keys_BuildingAccess"
                  className="sectionTwelve__DropdownLabel"
                >
                  Building Access
                </label>
              </div>

              <div
                className="sectionTwelve__DropdownContainer sectionTwelve__DropdownContainer--W"
                style={{ gridArea: "KeysThree" }}
              >
                <label className="customSelect">
                  <select
                    name="Keys_Mailbox"
                    id="Keys_Mailbox"
                    onChange={handleChangePart1}
                  >
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                  </select>
                </label>
                <label
                  htmlFor="Keys_Mailbox"
                  className="sectionTwelve__DropdownLabel"
                >
                  Mailbox
                </label>
              </div>

              <div
                className="sectionTwelve__DropdownContainer sectionTwelve__DropdownContainer--W"
                style={{ gridArea: "KeysFour" }}
              >
                <label className="customSelect">
                  <select
                    name="Keys_Pool"
                    id="Keys_Pool"
                    onChange={handleChangePart1}
                  >
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                  </select>
                </label>
                <label
                  htmlFor="Keys_Pool"
                  className="sectionTwelve__DropdownLabel"
                >
                  Pool
                </label>
              </div>

              <div
                className="sectionTwelve__DropdownContainer sectionTwelve__DropdownContainer--W"
                style={{ gridArea: "KeysFive" }}
              >
                <label className="customSelect">
                  <select
                    name="Keys_GarageDoorOpener"
                    id="Keys_GarageDoorOpener"
                    onChange={handleChangePart1}
                  >
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                  </select>
                </label>
                <label
                  htmlFor="Keys_GarageDoorOpener"
                  className="sectionTwelve__DropdownLabel"
                >
                  Garage Door Opener
                </label>
              </div>
            </div>
            <div className="PopUp_DescProperty--Input PopUp_DescProperty--Input--Margin">
              <label htmlFor="PopUp_TypeOfProperty">
                (E) Type of Property:
              </label>
              <div className="checkboxContainer">
                <label
                  className="checkbox-label"
                  htmlFor="typeOfPropertyCondominium"
                >
                  Condominium
                  <input
                    type="radio"
                    id="typeOfPropertyCondominium"
                    name="typeOfProperty"
                    value="Condominium"
                    onChange={handleChangePart1}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
              <div className="checkboxContainer">
                <label
                  className="checkbox-label"
                  htmlFor="typeOfPropertyHomeowners"
                >
                  Homeowners
                  <input
                    type="radio"
                    id="typeOfPropertyHomeowners"
                    name="typeOfProperty"
                    value="Homeowners"
                    onChange={handleChangePart1}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
              <div className="checkboxContainer">
                <label
                  className="checkbox-label"
                  htmlFor="typeOfPropertyFreeStanding"
                >
                  Free Standing
                  <input
                    type="radio"
                    id="typeOfPropertyFreeStanding"
                    name="typeOfProperty"
                    value="Free Standing"
                    onChange={handleChangePart1}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
            </div>
            <div className="PopUp_DescProperty--Input PopUp_DescProperty--Input--Margin">
              <label htmlFor="PopUp_propertyOccupancy">(F) Occupancy:</label>
              <div className="checkboxContainer">
                <label className="checkbox-label" htmlFor="Occupancy_isLeased">
                  Property is Leased
                  <input
                    type="radio"
                    id="Occupancy_isLeased"
                    name="Owner_Occupied"
                    value="Is Leased"
                    onChange={handleChangePart1}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
              <div className="checkboxContainer">
                <label className="checkbox-label" htmlFor="Occupancy_Occupied">
                  Owner Occupied
                  <input
                    type="radio"
                    id="Occupancy_Occupied"
                    name="Owner_Occupied"
                    value="Owner Occupied"
                    onChange={handleChangePart1}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
              <div className="checkboxContainer">
                <label className="checkbox-label" htmlFor="Occupancy_Vacant">
                  Vacant
                  <input
                    type="radio"
                    id="Occupancy_Vacant"
                    name="Owner_Occupied"
                    value="Vacant"
                    onChange={handleChangePart1}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
            </div>
            <h4>
              <span className="PopUp__SubTitle">Price and Terms:</span> &nbsp;
              <span className="fReg">
                The property is offered for sale and/or lease on the following
                terms or on other terms acceptable to Seller:
              </span>
            </h4>
            <div className="PopUp_DescProperty PriceAndTerms">
              <div className="PopUp_DescProperty--Input">
                <label htmlFor="PopUp_Price" className="fReg">
                  Price:
                </label>
                <input
                  type="text"
                  name="Price"
                  id="PopUp_Price"
                  placeholder={formData2.Price || ""}
                  onChange={handleInputChange}
                />
              </div>
              <div className="PopUp_DescProperty--Input">
                <label htmlFor="PopUp_Lease" className="fReg">
                  Lease:
                </label>
                <input
                  type="text"
                  name="Lease"
                  id="PopUp_Lease"
                  placeholder={formData2.Lease || ""}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="PopUp_DescProperty--Input">
              <label htmlFor="PopUp_FinancingTerms" className="fReg">
                (b) Financing Terms:
              </label>
              <input
                className="wForty"
                type="text"
                name="FinancingTerms_VAFHA"
                id="PopUp_FinancingTerms"
                placeholder={formData2.FinancingTerms_VAFHA || ""}
                onChange={handleInputChange}
              />
            </div>
            <h4 className="PopUp__SubTitle">Contact Information:</h4>
            <div className="PopUp_DescProperty--Input PopUp__ContactInformation">
              <label htmlFor="PopUp_Email_Contact" className="fReg">
                Main Email:
              </label>
              <input
                className="wForty inputPopUpPersonalProperty"
                type="text"
                name="Email_Contact"
                id="PopUp_Email_Contact"
                placeholder={formData2.Email_Contact || ""}
                onChange={handleInputChange}
              />
            </div>
            <div className="PopUp_DescProperty--Input PopUp__ContactInformation">
              <label htmlFor="PopUp_Secondary_Email_Contact" className="fReg">
                Secondary Email:
              </label>
              <input
                className="wForty inputPopUpPersonalProperty"
                type="text"
                name="Secondary_Email_Contact"
                id="PopUp_Secondary_Email_Contact"
                placeholder={formData2.Secondary_Email_Contact || ""}
                onChange={handleInputChange}
              />
            </div>
            <div className="PopUp_DescProperty--Input PopUp__ContactInformation">
              <label htmlFor="PopUp_Mobile_Contact" className="fReg">
                Main Mobile:
              </label>
              <input
                className="wForty inputPopUpPersonalProperty"
                type="text"
                name="Mobile_Contact"
                id="PopUp_Mobile_Contact"
                placeholder={formData2.Mobile_Contact || ""}
                onChange={handleInputChange}
              />
            </div>
            <div className="PopUp_DescProperty--Input PopUp__ContactInformation">
              <label htmlFor="PopUp_Secondary_Mobile_Contact" className="fReg">
                Secondary Mobile:
              </label>
              <input
                className="wForty inputPopUpPersonalProperty"
                type="text"
                name="Secondary_Mobile_Contact"
                id="PopUp_Secondary_Mobile_Contact"
                placeholder={formData2.Secondary_Mobile_Contact || ""}
                onChange={handleInputChange}
              />
            </div>
            <div className="PopUp_DescProperty--Input">
              <label
                htmlFor="PopUp_contactInformation_Address"
                className="fReg"
              >
                Address:
              </label>
              <input
                className="wForty"
                type="text"
                name="contactInformation_Address"
                id="PopUp_contactInformation_Address"
                placeholder={formData2.contactInformation_Address || ""}
                onChange={handleInputChange}
              />
            </div>
            <div className="PopUp_DescProperty">
              <div className="PopUp_DescProperty--Input">
                <label htmlFor="PopUp_City_Contact">City:</label>
                <input
                  type="text"
                  name="City_Contact"
                  id="PopUp_City_Contact"
                  placeholder={formData2.City_Contact || ""}
                  onChange={handleInputChange}
                />
              </div>
              <div className="PopUp_DescProperty--Input">
                <label htmlFor="PopUp_State_Contact">State:</label>
                <input
                  type="text"
                  name="State_Contact"
                  id="PopUp_State_Contact"
                  placeholder={formData2.State_Contact || ""}
                  onChange={handleInputChange}
                />
              </div>
              <div className="PopUp_DescProperty--Input">
                <label htmlFor="PopUp_Zip_Code_Contact">Zipcode:</label>
                <input
                  type="text"
                  name="Zip_Code_Contact"
                  id="PopUp_Zip_Code_Contact"
                  placeholder={formData2.Zip_Code_Contact || ""}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="PopUp_DescProperty--Input PopUp__ContactInformation">
              <label htmlFor="PopUp_Note_Contact" className="fReg">
                Note:
              </label>
              <input
                className="wForty inputPopUpPersonalProperty"
                type="text"
                name="Note_Contact"
                id="PopUp_Note_Contact"
                placeholder=""
                onChange={handleInputChange}
              />
            </div>
            <h4 className="mFive">
              Mailing Address is where all local governing bodies will mail
              official documents such as: violations or fines.
            </h4>
            <div className="PopUp_DescProperty--Input">
              <label htmlFor="PopUp_Mailing_Address" className="fReg">
                Address:
              </label>
              <input
                className="wForty"
                type="text"
                name="Mailing_Address"
                id="PopUp_Mailing_Address"
                placeholder={formData2.Mailing_Address || ""}
                onChange={handleInputChange}
              />
            </div>
            <div className="PopUp_DescProperty">
              <div className="PopUp_DescProperty--Input">
                <label htmlFor="PopUp_Mailing_City_Address">City:</label>
                <input
                  type="text"
                  name="Mailing_City_Address"
                  id="PopUp_Mailing_City_Address"
                  placeholder={formData2.Mailing_City_Address || ""}
                  onChange={handleInputChange}
                />
              </div>
              <div className="PopUp_DescProperty--Input">
                <label htmlFor="PopUp_Mailing_State_Address">State:</label>
                <input
                  type="text"
                  name="Mailing_State_Address"
                  id="PopUp_Mailing_State_Address"
                  placeholder={formData2.Mailing_State_Address || ""}
                  onChange={handleInputChange}
                />
              </div>
              <div className="PopUp_DescProperty--Input">
                <label htmlFor="PopUp_Mailing_Zip_Code_Address">Zipcode:</label>
                <input
                  type="text"
                  name="Mailing_Zip_Code_Address"
                  id="PopUp_Mailing_Zip_Code_Address"
                  placeholder={formData2.Mailing_Zip_Code_Address || ""}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="checkboxContainer marginTopTwo">
              <label
                className="checkbox-label lineHeight"
                htmlFor="PopUp_Mailing_AssociationCityCounty"
              >
                Check to confirm you would like County/City and the homeowner's
                association mailing address changed to the address entered here.
                <input
                  type="checkbox"
                  name="Mailing_AssociationCityCounty"
                  id="PopUp_Mailing_AssociationCityCounty"
                  value="Yes"
                />
                <span className="checkmark"></span>
              </label>
            </div>
            <div className="PopUp_DescProperty PriceAndTerms">
              <div className="PopUp_DescProperty--Input">
                <label htmlFor="PopUp_Bank_Name" className="fReg">
                  Bank Name:
                </label>
                <input
                  type="text"
                  name="Bank_Name"
                  id="PopUp_Bank_Name"
                  placeholder={formData2.Bank_Name || ""}
                  onChange={handleInputChange}
                />
              </div>
              <div className="PopUp_DescProperty--Input">
                <label htmlFor="PopUp_Account_Bank_Number" className="fReg">
                  Bank Account:
                </label>
                <input
                  type="text"
                  name="Account_Bank_Number"
                  id="PopUp_Account_Bank_Number"
                  placeholder={formData2.Account_Bank_Number || ""}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="PopUp_DescProperty PriceAndTerms">
              <div className="PopUp_DescProperty--Input">
                <label htmlFor="PopUp_Bank_AccountType" className="fReg">
                  Savings or Checking:
                </label>
                <input
                  type="text"
                  name="Bank_AccountType"
                  id="PopUp_Bank_AccountType"
                  placeholder={formData2.Bank_AccountType || ""}
                  onChange={handleInputChange}
                />
              </div>
              <div className="PopUp_DescProperty--Input PopUp__ContactInformation">
                <label htmlFor="PopUp_Bank_Instructions" className="fReg">
                  Special Instructions:
                </label>
                <input
                  className="inputPopUpPersonalProperty"
                  type="text"
                  name="Bank_Instructions"
                  id="PopUp_Bank_Instructions"
                  placeholder={formData2.Bank_Instructions || ""}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <h4>
              <span className="fBold">Keep in mind:</span> <br />
              <span className="fReg">
                This authorization will remain in effect until you revoke it in
                writing. <br /> You may revoke this authorization at any time by
                contacting us at [Phone Number] or by email at [Email Address].
                Blu Realty is not responsible for any loss or damage that may
                result from payment of insurance policies on your behalf.
              </span>
            </h4>
            <div className="PopUp_fIcons">
              <div className="PopUp_fIcon" onClick={handlePrint}>
                <img src={PrintIcon} alt="Print Icon" id="PrintIcon" />
                <p>Print</p>
              </div>
              <div className="PopUp_fIcon">
                <img src={SendIcon} alt="Send Icon" />
                <p>Send</p>
              </div>
              <div className="PopUp_fIcon" onClick={handleDownload}>
                <img src={DownloadIcon} alt="Download Icon" />
                <p>Download</p>
              </div>
              <div className="PopUp_DescProperty--Input PopUp__ContactInformation PopUp_Signature">
                <input
                  className="inputPopUpPersonalProperty"
                  type="text"
                  name="PopUp_Signature"
                  id="PopUp_Signature"
                />
                <label htmlFor="PopUp_Signature" className="fReg">
                  Signature
                </label>
              </div>
            </div>
            <div className="PopUp-Buttons">
              <button onClick={handleConfirmSubmit} className="PopUp__Save">
                Save
              </button>
            </div>
          </div>
        </div>
      </CSSTransition>
    </>
  );
};

export default MASection;

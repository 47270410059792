import React, { useEffect, useState } from "react";
import { Data } from "../../data/Data";

const BioServicesSection = () => {
  useEffect(() => {
    // Slider logic
    let isSliderDown = false;
    let sliderStartX;
    let sliderScrollLeft;
    const slider = document.querySelector(".items");

    const sliderEnd = () => {
      isSliderDown = false;
      slider.classList.remove("active");
    };

    const sliderStart = (e) => {
      isSliderDown = true;
      slider.classList.add("active");
      sliderStartX = (e.pageX || e.touches[0].pageX) - slider.offsetLeft;
      sliderScrollLeft = slider.scrollLeft;
    };

    const sliderMove = (e) => {
      if (!isSliderDown) return;
      e.preventDefault();
      const x = (e.pageX || e.touches[0].pageX) - slider.offsetLeft;
      const dist = x - sliderStartX;
      slider.scrollLeft = sliderScrollLeft - dist;
    };

    slider.addEventListener("mousedown", sliderStart);
    slider.addEventListener("touchstart", sliderStart);
    slider.addEventListener("mousemove", sliderMove);
    slider.addEventListener("touchmove", sliderMove);
    slider.addEventListener("mouseleave", sliderEnd);
    slider.addEventListener("mouseup", sliderEnd);
    slider.addEventListener("touchend", sliderEnd);

    // Carousel logic
    let currentImg = 1;

    function slide(id) {
      currentImg = Number(id);
      document.querySelector("li.active-img").classList.remove("active-img");
      document
        .querySelector(`ul#carousel-imgs > li:nth-child(${currentImg})`)
        .classList.add("active-img");

      document.querySelector("li.active-dot").classList.remove("active-dot");
      document
        .querySelector(`ul#carousel-dots li:nth-child(${currentImg})`)
        .classList.add("active-dot");
    }

    function previousSlide() {
      if (currentImg === 1) {
        slide(2);
      } else {
        slide(currentImg - 1);
      }
    }

    function nextSlide() {
      if (currentImg === 2) {
        slide(1);
      } else {
        slide(currentImg + 1);
      }
    }

    document.querySelectorAll("ul#carousel-dots li").forEach((e) => {
      e.addEventListener("click", () => {
        slide(e.dataset.triggerslide);
      });
    });

    document.addEventListener("keydown", (e) => {
      if (e.key === "ArrowLeft") {
        previousSlide();
      } else if (e.key === "ArrowRight") {
        nextSlide();
      }
    });

    document.querySelectorAll("ul#carousel-imgs li").forEach((e) => {
      e.addEventListener("click", nextSlide);
    });

    // Functionality to toggle 'Selling' and 'Acquisitions' sections
    const btnAcquisitions = document.getElementById("btnAcquisitions");
    const btnSelling = document.getElementById("btnSelling");
    const acquisitionsSection = document.querySelector(
      ".easyBuyingAcquisitions"
    );
    const sellingSection = document.querySelector(".easyBuyingSelling");

    const handleAcquisitionsClick = () => {
      const acquisitionsHeight = "994px";

      if (!acquisitionsSection.classList.contains("hidden")) {
        acquisitionsSection.style.height =
          acquisitionsSection.scrollHeight + "px";
        acquisitionsSection.style.opacity = "1";
        requestAnimationFrame(() => {
          acquisitionsSection.style.transition =
            "height 0.6s ease-in-out, opacity 0.6s ease-in-out";
          acquisitionsSection.style.height = "0px";
          acquisitionsSection.style.opacity = "0";
        });
        acquisitionsSection.addEventListener(
          "transitionend",
          () => {
            acquisitionsSection.classList.add("hidden");
            acquisitionsSection.style.height = "";
            acquisitionsSection.style.opacity = "";
          },
          { once: true }
        );
      } else {
        if (!sellingSection.classList.contains("hidden")) {
          sellingSection.style.height = sellingSection.scrollHeight + "px";
          sellingSection.style.opacity = "1";
          requestAnimationFrame(() => {
            sellingSection.style.transition =
              "height 0.6s ease-in-out, opacity 0.6s ease-in-out";
            sellingSection.style.height = "0px";
            sellingSection.style.opacity = "0";
          });
          sellingSection.addEventListener(
            "transitionend",
            () => {
              sellingSection.classList.add("hidden");
              sellingSection.style.height = "";
              sellingSection.style.opacity = "";
            },
            { once: true }
          );
        }

        acquisitionsSection.classList.remove("hidden");
        acquisitionsSection.style.height = "0px";
        acquisitionsSection.style.opacity = "0";
        acquisitionsSection.style.transition =
          "height 0.6s ease-in-out, opacity 0.6s ease-in-out";
        requestAnimationFrame(() => {
          acquisitionsSection.style.height = acquisitionsHeight;
          acquisitionsSection.style.opacity = "1";
        });
        acquisitionsSection.addEventListener(
          "transitionend",
          () => {
            acquisitionsSection.style.height = "auto";
          },
          { once: true }
        );
      }
    };

    const handleSellingClick = () => {
      const sellingHeight = "800px";

      if (!sellingSection.classList.contains("hidden")) {
        sellingSection.style.height = sellingSection.scrollHeight + "px";
        sellingSection.style.opacity = "1";
        requestAnimationFrame(() => {
          sellingSection.style.transition =
            "height 0.6s ease-in-out, opacity 0.6s ease-in-out";
          sellingSection.style.height = "0px";
          sellingSection.style.opacity = "0";
        });
        sellingSection.addEventListener(
          "transitionend",
          () => {
            sellingSection.classList.add("hidden");
            sellingSection.style.height = "";
            sellingSection.style.opacity = "";
          },
          { once: true }
        );
      } else {
        if (!acquisitionsSection.classList.contains("hidden")) {
          acquisitionsSection.style.height =
            acquisitionsSection.scrollHeight + "px";
          acquisitionsSection.style.opacity = "1";
          requestAnimationFrame(() => {
            acquisitionsSection.style.transition =
              "height 0.6s ease-in-out, opacity 0.6s ease-in-out";
            acquisitionsSection.style.height = "0px";
            acquisitionsSection.style.opacity = "0";
          });
          acquisitionsSection.addEventListener(
            "transitionend",
            () => {
              acquisitionsSection.classList.add("hidden");
              acquisitionsSection.style.height = "";
              acquisitionsSection.style.opacity = "";
            },
            { once: true }
          );
        }

        sellingSection.classList.remove("hidden");
        sellingSection.style.height = "0px";
        sellingSection.style.opacity = "0";
        sellingSection.style.transition =
          "height 0.6s ease-in-out, opacity 0.6s ease-in-out";
        requestAnimationFrame(() => {
          sellingSection.style.height = sellingHeight;
          sellingSection.style.opacity = "1";
        });
        sellingSection.addEventListener(
          "transitionend",
          () => {
            sellingSection.style.height = "auto";
          },
          { once: true }
        );
      }
    };

    btnAcquisitions.addEventListener("click", handleAcquisitionsClick);
    btnSelling.addEventListener("click", handleSellingClick);

    // Clean up event listeners on unmount
    return () => {
      slider.removeEventListener("mousedown", sliderStart);
      slider.removeEventListener("touchstart", sliderStart);
      slider.removeEventListener("mousemove", sliderMove);
      slider.removeEventListener("touchmove", sliderMove);
      slider.removeEventListener("mouseleave", sliderEnd);
      slider.removeEventListener("mouseup", sliderEnd);
      slider.removeEventListener("touchend", sliderEnd);
      btnAcquisitions.removeEventListener("click", handleAcquisitionsClick);
      btnSelling.removeEventListener("click", handleSellingClick);
    };
  }, []);

  const data = Data.jp

  const [pictureSelling, setPictureSelling] = useState(false)
  function changePictureSelling() {
    setPictureSelling(!pictureSelling)
  }
  useEffect(() => {
  },[pictureSelling])

  return (
    <section className="relative font-montserrat">
      <div className="py-[7.688rem] flex items-center justify-center gap-[3.875rem]">
        <div
          className="cursor-pointer rounded-[3.125rem] bg-black h-[400px] w-[800px] shadow-[0px_0px_48px_rgba(0,0,0,0.24)] flex flex-col justify-center items-center bg-cover bg-center relative overflow-hidden"
          id="btnSelling"
          style={{
            backgroundImage: `url(https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/HomePage%2FImages%2FSunnyIsles2.jpeg?alt=media&token=98328710-ebb6-4e30-b9cd-a0b844d34921)`,
          }}
        >
          <div className="absolute inset-0 bg-[#003b5c] opacity-65 mix-blend-multiply transition-all duration-300"></div>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/Icon%2FSellingSmall.svg?alt=media&token=c1db04e2-af78-41c4-b50f-f894c174c5da"
            alt="Selling"
            className="z-10 w-[220px] h-[220px] mt-4"
            draggable="false"
          />
          <h3 className="cursor-pointer text-white text-[2.5rem] uppercase font-semibold mb-8 z-10">
            Selling
          </h3>
        </div>
        <div
          className="cursor-pointer rounded-[3.125rem] bg-black h-[400px] w-[800px] shadow-[0px_0px_48px_rgba(0,0,0,0.24)] flex flex-col justify-center items-center bg-cover bg-center relative overflow-hidden"
          id="btnAcquisitions"
          style={{
            backgroundImage: `url(https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/HomePage%2FImages%2FApartBedroom.jpeg?alt=media&token=5926fabc-7d19-40ff-89aa-ed80c5d5fe1a)`,
          }}
        >
          <div className="absolute inset-0 bg-[#003b5c] opacity-65 mix-blend-multiply transition-all duration-300"></div>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/Icon%2FAcquisitionsSmall.svg?alt=media&token=f5267c12-abb6-4ac5-8de6-545a8345846a"
            alt="Acquisitions"
            className="z-10 w-[220px] h-[220px] mt-4"
            draggable="false"
          />
          <h3 className="cursor-pointer text-white text-[2.5rem] uppercase font-semibold mb-8 z-10">
            Acquisitions
          </h3>
        </div>
      </div>

      <div className="hidden easyBuyingSelling">
        <div id="carousel-root" className="w-full h-[105vh] flex justify-center items-center relative">
          {
            pictureSelling === false ?
              <img onClick={() => changePictureSelling()} src={data.images.virtualStaging} alt="Virtual Stagin" className="w-full h-screen object-cover object-center" draggable="false" />
              : <img onClick={() => changePictureSelling()} src={data.images.staging} alt="Staging" className="w-full h-screen object-cover object-center" draggable="false" />
          }

          <ul id="carousel-dots" className="flex absolute z-[350] bottom-[15px] right-0 left-0 justify-center m-0 p-[0_50px]">
            <li onClick={() => changePictureSelling()} data-triggerslide="1" className="block rounded-full h-[25px] w-[25px] bg-[#707070] mx-2.5 cursor-pointer transition-bg duration-[0.4s] ease-out"></li>
            <li onClick={() => changePictureSelling()} data-triggerslide="2" className="block rounded-full h-[25px] w-[25px] bg-[#707070] mx-2.5 cursor-pointer transition-bg duration-[0.4s] ease-out"></li>
          </ul>
        </div>
        <h2 className="pt-40 mx-auto mb-16 w-fit text-center uppercase font-light text-[#003b5c] text-[3.125rem] border-b-4 border-[#003b5c]">
          Marketing Strategy
        </h2>
        <div className="relative my-[7.668rem]">
          <ul className="items w-full relative whitespace-nowrap cursor-pointer overflow-hidden">
            <li className="inline-block user-select-none w-full h-auto">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/BioPage%2FImages%2FTimeline1.svg?alt=media&token=c83be5c3-63d9-4678-88e4-112809823c6a"
                alt="Timeline 1"
                className="w-full user-drag-none"
                draggable="false"
              />
            </li>
            <li className="inline-block user-select-none w-full h-auto">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/BioPage%2FImages%2FTimeline2.svg?alt=media&token=b86c078b-4ca1-4d07-9c48-9bc7f21bc80c"
                alt="Timeline 2"
                className="w-full user-drag-none"
                draggable="false"
              />
            </li>
            <li className="inline-block user-select-none w-full h-auto">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/BioPage%2FImages%2FTimeline3.svg?alt=media&token=3c39c0bb-f797-49dd-ba09-d94eaf178227"
                alt="Timeline 3"
                className="w-full user-drag-none"
                draggable="false"
              />
            </li>
            <li className="inline-block user-select-none w-full h-auto">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/BioPage%2FImages%2FTimeline4.svg?alt=media&token=1ea098bf-f0cc-4473-9214-0ee9bff7c9c5"
                alt="Timeline 4"
                className="w-full user-drag-none"
                draggable="false"
              />
            </li>
          </ul>
        </div>
      </div>

      <div className="hidden easyBuyingAcquisitions">
        <h2 className="text-center uppercase font-light text-[#003b5c] text-[4rem] mt-[4.875rem] mb-4">
          Easy Buying
        </h2>
        <h2 className="text-center text-[#003b5c] text-[5rem] font-semibold leading-none">
          Effortless Efficiency.
        </h2>
        <p className="text-center font-light text-[#003b5c] w-[60%] mx-auto mt-12 mb-24">
          All in one report. Easy buying. Effortless efficiency. With our Buyer
          Presentation Report, the{" "}
          <strong>buying experience is simple. Grow your portfolio</strong>{" "}
          without management worries. Our advanced technology delivers detailed
          reports that keep you informed and in control, streamlining the
          process and maximizing your results.
        </p>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/BioPage%2FImages%2Fexample.svg?alt=media&token=6a62998a-3b62-4be5-b29c-255c391dbb0b"
          alt="Example Dashboard Image"
          className="mx-auto w-[1000px]"
          draggable="false"
        />
      </div>
    </section>
  );
};

export default BioServicesSection;
import React, { useState } from "react";
import letspop from '../../../src/assets/letspop.svg'
const BLU_Img_Mar_36 =

  "https://console.catalyst.zoho.com/baas/v1/project/6905000000008864/folder/6905000000051074/file/16144000000033183/download?Environment=Production&catalyst_org=861843634";
const BLU_Img_Mar_35 =
  "https://console.catalyst.zoho.com/baas/v1/project/6905000000008864/folder/6905000000051074/file/16144000000033178/download?Environment=Production&catalyst_org=861843634";
const BLU_Img_Mar_34 =
  "https://console.catalyst.zoho.com/baas/v1/project/6905000000008864/folder/6905000000051074/file/16144000000033173/download?Environment=Production&catalyst_org=861843634";
const bluCaresLogo =
  "https://console.catalyst.zoho.com/baas/v1/project/6905000000008864/folder/6905000000051074/file/16144000000033158/download?Environment=Production&catalyst_org=861843634";
const FreeRecyclingIcon =
  "https://console.catalyst.zoho.com/baas/v1/project/6905000000008864/folder/6905000000051074/file/16144000000033118/download?Environment=Production&catalyst_org=861843634";
const MailLithiumIcon =
  "https://console.catalyst.zoho.com/baas/v1/project/6905000000008864/folder/6905000000051074/file/16144000000033098/download?Environment=Production&catalyst_org=861843634";
const NationalDropOffsIcon =
  "https://console.catalyst.zoho.com/baas/v1/project/6905000000008864/folder/6905000000051074/file/16144000000033093/download?Environment=Production&catalyst_org=861843634";
const The_Lowes_Logo =
  "https://console.catalyst.zoho.com/baas/v1/project/6905000000008864/folder/6905000000051074/file/16144000000033028/download?Environment=Production&catalyst_org=861843634";
const The_Home_Depot_Logo =
  "https://console.catalyst.zoho.com/baas/v1/project/6905000000008864/folder/6905000000051074/file/16144000000033033/download?Environment=Production&catalyst_org=861843634";
const The_Best_Buy_Logo =
  "https://console.catalyst.zoho.com/baas/v1/project/6905000000008864/folder/6905000000051074/file/16144000000033038/download?Environment=Production&catalyst_org=861843634";
const Call_2_Recycle_Logo =
  "https://console.catalyst.zoho.com/baas/v1/project/6905000000008864/folder/6905000000051074/file/16144000000033148/download?Environment=Production&catalyst_org=861843634";
const Battery_Solutions_Logo =
  "https://console.catalyst.zoho.com/baas/v1/project/6905000000008864/folder/6905000000051074/file/16144000000033225/download?Environment=Production&catalyst_org=861843634";
const Amazon_Recycling_Logo =
  "https://console.catalyst.zoho.com/baas/v1/project/6905000000008864/folder/6905000000051074/file/16144000000033230/download?Environment=Production&catalyst_org=861843634";
const Step1 =
  "https://console.catalyst.zoho.com/baas/v1/project/6905000000008864/folder/6905000000051074/file/16144000000033053/download?Environment=Production&catalyst_org=861843634";
const Step2 =
  "https://console.catalyst.zoho.com/baas/v1/project/6905000000008864/folder/6905000000051074/file/16144000000033048/download?Environment=Production&catalyst_org=861843634";
const Step3 =
  "https://console.catalyst.zoho.com/baas/v1/project/6905000000008864/folder/6905000000051074/file/16144000000033043/download?Environment=Production&catalyst_org=861843634";
const letsCigarrillos =
  "https://console.catalyst.zoho.com/baas/v1/project/6905000000008864/folder/6905000000051074/file/16144000000033103/download?Environment=Production&catalyst_org=861843634";
  const lets =
  "https://console.catalyst.zoho.com/baas/v1/project/6905000000008864/folder/6905000000051074/file/16144000000033113/download?Environment=Production&catalyst_org=861843634";

export default function BluCaresSection() {
  const [activePopup, setActivePopup] = useState(null);
  const [showInnerPopup, setShowInnerPopup] = useState(null);

  const togglePopup = (popupName) => {
    setActivePopup(activePopup === popupName ? null : popupName);
    setShowInnerPopup(null); // Cierra cualquier popup interno si se cambia de popup
  };

  const toggleInnerPopup = (popupName) => {
    setShowInnerPopup(showInnerPopup === popupName ? null : popupName);
  };
  const [activeStep, setActiveStep] = useState(1);

  const handleStepClick = (step) => {
    setActiveStep(step);
  };
  return (
    <div className="flex flex-col items-center px-8 py-16 font-montserrat">
      <img
        src={bluCaresLogo}
        alt="Blu Cares Logo"
        className="w-full max-w-lg mb-10"
      />
      <h1 className="text-3xl md:text-4xl font-semibold text-[#003b5c] text-center mb-14">
        ENVIRONMENTAL AND SUSTAINABILITY SOLUTIONS
      </h1>

      <div className="flex flex-wrap justify-center gap-[5.5rem]">
        {/* Imagen 1: Popup de baterías de litio */}
        <div
          className="relative w-[21rem] h-[29rem] overflow-hidden rounded-2xl cursor-pointer"
          onClick={() => togglePopup("lithiumPopup")}
        >
          <img
            src={BLU_Img_Mar_36}
            alt="Lithium Batteries"
            className="w-full h-full object-cover transition-transform duration-300 ease-in-out hover:scale-110" style={{ filter: 'brightness(82%)' }}
          />
          <p className="absolute bottom-0 w-full bg-[#2699fb] text-white text-2xl font-bold text-center py-4">
            Lithium Batteries
          </p>
        </div>

        {/* Imagen 2: Popup de Free Recycling Centers */}
        <div
          className="relative w-[21rem] h-[29rem] overflow-hidden rounded-2xl cursor-pointer"
          onClick={() => togglePopup("cigaretteConesPopup")}
        >
          <img
            src={BLU_Img_Mar_35}
            alt="Free Recycling Centers"
            className="w-full h-full object-cover transition-transform duration-300 ease-in-out hover:scale-110"
            style={{ filter: 'brightness(82%)' }}
          />
          <p className="absolute bottom-0 w-full bg-[#2699fb] text-white text-2xl font-bold text-center py-4">
            Cigarette Cones
          </p>
        </div>

        {/* Imagen 3: Popup de Let's Be Proud */}
        <div
          className="relative w-[21rem] h-[29rem] overflow-hidden rounded-2xl cursor-pointer"
          onClick={() => togglePopup("letsBeProudPopup")}
        >
          <img
            src={BLU_Img_Mar_34}
            alt="Let's Be Proud"
            className="w-full h-[87%] object-cover  transition-transform duration-300 ease-in-out hover:scale-110" style={{ filter: 'brightness(82%)' }}
          />
          <p className="absolute bottom-0 w-full bg-[#2699fb] text-white text-2xl font-bold text-center py-4">
            Let's Be Proud
          </p>
        </div>
      </div>

      <button className="mt-16 px-12 py-4 border-2 border-[#2699fb] text-[#2699fb] text-2xl font-bold rounded-2xl transition-colors duration-300 ease-in-out hover:bg-[#2699fb] hover:text-white">
        LEARN MORE
      </button>










      {/* Popup de Cigarette Cones */}
      {activePopup === "cigaretteConesPopup" && (
        <div>
          {/* Fondo borroso */}
          <div
            className="fixed top-0 left-0 w-full h-full bg-black/50 backdrop-blur z-10"
            onClick={() => togglePopup("cigaretteConesPopup")}
          ></div>

          {/* Contenedor del popup */}
          <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[80%] max-w-[80%] h-[90%] max-h-[80vh] bg-white p-8 rounded-[2.5rem] z-20 shadow-lg ">
            <h2 className="text-4xl text-center text-[#003b5c] font-light mb-8">
              Efficient Collection and Recycling Process
            </h2>

            <div className="flex flex-col md:flex-row items-center md:items-start justify-center gap-8">
              {/* Imagen */}
              <div className="w-full md:w-1/2 flex justify-center">
                <img
                  src={letsCigarrillos}
                  alt="Efficient Collection"
                  className="w-[75%] h-[27rem] max-w-lg rounded-2xl shadow-md object-cover"
                  style={{ objectPosition: 'center' }} // Ajusta la posición del zoom
                />
              </div>

              {/* Timeline y Texto explicativo */}
              <div className="w-full md:w-1/2 relative">
                {/* Línea azul (timeline) */}
                <div className="absolute left-[-13px] top-4 bottom-[13px] w-1 bg-[#2699fb] rounded-full"></div>

                <ol className="relative space-y-16">
                  {/* Primer elemento */}
                  <li className="relative pl-12">
                    <div className="absolute left-[-3.5rem] top-0 bg-[#2699fb] text-white font-bold w-[5.5rem] text-[3rem] h-[5.5rem] flex items-center justify-center rounded-full">
                      1
                    </div>
                    <h4 className="text-xl font-bold text-[#003b5c]">Collection:</h4>
                    <p className="text-gray-700">
                      Installation of bins on the beaches for disposing of cigarette butts. These devices help keep the beaches clean and prevent butts from polluting the ocean.
                    </p>
                  </li>

                  {/* Segundo elemento */}
                  <li className="relative pl-12">
                    <div className="absolute left-[-3.5rem] top-0 bg-[#2699fb] text-white font-bold w-[5.5rem] text-[3rem] h-[5.5rem] flex items-center justify-center rounded-full">
                      2
                    </div>
                    <h4 className="text-xl font-bold text-[#003b5c]">Recycling Process:</h4>
                    <p className="text-gray-700">
                      Collected butts are transported to specialized recycling centers. There, toxic materials are separated and recyclable components are reused.
                    </p>
                  </li>

                  {/* Tercer elemento */}
                  <li className="relative pl-12">
                    <div className="absolute left-[-3.5rem] top-0 bg-[#2699fb] text-white font-bold w-[5.5rem] text-[3rem] h-[5.5rem] flex items-center justify-center rounded-full">
                      3
                    </div>
                    <h4 className="text-xl font-bold text-[#003b5c]">Transformation:</h4>
                    <p className="text-gray-700">
                      Recycled butts are transformed into useful products, such as recycled plastic or construction materials. A small action can make a difference in the planet's future.
                    </p>
                  </li>
                </ol>
              </div>
            </div>

            {/* Botón de cierre */}
            <button
              className="absolute bottom--12 left-1/2 transform -translate-x-1/2 bg-white text-[#2699fb] text-2xl font-semibold rounded-full w-20 h-20 flex items-center justify-center shadow-2xl cursor-pointer"
              style={{ bottom: '-32px' }}
              onClick={() => setActivePopup(null)}
            >
              X
            </button>
          </div>
        </div>
      )}






{/* Popup de Let's Be Proud */}
{/* Popup de Let's Be Proud */}
{activePopup === "letsBeProudPopup" && (
  <div>
    {/* Fondo borroso */}
    <div
      className="fixed top-0 left-0 w-full h-full bg-black/50 backdrop-blur z-10"
      onClick={() => togglePopup("letsBeProudPopup")}
    ></div>

    {/* Contenedor del popup */}
    <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[80%] max-w-[80%] h-auto max-h-[90vh] bg-white p-8 rounded-[2.5rem] z-20 shadow-lg popup-lets">
      <div className="relative">
        <img src={lets} alt="lets" className="lets-image rounded-2xl" />

        {/* Superposición de texto */}
        <div className="absolute top-[10px] left-[20px] w-[30%] h-auto bg-white/60 rounded-[50px] pt-6 pl-2 pr-2 pb-0 flex flex-col items-center text-center lets-text-overlay">
  <p className="text-[14px] text-[#003b5c] font-semibold">
    We take pride in caring for our beaches and protecting the
    environment. Trash collection is essential to preserving the beauty
    of our oceans. Every small effort counts; together, we can make a
    significant impact.
  </p>
  <img src={letspop} alt="Let's Be Proud" className="mt-[-30px] w-[13rem] h-48" />
</div>
      </div>

      {/* Botón de cierre */}
      <button
        className="absolute bottom-[-2rem] left-1/2 transform -translate-x-1/2 bg-white text-[#2699fb] text-2xl font-semibold rounded-full w-20 h-20 flex items-center justify-center shadow-lg cursor-pointer"
        onClick={() => setActivePopup(null)}
      >
        X
      </button>
    </div>
  </div>
)}









      {/* Popup de baterías de litio */}
      {activePopup === "lithiumPopup" && (
        <div>
          {/* Fondo borroso */}
          <div
            className="fixed top-0 left-0 w-full h-full bg-black/50 backdrop-blur z-10"
            onClick={() => togglePopup("lithiumPopup")}
          ></div>

          {/* Contenedor del popup */}
          <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[80%] max-w-[80%] h-[90%] max-h-[80vh] bg-white p-8 rounded-[2.5rem] z-20 shadow-lg">
            <h2 className="text-4xl text-center text-[#003b5c] font-light mb-8">
              Lithium Batteries
            </h2>
            <p className="text-center text-xl mb-8">
              Maximizing the value of used lithium batteries depends on proper
              management, allowing for the recovery of key materials and reducing
              environmental impact.
            </p>
            <div className="flex justify-center mb-12" style={{ gap: '10%', height: '40%', marginTop: '12%' }}>
              {/* Contenedor 1: Free Recycling Centers */}
              <div
                className="flex flex-col items-center cursor-pointer transform transition-transform duration-300 hover:scale-105 hover:shadow-lg"
                onClick={() => toggleInnerPopup("freeRecycling")}
              >
                <img
                  src={FreeRecyclingIcon}
                  alt="Free recycling centers"
                  className="h-28 mb-8 transition-transform duration-300 hover:scale-105"
                  style={{ width: '190px' }}
                />
                <p className="bg-[#2699fb] text-white text-center w-full py-4 rounded-b-2xl">
                  Free Recycling Centers
                </p>
              </div>
              {/* Contenedor 2: National Drop-Offs */}
              <div
                className="flex flex-col items-center cursor-pointer  transform transition-transform duration-300 hover:scale-105 hover:shadow-lg"
                onClick={() => toggleInnerPopup("nationalDropOffs")}
              >
                <img
                  src={NationalDropOffsIcon}
                  alt="National Drop-Offs"
                  className="h-28 mb-8 transition-transform duration-300  hover:scale-105"
                  style={{ width: '190px' }}
                />
                <p className="bg-[#2699fb] text-white text-center w-full py-4 rounded-b-2xl">
                  National Drop-Offs
                </p>
              </div>
              {/* Contenedor 3: Mail Lithium Batteries */}
              <div
                className="flex flex-col items-center cursor-pointer transform transition-transform duration-300 hover:scale-105 hover:shadow-lg"
                onClick={() => toggleInnerPopup("mailLithium")}
              >
                <img
                  src={MailLithiumIcon}
                  alt="Mail Lithium Batteries?"
                  className="h-28 mb-8 transition-transform duration-300 hover:scale-105"
                  style={{ width: '190px' }}
                />
                <p className="bg-[#2699fb] text-white text-center w-full py-4 rounded-b-2xl">
                  Mail Lithium Batteries?
                </p>
              </div>
            </div>
            <button
              className="absolute bottom--12 left-1/2 transform -translate-x-1/2 bg-white text-[#2699fb] text-2xl font-semibold rounded-full w-20 h-20 flex items-center justify-center shadow-2xl cursor-pointer"
              style={{ bottom: '-32px' }}
              onClick={() => togglePopup("lithiumPopup")}
            >
              X
            </button>
          </div>






          {/* Popup interno de "Free Recycling Centers" */}
          {showInnerPopup === "freeRecycling" && (
            <div>
              <div
                className="fixed top-0 left-0 w-full h-full backdrop-blur-xl z-10"
                onClick={() => toggleInnerPopup("freeRecycling")}
              ></div>
              <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[calc(100%-4rem)] h-[90%] bg-transparent p-10 rounded-2xl z-20 shadow-2xl">
                <div className="relative flex flex-col items-center text-center h-full bg-white bg-opacity-80 backdrop-blur-lg p-8 rounded-2xl">
                  <h2 className="text-4xl font-light text-[#003b5c] mb-8">
                    Lithium Battery Drop-Offs
                  </h2>

                  <div className="relative w-full h-full">
                    <div className="absolute top-[4rem] left-1/4 transform -translate-x-1/2">
                      <div className="flex flex-col items-center text-center max-w-xs">
                        <img
                          src={The_Home_Depot_Logo}
                          alt="The Home Depot"
                          className="w-44 h-44 rounded-full mb-4 object-cover bg-white p-4 shadow-[0_4px_15px_rgba(0,0,0,0.4)] border-6 border-white"
                        />
                        <p>
                          <strong className="underline font-bold">The Home Depot</strong>
                          <br />
                          Located near the entrance or customer service area.
                        </p>
                      </div>
                    </div>

                    <div className="absolute top-[4rem] left-1/2 transform -translate-x-1/2">
                      <div className="flex flex-col items-center text-center max-w-xs">
                        <img
                          src={The_Lowes_Logo}
                          alt="Lowe's"
                          className="w-44 h-44 rounded-full mb-4 object-cover bg-white p-4 shadow-[0_4px_15px_rgba(0,0,0,0.4)] border-6 border-white"
                        />
                        <p>
                          <strong className="underline font-bold">Lowe's</strong>
                          <br />
                          These bins are typically found near the entrance or customer service area.
                        </p>
                      </div>
                    </div>

                    <div className="absolute top-[4rem] left-3/4 transform -translate-x-1/2">
                      <div className="flex flex-col items-center text-center max-w-xs">
                        <img
                          src={The_Best_Buy_Logo}
                          alt="Best Buy"
                          className="w-44 h-44 rounded-full mb-4 object-cover bg-white p-4 shadow-[0_4px_15px_rgba(0,0,0,0.4)] border-6 border-white"
                        />
                        <p>
                          <strong className="underline font-bold">Best Buy</strong>
                          <br />
                          Stores feature recycling kiosks at the entrance.
                        </p>
                      </div>
                    </div>
                  </div>

                  <p className="absolute bottom-[4rem] text-base text-gray-700">
                    These centers work in partnership with{" "}
                    <a href="#" className="text-blue-500 underline">Call2Recycle</a>, one of the largest battery recycling programs in the U.S. It’s a good idea to call ahead to verify accepted types of batteries and confirm details.
                  </p>

                  <button
                    className="absolute bottom-[-2.3rem] left-1/2 transform -translate-x-1/2 bg-white text-[#2699fb] text-2xl font-semibold rounded-full w-20 h-20 flex items-center justify-center shadow-lg cursor-pointer"
                    onClick={() => toggleInnerPopup("freeRecycling")}
                  >
                    X
                  </button>
                </div>
              </div>
            </div>
          )}

          {/* Popup interno de "National Drop-Offs" */}
          {showInnerPopup === "nationalDropOffs" && (
            <div>
              <div
                className="fixed top-0 left-0 w-full h-full backdrop-blur-xl z-10"
                onClick={() => toggleInnerPopup("nationalDropOffs")}
              ></div>
              <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[calc(100%-4rem)] h-[90%] bg-transparent p-10 rounded-2xl z-20 shadow-2xl">
                <div className="relative flex flex-col items-center text-center h-full bg-white bg-opacity-80 backdrop-blur-lg p-8 rounded-2xl">
                  <h2 className="text-4xl font-light text-[#003b5c] mb-8">
                    National Drop-Offs
                  </h2>

                  <div className="relative w-full h-full" >
                    <div className="absolute top-[6rem] left-1/4 transform -translate-x-1/2">
                      <div className="flex flex-col items-center text-center max-w-xs">
                        <img
                          src={Call_2_Recycle_Logo}
                          alt="Call 2 Recycle"
                          className="w-44 h-44 rounded-full mb-4 object-cover bg-white shadow-[0_4px_15px_rgba(0,0,0,0.2)]"
                        />
                        <p>
                          <strong className="underline font-bold">Call2Recycle</strong>
                          <br />
                          Free recycling at collection points nationwide.
                        </p>
                      </div>
                    </div>

                    <div className="absolute top-[6rem] left-1/2 transform -translate-x-1/2">
                      <div className="flex flex-col items-center text-center max-w-xs">
                        <img
                          src={Battery_Solutions_Logo}
                          alt="Battery Solutions"
                          className="w-44 h-44 rounded-full mb-4 object-contain bg-white shadow-[0_4px_15px_rgba(0,0,0,0.2)]"
                        />
                        <p>
                          <strong className="underline font-bold">Battery Solutions</strong>
                          <br />
                          Provides recycling kits for national delivery and easy drop-off.
                        </p>
                      </div>
                    </div>

                    <div className="absolute top-[6rem] left-3/4 transform -translate-x-1/2">
                      <div className="flex flex-col items-center text-center max-w-xs">
                        <img
                          src={Amazon_Recycling_Logo}
                          alt="Amazon Recycling Program"
                          className="w-44 h-44 rounded-full mb-4 object-cover bg-white  shadow-[0_4px_15px_rgba(0,0,0,0.2)]"
                        />
                        <p>
                          <strong className="underline font-bold">Amazon Recycling Program</strong>
                          <br />
                          Offers national drop-off points, with options for mailing or in-store delivery.
                        </p>
                      </div>
                    </div>
                  </div>

                  <p className="absolute bottom-[4rem] text-base text-gray-700">
                    These centers work in partnership with{" "}
                    <a href="#" className="text-blue-500 underline">Call2Recycle</a>, one of the largest battery recycling programs in the U.S. It’s a good idea to call ahead to verify accepted types of batteries and confirm details.
                  </p>

                  <button
                    className="absolute bottom-[-2.3rem] left-1/2 transform -translate-x-1/2 bg-white text-[#2699fb] text-2xl font-semibold rounded-full w-20 h-20 flex items-center justify-center shadow-2xl cursor-pointer"
                    onClick={() => toggleInnerPopup("nationalDropOffs")}
                  >
                    X
                  </button>
                </div>
              </div>
            </div>
          )}








          {/* Popup interno de "Mail Lithium Batteries" */}
          {showInnerPopup === "mailLithium" && (
            <div>
              <div
                className="fixed top-0 left-0 w-full h-full backdrop-blur-xl z-10"
                onClick={() => toggleInnerPopup("mailLithium")}
              ></div>
              <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[calc(85%-3rem)] h-[85%] bg-white rounded-2xl z-20 shadow-2xl">
                <div className="relative flex flex-col items-center text-center h-full bg-white p-8 rounded-2xl">
                  <h2 className="text-4xl font-light text-[#003b5c] mb-8">
                    Lithium Battery Shipping Guide
                  </h2>

                  {/* Contenedor del timeline */}
                  <div className="relative top-24 w-full flex justify-between items-center">
                    {/* Primer círculo azul en el extremo izquierdo */}
                    <div className="bg-[#2699FB] w-[2rem] h-[2rem] flex items-center justify-center rounded-full z-10"></div>

                    {/* Línea azul detrás de los círculos */}
                    <div className="absolute left-0 right-0 top-1/2 transform -translate-y-1/2 h-1 bg-[#2699FB] z-0"></div>

                    {/* Contenedor del primer SVG */}
                    <div className="relative flex flex-col items-center">
                      <div
                        className={`bg-[#2699FB] w-[7rem] h-[7rem] flex items-center justify-center rounded-full border-[0.4rem] shadow-[0_4px_15px_rgba(0,0,0,0.4)] border-white z-10 cursor-pointer`}
                        onClick={() => handleStepClick(1)}
                      >
                        <img src={Step1} alt="Step 1" className="w-[5rem] h-[4rem] " />
                      </div>

                      {/* Texto debajo del SVG con transición */}
                      <div
                        className={`absolute top-[5.8rem]  p-4 rounded-2xl shadow-lg w-[12rem] transition-all duration-500 ease-in-out ${activeStep === 1 ? "opacity-100 translate-y-0" : "opacity-0 translate-y-4"
                          }`}
                      >
                        <h3 className="text-xl font-semibold mb-2">Request a Recycling Kit</h3>
                        <p className="text-sm">
                          Contact a recycling provider like Call2Recycle or Battery Solutions and request a packaging kit that includes prepaid shipping labels.
                        </p>
                      </div>
                    </div>

                    {/* Contenedor del segundo SVG */}
                    <div className="relative flex flex-col items-center">
                      <div
                        className={`bg-[#2699FB] w-[7rem] h-[7rem] flex items-center justify-center rounded-full border-[0.4rem] border-white z-10 shadow-[0_4px_15px_rgba(0,0,0,0.4)] cursor-pointer`}
                        onClick={() => handleStepClick(2)}
                      >
                        <img src={Step2} alt="Step 2" className="w-[5rem] h-[4rem]" />
                      </div>

                      {/* Texto debajo del SVG con transición */}
                      <div
                        className={`absolute top-[5.8rem]  p-4 rounded-2xl shadow-lg w-[12rem] transition-all duration-500 ease-in-out ${activeStep === 2 ? "opacity-100 translate-y-0" : "opacity-0 translate-y-4"
                          }`}
                      >
                        <h3 className="text-xl font-semibold mb-2">Pack the Batteries</h3>
                        <p className="text-sm">
                          Once you receive the kit, place the batteries inside the provided packaging according to the instructions. Ensure they are securely packed to prevent any damage during transport.
                        </p>
                      </div>
                    </div>

                    {/* Contenedor del tercer SVG */}
                    <div className="relative flex flex-col items-center">
                      <div
                        className={`bg-[#2699FB] w-[7rem] h-[7rem] flex items-center justify-center rounded-full border-[0.4rem] border-white z-10 shadow-[0_4px_15px_rgba(0,0,0,0.4)] cursor-pointer`}
                        onClick={() => handleStepClick(3)}
                      >
                        <img src={Step3} alt="Step 3" className="w-[5rem] h-[5rem]" />
                      </div>

                      {/* Texto debajo del SVG con transición */}
                      <div
                        className={`absolute top-[5.8rem]  p-4 rounded-2xl shadow-lg w-[12rem] transition-all duration-500 ease-in-out ${activeStep === 3 ? "opacity-100 translate-y-0" : "opacity-0 translate-y-4"
                          }`}
                      >
                        <h3 className="text-xl font-semibold mb-2">Schedule the Pickup</h3>
                        <p className="text-sm">
                          Affix the prepaid shipping label in a visible location. If shipping by air, include a label indicating that it contains lithium batteries.
                        </p>
                      </div>
                    </div>

                    {/* Último círculo azul en el extremo derecho */}
                    <div className="bg-[#2699FB] w-[2rem] h-[2rem] flex items-center justify-center rounded-full z-10"></div>
                  </div>

                  <button
                    className="absolute bottom-[-2.3rem] left-1/2 transform -translate-x-1/2 bg-white text-[#2699fb] text-2xl font-semibold rounded-full w-20 h-20 flex items-center justify-center shadow-2xl cursor-pointer"
                    onClick={() => toggleInnerPopup("mailLithium")}
                  >
                    X
                  </button>
                </div>
              </div>
            </div>










          )}




        </div>


      )}
    </div>



  );
}
import { useEffect, useState } from "react";
import { Data } from "../../data/Data";

export default function BeachCleanUpSection() {

  const data = Data.bluWorld.beachCleanUpSection

  const [Image, setImage] = useState("image-a");
  const [timeCarrousel, setTimeCarrousel] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      if (Image === "image-a") {
        setImage("image-b");
        setTimeCarrousel(2);
      } else if (Image === "image-b") {
        setImage("image-c");
        setTimeCarrousel(3);
      } else {
        setImage("image-a");
        setTimeCarrousel(1);
      }
    }, 22000);
    return () => clearInterval(interval);
  }, [Image, timeCarrousel]);


  const images = {
    "image-a": data.images.imageA,
    "image-b": data.images.imageB,
    "image-c": data.images.imageC,
  };

  return (
    <div className="w-full flex flex-col justify-center items-center pt-[30%] font-montserrat gap-8" id="beach-clean">
      <div className="w-full flex flex-col md:flex-row justify-center items-center gap-8">

        {/* Beach Info */}
        <div className="flex flex-col items-start text-[#003b5c] w-11/12 max-w-2xl gap-6">
          <h1 className="text-3xl md:text-4xl text-start font-bold">{data.beachInfo.title}</h1>
          <p className="text-lg">
            <span className="font-bold">{data.beachInfo.textOne}</span>{" "}
            <a
              href="https://www.google.com/maps/place/Playa+de+la+Pen%C3%ADnsula+de+Bar%C3%BA/@10.1694849,-75.6580888,2a,75y,32.86h,98.11t,0r/data=!3m7!1e1!3m5!1scD8xsfKSpxn9tCvMqCzvOA!2e0!6shttps:%2F%2Fstreetviewpixels-pa.googleapis.com%2Fv1%2Fthumbnail%3Fpanoid%3DcD8xsfKSpxn9tCvMqCzvOA%26cb_client%3Dsearch.gws-prod.gps%26w%3D211%26h%3D120%26yaw%3D0%26pitch%3D-20%26thumbfov%3D100!7i13312!8i6656!4m15!1m8!3m7!1s0x8e5887aa3c1b1d33:0x14ef5ac3fdda0952!2sPlaya+de+la+Pen%C3%ADnsula+de+Bar%C3%BA!8m2!3d10.1697267!4d-75.6573203!10e5!16s%2Fg%2F121dc64t!3m5!1s0x8e5887aa3c1b1d33:0x14ef5ac3fdda0952!8m2!3d10.1697267!4d-75.6573203!16s%2Fg%2F121dc64t?coh=205409&entry=ttu"
              target="_blank"
              rel="noreferrer"
              className="font-bold underline text-[#2699fb]"
            >{data.beachInfo.textTwo}</a>{" "}{data.beachInfo.textThree}</p>
          <p className="text-lg"><span className="font-bold">{data.beachInfo.textFour}</span>{data.beachInfo.textFive}</p>
          <p className="text-lg"><span className="font-bold">{data.beachInfo.textSix}</span>{data.beachInfo.textSeven}</p>
          <p className="text-lg"><span className="font-bold">{data.beachInfo.textEigth}</span>{data.beachInfo.textNine}</p>
        </div>

        {/* Beach Carrousel */}
        <div className="flex flex-col items-center gap-6 max-w-lg w-full">
          <div
            className="w-full h-[400px] bg-cover bg-center rounded-lg"
            style={{ backgroundImage: `url(${images[Image]})` }}
          ></div>
          <div className="flex space-x-2 justify-center">
            <button
              className={`w-4 h-4 rounded-full ${Image === "image-a" ? "bg-[#2699fb]" : "bg-gray-400"
                }`}
              onClick={() => setImage("image-a")}
            ></button>
            <button
              className={`w-4 h-4 rounded-full ${Image === "image-b" ? "bg-[#2699fb]" : "bg-gray-400"
                }`}
              onClick={() => setImage("image-b")}
            ></button>
            <button
              className={`w-4 h-4 rounded-full ${Image === "image-c" ? "bg-[#2699fb]" : "bg-gray-400"
                }`}
              onClick={() => setImage("image-c")}
            ></button>
          </div>
        </div>
      </div>

      {/* Stay Updated */}
      <button className="bg-white border-4 border-[#2699fb] text-[#2699fb] text-xl font-bold py-2 px-4 rounded-lg">{data.stayUpdated}</button>

      {/* Footer */}
      <p className="text-[#003b5c] text-xl">{data.footer}</p>

    </div>
  );
}